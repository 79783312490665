import React, { useEffect, useState } from 'react'
import { gql } from 'apollo-boost'
import { useLocation, Link, withRouter } from 'react-router-dom'
import Loader from '../components/Loader'
import OrderItem from '../components/orders/OrderItem'
import { FormattedMessage } from "react-intl";
import compose from "lodash.flowright";
import { graphql } from "react-apollo";

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

function Orders({ feedQuery, deliverer, audioNotification, me, updateOrdersMutation, history }) {
  const isDeliverer = localStorage.getItem('isDeliverer') === 'true'
  let query = useQuery()
  const tab = query.get('tab') || (isDeliverer ? 'preparing' : 'new')
  const [searchResults, setSearchResults] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (isDeliverer && !deliverer)
      return
    setSearchResults(!searchTerm
      ? feedQuery.feed?.filter(order =>
        order.status === tab && (isDeliverer ? (order.deliverer?.id === deliverer) : true)
      )
      : feedQuery.feed?.filter(order =>
        order.status === tab && (isDeliverer ? (order.deliverer?.id === deliverer) : true)
      ).filter(order =>
        order.number.toString().toLowerCase().includes(searchTerm)
        ||
        order.name.toString().toLowerCase().includes(searchTerm)
      ))
  }, [searchTerm, feedQuery, tab, deliverer, isDeliverer])

  const handleChange = event => {
    setSearchTerm(event.target.value);
  };

  const updateOrders = async data => {
    setLoading(true)
    await updateOrdersMutation({
      variables: data,
    })
    feedQuery.refetch()
    setLoading(false)
    history.replace('/orders')
  }

  if (feedQuery.loading) {
    return (
      <Loader/>
    )
  }

  let renderNavTabs = <ul className="nav nav-tabs nav-overflow header-tabs">
    <li className="nav-item">
      <Link to="/orders" className={`font-size-lg nav-link ${tab === 'new' && 'active'}`}>
        Nouvelles <span
        className="badge badge-pill badge-light font-size-lg">{feedQuery.feed?.filter(e => e.status === 'new').length}</span>
      </Link>
    </li>
    <li className="nav-item">
      <Link to="/orders?tab=preparing" className={`font-size-lg nav-link ${tab === 'preparing' && 'active'}`}>
        Préparations <span
        className="badge badge-pill badge-light font-size-lg">{feedQuery.feed?.filter(e => e.status === 'preparing').length}</span>
      </Link>
    </li>
    <li className="nav-item">
      <Link to="orders?tab=ready" className={`font-size-lg nav-link ${tab === 'ready' && 'active'}`}>
        Prêtes <span
        className="badge badge-pill badge-light font-size-lg">{feedQuery.feed?.filter(e => e.status === 'ready').length}</span>
      </Link>
    </li>
    <li className="nav-item">
      <Link to="orders?tab=readytodeliver" className={`font-size-lg nav-link ${tab === 'readytodeliver' && 'active'}`}>
        Prêtes à être
        livrées <span
        className="badge badge-pill badge-light font-size-lg">{feedQuery.feed?.filter(e => e.status === 'readytodeliver').length}</span>
      </Link>
    </li>
    <li className="nav-item">
      <Link to="orders?tab=delivering" className={`font-size-lg nav-link ${tab === 'delivering' && 'active'}`}>
        En cours de
        livraison <span
        className="badge badge-pill badge-light font-size-lg">{feedQuery.feed?.filter(e => e.status === 'delivering').length}</span>
      </Link>
    </li>
    <li className="nav-item">
      <Link to="orders?tab=arrived" className={`font-size-lg nav-link ${tab === 'arrived' && 'active'}`}>
        Arrivé sur
        place<span
        className="badge badge-pill badge-light font-size-lg">{feedQuery.feed?.filter(e => e.status === 'arrived').length}</span>
      </Link>
    </li>
  </ul>

  if (isDeliverer)
    renderNavTabs = <ul className="nav nav-tabs nav-overflow header-tabs">
      <li className="nav-item">
        <Link to="orders?tab=preparing" className={`font-size-lg nav-link ${tab === 'preparing' && 'active'}`}>
          En
          Préparation <span
          className="badge badge-pill badge-light font-size-lg">{feedQuery.feed?.filter(e => e.status === 'preparing' && e.deliverer?.id === deliverer).length}</span>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="orders?tab=readytodeliver"
              className={`font-size-lg nav-link ${tab === 'readytodeliver' && 'active'}`}>
          Prêtes à être
          livrées <span
          className="badge badge-pill badge-light font-size-lg">{feedQuery.feed?.filter(e => e.status === 'readytodeliver' && e.deliverer?.id === deliverer).length}</span>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="orders?tab=delivering" className={`font-size-lg nav-link ${tab === 'delivering' && 'active'}`}>
          En cours de
          livraison <span
          className="badge badge-pill badge-light font-size-lg">{feedQuery.feed?.filter(e => e.status === 'delivering' && e.deliverer?.id === deliverer).length}</span>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="orders?tab=arrived" className={`font-size-lg nav-link ${tab === 'arrived' && 'active'}`}>
          Arrivé sur
          place<span
          className="badge badge-pill badge-light font-size-lg">{feedQuery.feed?.filter(e => e.status === 'arrived' && e.deliverer?.id === deliverer).length}</span>
        </Link>
      </li>
    </ul>

  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="header mt-md-5">
            <div className="header-body">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="header-title">
                    Gestion des Commandes
                  </h1>
                </div>
                <div className="col-auto">
                  {isDeliverer ?
                    <Link to="/orders" className="btn btn-primary lift">
                      ({feedQuery.feed?.filter(e => e.status === 'readytodeliver' && e.deliverer?.id === deliverer).length})
                      Prêtes à être livrées
                    </Link>
                    :
                    <Link to="/orders" className="btn btn-primary lift">
                      ({feedQuery.feed?.filter(e => e.status === 'new').length}) Nouvelles
                    </Link>
                  }
                  <button className="btn btn-dark ml-3" onClick={() =>
                    window.location.reload(true)
                  }>
                    <i className="fe fe-refresh-cw"/>
                  </button>
                  {[23].includes(feedQuery?.feed[0]?.restaurant?.id) &&
                    <button onClick={() => updateOrders({
                      restaurant: feedQuery?.feed[0]?.restaurant?.id,
                      fromStatus: 'new',
                      toStatus: 'preparing',
                    })} className="btn btn-primary ml-3 lift" disabled={loading}>
                      {loading ? <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div> : <span>Accepter toutes les commandes</span>}
                    </button>}
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col">
                  {renderNavTabs}
                </div>
              </div>
            </div>
          </div>
          <div className="card" data-toggle="lists"
               data-options='{"valueNames": ["orders-order", "orders-product", "orders-date", "orders-total", "orders-status", "orders-method"]}'>
            <div className="card-header card-header-search">
              <form>
                <div className="input-group input-group-flush">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fe fe-search"/>
                    </span>
                  </div>
                  <input className="form-control search" type="search"
                         placeholder="Rechercher par numéro de commande ou nom de client"
                         value={searchTerm}
                         onChange={handleChange}
                  />
                </div>
              </form>
            </div>
            <div className="table-responsive">
              <table className="table table-nowrap card-table table-hover">
                <thead>
                <tr>
                  {feedQuery?.feed[0]?.restaurant?.id !== 23 && (isDeliverer || me?.admin === true) &&
                    <th>
                    <span className="text-muted sort" data-sort="orders-order">
                      Restaurant
                    </span>
                    </th>
                  }
                  <th>
                    <span className="text-muted sort" data-sort="orders-order">
                      Commande
                    </span>
                  </th>
                  <th>
                    <span className="text-muted sort" data-sort="orders-product">
                      Client
                    </span>
                  </th>
                  <th>
                    <span className="text-muted sort" data-sort="orders-date">
                      Date / Heure
                    </span>
                  </th>
                  {!isDeliverer &&
                    <th>
                    <span className="text-muted sort" data-sort="orders-total">
                      Total
                    </span>
                    </th>
                  }
                  <th>
                    <span className="text-muted sort" data-sort="orders-status">
                      Statut
                    </span>
                  </th>
                  <th>
                    <span className="text-muted sort" data-sort="orders-status">
                      Livreur
                    </span>
                  </th>
                </tr>
                </thead>
                <tbody className="list">
                {feedQuery.feed &&
                  searchResults?.sort((a, b) => a.delivererNumber - b.delivererNumber).map(order => (
                    <OrderItem order={order} key={order.id} isDeliverer={isDeliverer} admin={me?.admin === true}
                               audio={audioNotification}/>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const UPDATE_ORDERS = gql`
  mutation updateOrders($restaurant: ID!, $fromStatus: String!, $toStatus: String!) {
    updateOrders(restaurant: $restaurant, fromStatus: $fromStatus, toStatus: $toStatus) {
      status
    }
  }
`

export default compose(
  graphql(UPDATE_ORDERS, {
    name: 'updateOrdersMutation',
  }),
  withRouter,
)
(Orders)
