import React, { Fragment } from 'react'
import BarGraph from '../components/graph/BarGraph'
import LineGraph from '../components/graph/LineGraph'
import { gql } from 'apollo-boost'
import { graphql } from 'react-apollo';
import { Link } from 'react-router-dom'

function DashboardDeliverer ({ dashboardQuery, deliverer, name }) {
  const { dashboardDeliverer: dashboard } = dashboardQuery

  return (
    <Fragment>
      <div className="header">
        <div className="container-fluid">
          <div className="header-body">
            <div className="row align-items-end">
              <div className="col">
                <h1 className="header-title">Tableau de bord | {name}</h1>
              </div>
              <div className="col-auto">
                <button className="btn btn-dark h-100" onClick={() =>
                  window.location.reload(true)
                }>
                  <i className="fe fe-refresh-cw"/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-lg-6 col-xl">
            <div className="card">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase h4 mb-2" style={{ color: '#162e4d' }}>CA</h6>
                    <span className="h2 mb-0">{dashboard?.ca?.toFixed(2)}</span>
                  </div>
                  <div className="col-auto">
                    <span className="h2 font-weight-normal text-success mb-0">€</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 col-xl">
            <Link to="/orders" className="card">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase h4 mb-2" style={{ color: '#162e4d' }}>Nouvelles commandes</h6>
                    <span className="h2 mb-0 text-primary">{dashboard?.newOrders?.length}</span>
                  </div>
                  <div className="col-auto">
                    <span className="h2 fe fe-alert-circle text-primary mb-0"/>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-12 col-lg-6 col-xl">
            <div className="card">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase h4 mb-2" style={{ color: '#162e4d' }}>Commandes reçues</h6>
                    <span className="h2 mb-0">{dashboard?.ordersReceived?.length}</span>
                  </div>
                  <div className="col-auto">
                    <span className="h2 fe fe-activity text-secondary mb-0"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 col-xl">
            <Link to="/history" className="card">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase h4 mb-2" style={{ color: '#162e4d' }}>Commandes Complétées</h6>
                    <span className="h2 mb-0 text-success">{dashboard?.ordersDelivered?.length}</span>
                  </div>
                  <div className="col-auto">
                    <span className="h2 fe fe-check-circle text-success mb-0"/>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h2 className="card-header-title">Ventes de la semaine</h2>
              </div>
              <div className="card-body">
                {dashboard?.salesPerDay &&
                <LineGraph
                  labels={dashboard.salesPerDay.map(obj => obj.label)}
                  data={dashboard.salesPerDay.map(obj => obj.value)}/>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h2 className="card-header-title">Chiffre d'affaires</h2>
              </div>
              <div className="card-body">
                {dashboard?.salesPerMonth &&
                <BarGraph
                  labels={dashboard.salesPerMonth.map(obj => obj.label)}
                  data={dashboard.salesPerMonth.map(obj => obj.value)}/>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}


const DASHBOARD_QUERY = gql`
  query dashboardQuery {
    dashboardDeliverer {
      ca
      access_token
      ordersReceived {
        id
      }
      ordersDelivered {
        id
      }
      newOrders {
        id
      }
      salesPerMonth {
        label
        value
      }
      salesPerDay {
        label
        value
      }
    }
  }
`

export default graphql(DASHBOARD_QUERY, {
  name: 'dashboardQuery',
  options: {
    fetchPolicy: 'network-only',
  },
})(DashboardDeliverer)

