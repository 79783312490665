import React from 'react'
import { Link } from 'react-router-dom'

const PageNotFound = () => (
  <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    flexDirection: 'column'
  }}>
    <p className="h1 font-weight-normal mb-5">La page n'existe pas</p>
    <div>
      <Link to="/" className="btn btn-primary">Revenir à l'accueil</Link>
    </div>
  </div>
)

export default PageNotFound
