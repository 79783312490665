import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { UPLOADS_PATH } from '../constant';

const FileUpload = ({ mutation, query, id, updateMutation, image, field = 'image' }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      try {
        const reader = new FileReader()
        reader.onload = async () => {
          setIsUploading(true)
          const binaryStr = reader.result
          acceptedFiles[0].stream = binaryStr
          const file = { stream: binaryStr, type: acceptedFiles[0].type }
          await mutation({
            variables: { file, article: id },
          })
          await query.refetch()
          setIsUploading(false)
        }
        reader.readAsDataURL(acceptedFiles[0])
      } catch (e) {
        setIsUploading(false)
      }
    },
    [mutation]
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  })
  const [isUploading, setIsUploading] = useState(false)
  if (isUploading) {
    return <div className="d-flex justify-content-center w-100 align-items-center">
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  }
  if (image)
    return (
      <div>
        {image && (image.split('.').pop() === 'mp4' ?
            <video controls style={{ width: '100%' }}>
              <source src={UPLOADS_PATH + image} type="video/mp4"/>
            </video>
            :
            <img src={UPLOADS_PATH + image} style={{ width: '100%' }} alt={image}/>
        )}
        <button className="btn btn-icon cursor-pointer" onClick={async () => {
          await updateMutation({
            variables: { id, [field]: null },
          })
          await query.refetch()
        }}><i className="fe fe-trash text-danger"/></button>
      </div>
    )
  return (
    <div {...getRootProps()} className={`dropzone ${isDragActive && 'isActive'}`}>
      <input {...getInputProps()} />
      <i className="fe fe-plus-square" style={{ fontSize: '25px', cursor: 'pointer' }}/>
    </div>
  )
}

export default FileUpload
