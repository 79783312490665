import React, { Fragment } from 'react'
import BarGraph from '../components/graph/BarGraph'
import LineGraph from '../components/graph/LineGraph'
import { gql } from 'apollo-boost'
import { graphql } from 'react-apollo';
import { Link } from 'react-router-dom'
import DoughnutGraph from "../components/graph/DoughnutGraph";

const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

function Dashboard({ restaurant, dashboardQuery, me }) {
  const { dashboard } = dashboardQuery
  return (
    <Fragment>
      <div className="header">
        <div className="container-fluid">
          <div className="header-body">
            <div className="row align-items-end">
              <div className="col">
                <h1 className="header-title">Tableau de bord | {restaurant?.name}</h1>
              </div>
              <div className="col-auto">
                <button className="btn btn-dark h-100" onClick={() =>
                  window.location.reload(true)
                }>
                  <i className="fe fe-refresh-cw"/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-lg-6 col-xl">
            <div className="card">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase h4 mb-2" style={{ color: '#162e4d' }}>CA</h6>
                    <span className="h2 mb-0">{dashboard?.ca}</span>
                  </div>
                  <div className="col-auto">
                    <span className="h2 font-weight-normal text-success mb-0">€</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {me?.admin === true &&
          <div className="col-12 col-lg-6 col-xl">
            <div className="card">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase h4 mb-2" style={{ color: '#162e4d' }}>CA Yeaty</h6>
                    <span className="h2 mb-0">{dashboard?.caYeaty}</span>
                  </div>
                  <div className="col-auto">
                    <span className="h2 font-weight-normal text-success mb-0">€</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          }
          <div className="col-12 col-lg-6 col-xl">
            <Link to="/orders?tab=new" className="card">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase h4 mb-2" style={{ color: '#162e4d' }}>Nouvelles commandes</h6>
                    <span className="h2 mb-0 text-primary">{dashboard?.newOrders?.length}</span>
                  </div>
                  <div className="col-auto">
                    <span className="h2 fe fe-alert-circle text-primary mb-0"/>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-12 col-lg-6 col-xl">
            <div className="card">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase h4 mb-2" style={{ color: '#162e4d' }}>Commandes reçues</h6>
                    <span className="h2 mb-0">{dashboard?.ordersReceived?.length}</span>
                  </div>
                  <div className="col-auto">
                    <span className="h2 fe fe-activity text-secondary mb-0"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 col-xl">
            <Link to="/history" className="card">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase h4 mb-2" style={{ color: '#162e4d' }}>Commandes Complétées</h6>
                    <span className="h2 mb-0 text-success">{dashboard?.ordersDelivered?.length}</span>
                  </div>
                  <div className="col-auto">
                    <span className="h2 fe fe-check-circle text-success mb-0"/>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>

        {restaurant?.id === 31 &&
        <div className="row">
          <div className="col-12 col-xl-4">
            <div className="card card-fill">
              <div className="card-header">
                <h2 className="card-header-title">Paris 6 Mai 2021</h2>
              </div>
              <div className="card-body mt-0">
                <div className="list-group list-group-flush my-n3">
                  <div className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col ml-n2">
                        <h4 className="mb-1">13h - 14h15</h4>
                      </div>
                      <div className="col-auto">
                        <p
                          className="card-text">{dashboard?.ordersReceived?.filter(order => order.scheduled === '13h - 14h15')?.length}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col ml-n2">
                        <h4 className="mb-1">14h - 14h30</h4>
                      </div>
                      <div className="col-auto">
                        <p
                          className="card-text">{dashboard?.ordersReceived?.filter(order => order.scheduled === '14h - 14h30')?.length}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col ml-n2">
                        <h4 className="mb-1">15h - 15h45</h4>
                      </div>
                      <div className="col-auto">
                        <p
                          className="card-text">{dashboard?.ordersReceived?.filter(order => order.scheduled === '15h - 15h45')?.length}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col ml-n2">
                        <h4 className="mb-1">16h - 17h15</h4>
                      </div>
                      <div className="col-auto">
                        <p
                          className="card-text">{dashboard?.ordersReceived?.filter(order => order.scheduled === '16h - 17h15')?.length}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col ml-n2">
                        <h4 className="mb-1">17h30 - 18h45</h4>
                      </div>
                      <div className="col-auto">
                        <p
                          className="card-text">{dashboard?.ordersReceived?.filter(order => order.scheduled === '17h30 - 18h45')?.length}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        }

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h2 className="card-header-title">Ventes de la semaine</h2>
              </div>
              <div className="card-body">
                {dashboard?.salesPerDay &&
                <LineGraph
                  labels={dashboard.salesPerDay.map(obj => obj.label)}
                  data={dashboard.salesPerDay.map(obj => obj.value)}/>
                }
              </div>
            </div>
          </div>
          {false &&
          <div className="col-12 col-md-4">
            <div className="card">
              <div className="card-header">
                <h2 className="card-header-title">Affluence</h2>
              </div>
              <div className="card-body">
                {dashboard?.affluence &&
                <DoughnutGraph
                  labels={['11h-13h', '13h-15h', '18h-21h', '21h-00h']}
                  data={dashboard.affluence}/>
                }
              </div>
            </div>
          </div>
          }
        </div>
        {restaurant?.id === 23 && dashboard?.topArticles ?
          <div className="row">
            {/*
            {Object.keys(groupBy(dashboard.topArticles, 'type')).map((key, i) => (
              <div key={i} className="col-12 col-xl-4">
                <div className="card card-fill">
                  <div className="card-header">
                    <h2 className="card-header-title">Itadakimasu {key}</h2>
                  </div>
                  <div className="card-body mt-0">
                    <div className="list-group list-group-flush my-n3">
                      {groupBy(dashboard.topArticles, 'type')[key].filter(article => parseInt(article.type) === parseInt(key))?.map((article, i2) => (
                        <div className="list-group-item" key={i2}>
                          <div className="row align-items-center">
                            <div className="col ml-n2">
                              <h4 className="mb-1">{article.name}</h4>
                            </div>
                            <div className="col-auto">
                              <p className="card-text">{article.quantity}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            */}

            <div className="col-12 col-xl-4">
              <div className="card card-fill">
                <div className="card-header">
                  <h2 className="card-header-title">Itadakimasu 1</h2>
                </div>
                <div className="card-body mt-0">
                  <div className="list-group list-group-flush my-n3">
                    {dashboard?.topArticles?.map(article => (
                      <div className="list-group-item" key={article.id}>
                        <div className="row align-items-center">
                          <div className="col ml-n2">
                            <h4 className="mb-1">{article.name}</h4>
                          </div>
                          <div className="col-auto">
                            <p className="card-text">{article.quantity}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-4">
              <div className="card card-fill">
                <div className="card-header">
                  <h2 className="card-header-title">Itadakimasu 2</h2>
                </div>
                <div className="card-body mt-0">
                  <div className="list-group list-group-flush my-n3">
                    {dashboard?.topArticles2?.map(article => (
                      <div className="list-group-item" key={article.id}>
                        <div className="row align-items-center">
                          <div className="col ml-n2">
                            <h4 className="mb-1">{article.name}</h4>
                          </div>
                          <div className="col-auto">
                            <p className="card-text">{article.quantity}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-4">
              <div className="card card-fill">
                <div className="card-header">
                  <h2 className="card-header-title">Itadakimasu 3</h2>
                </div>
                <div className="card-body mt-0">
                  <div className="list-group list-group-flush my-n3">
                    {dashboard?.topArticles3?.map(article => (
                      <div className="list-group-item" key={article.id}>
                        <div className="row align-items-center">
                          <div className="col ml-n2">
                            <h4 className="mb-1">{article.name}</h4>
                          </div>
                          <div className="col-auto">
                            <p className="card-text">{article.quantity}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-4">
              <div className="card card-fill">
                <div className="card-header">
                  <h2 className="card-header-title">Itadakimasu 4</h2>
                </div>
                <div className="card-body mt-0">
                  <div className="list-group list-group-flush my-n3">
                    {dashboard?.topArticles4?.map(article => (
                      <div className="list-group-item" key={article.id}>
                        <div className="row align-items-center">
                          <div className="col ml-n2">
                            <h4 className="mb-1">{article.name}</h4>
                          </div>
                          <div className="col-auto">
                            <p className="card-text">{article.quantity}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-4">
              <div className="card card-fill">
                <div className="card-header">
                  <h2 className="card-header-title">Itadakimasu X Yummy Thai</h2>
                </div>
                <div className="card-body mt-0">
                  <div className="list-group list-group-flush my-n3">
                    {dashboard?.topArticlesItadakimasuYummyThai?.map(article => (
                      <div className="list-group-item" key={article.id}>
                        <div className="row align-items-center">
                          <div className="col ml-n2">
                            <h4 className="mb-1">{article.name}</h4>
                          </div>
                          <div className="col-auto">
                            <p className="card-text">{article.quantity}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          <div className="row">
            <div className="col-12 col-xl-4">
              <div className="card card-fill">
                <div className="card-header">
                  <h2 className="card-header-title">Articles les plus commandés</h2>
                </div>
                <div className="card-body mt-0">
                  <div className="list-group list-group-flush my-n3">
                    {dashboard?.topArticles?.map(article => (
                      <div className="list-group-item" key={article.id}>
                        <div className="row align-items-center">
                          <div className="col ml-n2">
                            <h4 className="mb-1">{article.name}</h4>
                          </div>
                          <div className="col-auto">
                            <p className="card-text">{article.quantity}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-8">
              <div className="card">
                <div className="card-header">
                  <h2 className="card-header-title">Chiffre d'affaires</h2>
                </div>
                <div className="card-body">
                  {dashboard?.salesPerMonth &&
                  <BarGraph
                    labels={dashboard.salesPerMonth.map(obj => obj.label)}
                    data={dashboard.salesPerMonth.map(obj => obj.value)}/>
                  }
                </div>
              </div>
            </div>
          </div>
        }

      </div>
    </Fragment>
  )
}


const DASHBOARD_QUERY = gql`
  query dashboardQuery {
    dashboard {
      ca
      caYeaty
      access_token
      affluence
      ordersReceived {
        id
        scheduled
      }
      ordersDelivered {
        id
      }
      newOrders {
        id
      }
      salesPerMonth {
        label
        value
      }
      salesPerDay {
        label
        value
      }
      topArticles {
        id
        name
        quantity
        type
      }
      topArticles2 {
        id
        name
        quantity
        type
      }
      topArticles3 {
        id
        name
        quantity
        type
      }
      topArticles4 {
        id
        name
        quantity
        type
      }
      topArticlesItadakimasuYummyThai {
        id
        name
        quantity
        type
      }
    }
  }
`

export default graphql(DASHBOARD_QUERY, {
  name: 'dashboardQuery',
  options: {
    fetchPolicy: 'network-only',
  },
})(Dashboard)

