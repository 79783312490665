import React, { useState, useEffect } from 'react'
import compose from 'lodash.flowright'
import { graphql } from 'react-apollo'
import { gql } from 'apollo-boost'
import FileUpload from '../components/FileUpload'
import Loader from '../components/Loader'
import OpenHour from '../components/OpenHour'
import { toast } from 'react-toastify'
import { Form } from 'react-bootstrap'
import ModalSelect from '../components/menu/ModalSelect'
import { useQuery } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import { useQueryParams } from "./History";

function Restaurant ({
                       meQuery,
                       uploadPhotoRestaurant,
                       updateRestaurant,
                       addDiscountCategories,
                       ...props
                     }) {
  const query = useQueryParams()
  const restaurantId = query.get('restaurant') ? parseInt(query.get('restaurant')) : undefined
  const restaurantQuery = useQuery(RESTAURANT_QUERY, {
    name: 'restaurant',
    skip: meQuery.me?.admin !== true || !restaurantId,
    variables: {
      id: restaurantId
    }
  })

  const [profile, setProfile] = useState(meQuery.me)
  const [profileLoader, setProfileLoader] = useState(false)

  const [discountLoader, setDiscountLoader] = useState(false)
  const [discountCategories, setDiscountCategories] = useState([])
  const [discountPercentage, setDiscountPercentage] = useState(false)

  const [monday, setMonday] = useState(meQuery.me?.restaurant?.monday)
  const [tuesday, setTuesday] = useState(meQuery.me?.restaurant?.tuesday)
  const [wednesday, setWednesday] = useState(meQuery.me?.restaurant?.wednesday)
  const [thursday, setThursday] = useState(meQuery.me?.restaurant?.thursday)
  const [friday, setFriday] = useState(meQuery.me?.restaurant?.friday)
  const [saturday, setSaturday] = useState(meQuery.me?.restaurant?.saturday)
  const [sunday, setSunday] = useState(meQuery.me?.restaurant?.sunday)

  const [mondayDelivery, setMondayDelivery] = useState(meQuery.me?.restaurant?.mondayDelivery)
  const [tuesdayDelivery, setTuesdayDelivery] = useState(meQuery.me?.restaurant?.tuesdayDelivery)
  const [wednesdayDelivery, setWednesdayDelivery] = useState(meQuery.me?.restaurant?.wednesdayDelivery)
  const [thursdayDelivery, setThursdayDelivery] = useState(meQuery.me?.restaurant?.thursdayDelivery)
  const [fridayDelivery, setFridayDelivery] = useState(meQuery.me?.restaurant?.fridayDelivery)
  const [saturdayDelivery, setSaturdayDelivery] = useState(meQuery.me?.restaurant?.saturdayDelivery)
  const [sundayDelivery, setSundayDelivery] = useState(meQuery.me?.restaurant?.sundayDelivery)

  const [validHours, setValidHours] = useState(true)
  const [validHoursDelivery, setValidHoursDelivery] = useState(true)

  useEffect(() => {
    let me = meQuery.me
    if (meQuery.me?.admin === true) {
      me = { admin: true, restaurant: restaurantQuery.data?.restaurantAdmin }
    }

    setProfile(me)

    setMonday(me?.restaurant?.monday)
    setTuesday(me?.restaurant?.tuesday)
    setWednesday(me?.restaurant?.wednesday)
    setThursday(me?.restaurant?.thursday)
    setFriday(me?.restaurant?.friday)
    setSaturday(me?.restaurant?.saturday)
    setSunday(me?.restaurant?.sunday)

    setMondayDelivery(me?.restaurant?.mondayDelivery)
    setTuesdayDelivery(me?.restaurant?.tuesdayDelivery)
    setWednesdayDelivery(me?.restaurant?.wednesdayDelivery)
    setThursdayDelivery(me?.restaurant?.thursdayDelivery)
    setFridayDelivery(me?.restaurant?.fridayDelivery)
    setSaturdayDelivery(me?.restaurant?.saturdayDelivery)
    setSundayDelivery(me?.restaurant?.sundayDelivery)

    const discountCategories = []
    let discountPercentage = null
    // eslint-disable-next-line no-unused-expressions
    me?.restaurant?.categories?.map(category => {
      if (category.discount) {
        discountCategories.push({ value: category.id, label: category.name })
        discountPercentage = category.discount
      }
    })
    setDiscountCategories(discountCategories)
    setDiscountPercentage(discountPercentage)
  }, [meQuery.me, restaurantQuery.data])

  const handleInputChange = event => {
    const { name, value } = event.target
    if (name.includes('.')) {
      const array = name.split('.')
      const first = array[0]
      const second = array[1]
      setProfile({ ...profile, ...{ [first]: { ...profile[first], [second]: value } } })
    } else {
      setProfile({ ...profile, [name]: value })
    }
  }

  const submitRestaurant = async e => {
    e.preventDefault()
    setProfileLoader(true)
    updateRestaurant({
      variables: {
        id: restaurantId,
        name: profile.restaurant.name, address: profile.restaurant.address, category: profile.restaurant.category,
        preparationTime: parseInt(profile.restaurant.preparationTime),
        deliveryFees: parseFloat(profile.restaurant.deliveryFees),
        perimeter: parseFloat(profile.restaurant.perimeter),
        perimeterIntermediate: parseFloat(profile.restaurant.perimeterIntermediate),
        minimumDeliveryAmount: parseFloat(profile.restaurant.minimumDeliveryAmount),
        minimumFreeDelivery: parseFloat(profile.restaurant.minimumFreeDelivery)
      }
    })
      .then(async () => {
        setProfileLoader(false)
        toast('Informations mises à jour !', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: 'success'
        })
        await meQuery.refetch()
        if (profile?.admin === true) {
          await restaurantQuery.refetch()
        }
      })
      .catch(() => {
        setProfileLoader(false)
      })
  }


  const submitDiscount = async e => {
    e.preventDefault()
    setDiscountLoader(true)
    addDiscountCategories({
      variables: {
        id: restaurantId,
        categories: discountCategories?.map(cat => Number(cat.value)),
        percentage: discountPercentage ? parseInt(discountPercentage) : undefined
      }
    })
      .then(async () => {
        setDiscountLoader(false)
        toast('Promotions ajoutées !', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: 'success'
        })
        await meQuery.refetch()
        if (profile?.admin === true) {
          await restaurantQuery.refetch()
        }
      })
      .catch(() => {
        setProfileLoader(false)
      })
  }

  const listRestaurants = useQuery(LIST_RESTAURANTS_QUERY, {
    name: 'listRestaurants', // name of the injected prop: this.props.ordersQuery...
  })

  const restaurantListDiv = <div>
    <nav className="nav nav-tabs">
      {listRestaurants?.data?.listRestaurants?.map((restaurant) =>
        <li className="nav-item">
          <Link className={`nav-item nav-link ${restaurant.id === Number(restaurantId) ? 'active' : ''}`} to={'?restaurant=' + restaurant.id}>{restaurant.name}</Link>
        </li>
      )}
    </nav>
  </div>

  if (profile?.admin === true && !restaurantId) {
    return restaurantListDiv
  }

  if (!profile?.restaurant)
    return <Loader/>

  return (
    <div className="container-fluid">
      {profile?.admin === true && (
        restaurantListDiv
      )}
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10 col-xl-8">
          <div className="header mt-md-5">
            <div className="header-body">
              <div className="row align-items-center">
                <div className="col">
                  <h6 className="header-pretitle">
                    Paramètres
                  </h6>
                  <h1 className="header-title">
                    {profile?.admin === true ? profile?.restaurant?.name : 'Restaurant'}
                  </h1>
                </div>
                <div className="col-auto">
                  <button className="btn btn-dark h-100" onClick={() =>
                    window.location.reload(true)
                  }>
                    <i className="fe fe-refresh-cw"/>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <form>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label className="mb-1">
                    Suspendre les commandes
                  </label>
                  <small className="form-text text-muted">
                    Les clients ne pourront plus passer commande jusqu'à réactivation
                  </small>
                  <div className="row">
                    <div className="col-auto">
                      <div className="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" id="suspendreCommandes"
                               checked={profile?.restaurant?.orderDisabled}
                               onChange={async (e) => {
                                 await updateRestaurant({
                                   variables: {
                                     id: restaurantId,
                                     orderDisabled: !profile?.restaurant?.orderDisabled,
                                   },
                                 })
                                 await meQuery.refetch()
                                 if (profile?.admin === true) {
                                   await restaurantQuery.refetch()
                                 }
                               }}/>
                        <label className="custom-control-label" htmlFor="suspendreCommandes"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="mt-4 mb-5"/>
          </form>
          <form className="mb-5" onSubmit={submitDiscount}>
            <h3 className="mb-2">Promotions sur les catégories</h3>
            <div className="row">
              <div className="col-12">
                <Form.Group>
                  <Form.Label>Catégorie(s)</Form.Label>
                  <ModalSelect
                    isMulti
                    isValid={true}
                    value={discountCategories}
                    onChange={(option) => setDiscountCategories(option)}
                    options={profile?.restaurant?.categories?.map(category => (
                      { value: category.id, label: category.name }
                    ))
                    }
                  />
                  <Form.Control.Feedback type="invalid">Veuillez choisir une ou plusieurs
                    catégorie(s)</Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="inputName">%</label>
                  <input
                    type="number"
                    className="form-control"
                    value={discountPercentage}
                    onChange={(e) => setDiscountPercentage(e.target.value)}
                    autoComplete="off"/>
                </div>
              </div>
              <div className="col-12">
                <button type="submit" className="btn btn-primary lift" disabled={discountLoader}>
                  {discountLoader ? <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div> : <span>Mettre à jour les promotions</span>}
                </button>
              </div>
            </div>
          </form>

          <div className="mb-4">
            <div className="row">
              <div className="col-12">
                <h3 className="mb-2">Grande photo principale</h3>
                <div className="card">
                  <div className="card-body">
                    <FileUpload mutation={uploadPhotoRestaurant} query={meQuery} image={profile?.restaurant?.image}
                                updateMutation={updateRestaurant} id={restaurantId || profile?.restaurant?.id}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <form className="mb-4" onSubmit={submitRestaurant}>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label>
                    Nom du restaurant
                  </label>
                  <input type="text" className="form-control" name="restaurant.name" value={profile?.restaurant?.name || ''} onChange={handleInputChange}/>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label>
                    Adresse du restaurant
                  </label>
                  <input type="text" className="form-control" name="restaurant.address" value={profile?.restaurant?.address || ''} onChange={handleInputChange}/>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label>
                    Catégorie du restaurant
                  </label>
                  <input type="text" className="form-control" name="restaurant.category" value={profile?.restaurant?.category || ''} onChange={handleInputChange}/>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label>
                    Temps de préparation (minutes)
                  </label>
                  <input type="number" className="form-control" name="restaurant.preparationTime" value={profile?.restaurant?.preparationTime || ''} onChange={handleInputChange}/>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label>
                    Frais de livraison
                  </label>
                  <div className="input-group">
                    <input step="any" type="number" className="form-control" name="restaurant.deliveryFees" value={profile?.restaurant?.deliveryFees || ''} onChange={handleInputChange}/>
                    <div className="input-group-append">
                      <span className="input-group-text">€</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label>
                    Périmètre maximal de livraison (km)
                  </label>
                  <input step="any" type="number" className="form-control" name="restaurant.perimeter" value={profile?.restaurant?.perimeter || ''} onChange={handleInputChange}/>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label>
                    Périmètre intermédiaire de livraison (km)
                  </label>
                  <input step="any" type="number" className="form-control" name="restaurant.perimeterIntermediate" value={profile?.restaurant?.perimeterIntermediate || ''} onChange={handleInputChange}/>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label>
                    Minimum de commande en livraison
                  </label>
                  <div className="input-group">
                    <input step="any" type="number" className="form-control" name="restaurant.minimumDeliveryAmount" value={profile?.restaurant?.minimumDeliveryAmount || ''} onChange={handleInputChange}/>
                    <div className="input-group-append">
                      <span className="input-group-text">€</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label>
                    Minimum de commande pour la livraison offerte
                  </label>
                  <div className="input-group">
                    <input step="any" type="number" className="form-control" name="restaurant.minimumFreeDelivery" value={profile?.restaurant?.minimumFreeDelivery || ''} onChange={handleInputChange}/>
                    <div className="input-group-append">
                      <span className="input-group-text">€</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <button type="submit" className="btn btn-primary lift" disabled={profileLoader}>
                  {profileLoader ? <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div> : <span>Mettre à jour les informations</span>}
                </button>
              </div>
            </div>
          </form>

          <form>
            <hr className="mt-4 mb-5"/>
            <h3 className="mb-4">Horaires de Click & Collect</h3>
            <div className="row mb-5">
              <div className="col-12">
                <OpenHour label="Lundi" value={monday}
                          onChange={(e, valid) => {
                            setMonday(e ? e.target.value : '')
                            setValidHours(valid)
                          }}/>

                <OpenHour label="Mardi" value={tuesday}
                          onChange={(e, valid) => {
                            setTuesday(e ? e.target.value : '')
                            setValidHours(valid)
                          }}/>

                <OpenHour label="Mercredi" value={wednesday}
                          onChange={(e, valid) => {
                            setWednesday(e ? e.target.value : '')
                            setValidHours(valid)
                          }}/>

                <OpenHour label="Jeudi" value={thursday}
                          onChange={(e, valid) => {
                            setThursday(e ? e.target.value : '')
                            setValidHours(valid)
                          }}/>

                <OpenHour label="Vendredi" value={friday}
                          onChange={(e, valid) => {
                            setFriday(e ? e.target.value : '')
                            setValidHours(valid)
                          }}/>

                <OpenHour label="Samedi" value={saturday}
                          onChange={(e, valid) => {
                            setSaturday(e ? e.target.value : '')
                            setValidHours(valid)
                          }}/>

                <OpenHour label="Dimanche" value={sunday}
                          onChange={(e, valid) => {
                            setSunday(e ? e.target.value : '')
                            setValidHours(valid)
                          }}/>

                <button type="submit" className="btn btn-primary lift" disabled={!validHours} onClick={async (e) => {
                  e.preventDefault()
                  if (validHours) {
                    await updateRestaurant({
                      variables: {
                        id: restaurantId,
                        monday: monday.replace(/\s+/g, ''),
                        tuesday: tuesday.replace(/\s+/g, ''),
                        wednesday: wednesday.replace(/\s+/g, ''),
                        thursday: thursday.replace(/\s+/g, ''),
                        friday: friday.replace(/\s+/g, ''),
                        saturday: saturday.replace(/\s+/g, ''),
                        sunday: sunday.replace(/\s+/g, '')
                      },
                    })
                    toast('Horaires mis à jour !', {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      type: 'success'
                    })
                    window.scrollTo(0, 0)
                  }
                }}>
                  Mettre à jour les horaires
                </button>
              </div>
            </div>
          </form>

          <form>
            <hr className="mt-4 mb-5"/>
            <h3 className="mb-4">Horaires de livraison</h3>
            <div className="row mb-5">
              <div className="col-12">
                <OpenHour label="Lundi Livraison" value={mondayDelivery}
                          onChange={(e, valid) => {
                            setMondayDelivery(e ? e.target.value : '')
                            setValidHoursDelivery(valid)
                          }}/>

                <OpenHour label="Mardi Livraison" value={tuesdayDelivery}
                          onChange={(e, valid) => {
                            setTuesdayDelivery(e ? e.target.value : '')
                            setValidHoursDelivery(valid)
                          }}/>

                <OpenHour label="Mercredi Livraison" value={wednesdayDelivery}
                          onChange={(e, valid) => {
                            setWednesdayDelivery(e ? e.target.value : '')
                            setValidHoursDelivery(valid)
                          }}/>

                <OpenHour label="Jeudi Livraison" value={thursdayDelivery}
                          onChange={(e, valid) => {
                            setThursdayDelivery(e ? e.target.value : '')
                            setValidHoursDelivery(valid)
                          }}/>

                <OpenHour label="Vendredi Livraison" value={fridayDelivery}
                          onChange={(e, valid) => {
                            setFridayDelivery(e ? e.target.value : '')
                            setValidHoursDelivery(valid)
                          }}/>

                <OpenHour label="Samedi Livraison" value={saturdayDelivery}
                          onChange={(e, valid) => {
                            setSaturdayDelivery(e ? e.target.value : '')
                            setValidHoursDelivery(valid)
                          }}/>

                <OpenHour label="Dimanche Livraison" value={sundayDelivery}
                          onChange={(e, valid) => {
                            setSundayDelivery(e ? e.target.value : '')
                            setValidHoursDelivery(valid)
                          }}/>

                <button type="submit" className="btn btn-primary lift" disabled={!validHours} onClick={async (e) => {
                  e.preventDefault()
                  if (validHoursDelivery) {
                    await updateRestaurant({
                      variables: {
                        id: restaurantId,
                        mondayDelivery: mondayDelivery.replace(/\s+/g, ''),
                        tuesdayDelivery: tuesdayDelivery.replace(/\s+/g, ''),
                        wednesdayDelivery: wednesdayDelivery.replace(/\s+/g, ''),
                        thursdayDelivery: thursdayDelivery.replace(/\s+/g, ''),
                        fridayDelivery: fridayDelivery.replace(/\s+/g, ''),
                        saturdayDelivery: saturdayDelivery.replace(/\s+/g, ''),
                        sundayDelivery: sundayDelivery.replace(/\s+/g, '')
                      },
                    })
                    toast('Horaires de livraison mis à jour !', {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      type: 'success'
                    })
                    window.scrollTo(0, 0)
                  }
                }}>
                  Mettre à jour les horaires de livraison
                </button>
              </div>
            </div>
          </form>

          {/*
          <form>
            <hr className="mt-4 mb-5"/>
            <h3 className="mb-4">Liens sociaux</h3>
            <div className="row mb-5">
              <div className="col-12">
                <div className="form-group">
                  <label>
                    Facebook
                  </label>
                  <input type="text" className="form-control"/>
                </div>
                <div className="form-group">
                  <label>
                    Twitter
                  </label>
                  <input type="text" className="form-control"/>
                </div>
                <div className="form-group">
                  <label>
                    Instagram
                  </label>
                  <input type="text" className="form-control"/>
                </div>
                <button type="submit" className="btn btn-primary lift">
                  Mettre à jour
                </button>
              </div>
            </div>
          </form>
          */}
        </div>
      </div>
    </div>
  )
}

export const LIST_RESTAURANTS_QUERY = gql`
  query listRestaurants {
    listRestaurants {
      id
      name
      address
      category
      image
      slug
      orderDisabled
      disabled
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
      mondayDelivery
      tuesdayDelivery
      wednesdayDelivery
      thursdayDelivery
      fridayDelivery
      saturdayDelivery
      sundayDelivery
      preparationTime
      deliveryFees
      perimeter
      perimeterIntermediate
      minimumDeliveryAmount
      minimumFreeDelivery
    }
  }
`

const ME_QUERY = gql`
  query MeQuery {
    me {
      id
      admin
      restaurant {
        id
        name
        address
        category
        image
        slug
        orderDisabled
        disabled
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
        mondayDelivery
        tuesdayDelivery
        wednesdayDelivery
        thursdayDelivery
        fridayDelivery
        saturdayDelivery
        sundayDelivery
        preparationTime
        deliveryFees
        perimeter
        perimeterIntermediate
        minimumDeliveryAmount
        minimumFreeDelivery
        categories {
          id
          name
          discount
        }
      }
    }
  }
`

export const RESTAURANT_QUERY = gql`
  query RestaurantQuery($id: ID) {
    restaurantAdmin(id: $id) {
      id
      name
      address
      category
      image
      slug
      orderDisabled
      disabled
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
      mondayDelivery
      tuesdayDelivery
      wednesdayDelivery
      thursdayDelivery
      fridayDelivery
      saturdayDelivery
      sundayDelivery
      preparationTime
      deliveryFees
      perimeter
      perimeterIntermediate
      minimumDeliveryAmount
      minimumFreeDelivery
      categories {
        id
        name
        discount
      }
    }
  }
`

const UPLOAD_FILE_RESTAURANT = gql`
  mutation uploadPhotoRestaurant($file: Upload!) {
    uploadPhotoRestaurant(file: $file) {
      id
    }
  }
`
const UPDATE_RESTAURANT_MUTATION = gql`
  mutation UpdateRestaurant($id: ID, $name: String, $address: String, $category: String, $orderDisabled: Boolean, $disabled: Boolean,
  $monday: String, $tuesday: String, $wednesday: String, $thursday: String, $friday: String, $saturday: String, $sunday: String,
  $mondayDelivery: String, $tuesdayDelivery: String, $wednesdayDelivery: String, $thursdayDelivery: String, $fridayDelivery: String, $saturdayDelivery: String, $sundayDelivery: String,
  $image: String, $preparationTime: Int, $perimeter: Float, $perimeterIntermediate: Float, $deliveryFees: Float, $minimumDeliveryAmount: Float, $minimumFreeDelivery: Float) {
    updateRestaurant(id: $id, name: $name, address : $address, category: $category, orderDisabled: $orderDisabled, disabled: $disabled,
    monday: $monday, tuesday: $tuesday, wednesday: $wednesday, thursday: $thursday, friday: $friday, saturday: $saturday, sunday: $sunday,
    mondayDelivery: $mondayDelivery, tuesdayDelivery: $tuesdayDelivery, wednesdayDelivery: $wednesdayDelivery, thursdayDelivery: $thursdayDelivery, fridayDelivery: $fridayDelivery, saturdayDelivery: $saturdayDelivery, sundayDelivery: $sundayDelivery,
    image: $image, preparationTime: $preparationTime, perimeter: $perimeter, perimeterIntermediate: $perimeterIntermediate, deliveryFees: $deliveryFees,
    minimumDeliveryAmount: $minimumDeliveryAmount, minimumFreeDelivery: $minimumFreeDelivery) {
      id
      name
      address
    }
  }
`

const ADD_DISCOUNT_CATEGORIES = gql`
  mutation addDiscountCategories($id: ID, $categories: [ID!], $percentage: Int) {
    addDiscountCategories(id: $id, categories: $categories, percentage: $percentage) {
      id
    }
  }
`

export default compose(
  graphql(ME_QUERY, {
    name: 'meQuery',
    options: {
      fetchPolicy: 'network-only',
    },
  }),
  graphql(UPDATE_RESTAURANT_MUTATION, {
    name: 'updateRestaurant',
  }),
  graphql(ADD_DISCOUNT_CATEGORIES, {
    name: 'addDiscountCategories'
  }),
  graphql(UPLOAD_FILE_RESTAURANT, {
    name: 'uploadPhotoRestaurant',
  }),
)(Restaurant)
