import React, { Fragment, useEffect, useState } from 'react'
import { graphql } from 'react-apollo'
import compose from 'lodash.flowright'
import { withRouter } from 'react-router-dom'
import { gql } from 'apollo-boost'
import { Form, Modal } from 'react-bootstrap'
import Select from 'react-select'
import { useLazyQuery } from '@apollo/react-hooks'
import FileUpload from '../components/FileUpload'
import CurrencyInput from '../components/menu/CurrencyInput'
import Loader from '../components/Loader';
import { getOptionLabel } from "../helper/helper";
import ModalSelect from "../components/menu/ModalSelect";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ArticleItem from "../components/menu/ArticleItem";
import OptionItem from "../components/menu/OptionItem";

function Article({
                   articleQuery, deleteArticle, history,
                   createArticleOptionMutation, deleteArticleOptionItem,
                   deleteArticleOption, updateArticleMutation,
                   updateArticleOptionMutation,
                   uploadArticlePhoto, uploadArticlePhoto2, restaurant
                 }) {
  const [showArticleOption, setShowArticleOption] = useState(false)
  const [optionId, setOptionId] = useState('')
  const [optionTitle, setOptionTitle] = useState('')
  const [optionDescription, setOptionDescription] = useState('')
  const [optionMaximum, setOptionMaximum] = useState('')
  const [optionType, setOptionType] = useState({ value: 'multiple', label: 'Choix multiples' })
  const [optionRequired, setOptionRequired] = useState(false)
  const [optionItems, setOptionItems] = useState([{ name: '', price: 0 }])

  const [optionItemName, setOptionItemName] = useState('')
  const [optionItemPrice, setOptionItemPrice] = useState(0)
  const [optionItem, setOptionItem] = useState('')
  const [optionItemOption, setOptionItemOption] = useState('')

  const [articleName, setArticleName] = useState('')
  const [articleMinimumQuantity, setArticleMinimumQuantity] = useState(null)
  const [category, setCategory] = useState('')
  const [price, setPrice] = useState(0)
  const [online, setOnline] = useState(true)
  const [description, setDescription] = useState('')

  const [showArticle, setShowArticle] = useState(false)
  const [showEditOption, setShowEditOption] = useState(false)

  const [validated, setValidated] = useState(false)
  const [isCategoryOption, setIsCategoryOption] = useState(false)

  const [getCategories, { data: categories }] = useLazyQuery(CATEGORIES_QUERY)

  const handleCloseArticle = () => setShowArticle(false)
  const handleShowArticle = () => {
    getCategories()
    setShowArticle(true)
  }

  const handleCloseEditOption = () => {
    setShowEditOption(false)
    resetOptionForm()
  }

  const handleShowArticleOption = () => setShowArticleOption(true)

  const handleCloseArticleOption = () => {
    setShowArticleOption(false)
    resetOptionForm()
    setValidated(false)
  }

  const updateArticleOption = async e => {
    e.preventDefault()
    const form = e.currentTarget
    if (form.checkValidity() === false || !category) {
      e.stopPropagation()
      setValidated(true)
      return
    }
    setValidated(false)

    updateArticleOptionMutation({
      variables: {
        id: optionId,
        title: optionTitle,
        description: optionDescription,
        maximum: optionMaximum ? Number(optionMaximum) : null,
        type: optionType.value,
        items: optionItems.map(item => ({ name: item.name, price: item.price })),
        required: optionRequired
      },
    })
      .then(async () => {
        articleQuery.refetch()
        setShowEditOption(false)
        resetOptionForm()
      })
      .catch(e => {
        console.log(e)
      })
  }

  useEffect(() => {
    if (articleQuery?.article && !articleName) {
      setArticleName(article.name)
      setCategory({ value: article.category?.id, label: article.category?.name })
      setPrice(article.price)
      setOnline(article.online)
      setDescription(article.description)
      setArticleMinimumQuantity(article.minimumQuantity)
    }
  }, [articleQuery.article])

  if (articleQuery.loading) {
    return (
      <Loader/>
    )
  }


  const { article } = articleQuery
  if (!article) {
    // TODO : return 404 component
    return `Error!`
  }
  const _deleteArticle = async id => {
    await deleteArticle({
      variables: { id },
    })
    history.replace('/menu')
  }

  const _deleteArticleOption = async id => {
    await deleteArticleOption({
      variables: { id },
    })
    await articleQuery.refetch()
  }

  const _deleteOptionItem = async id => {
    await deleteArticleOptionItem({
      variables: { id },
    })
    await articleQuery.refetch()
  }

  const updateArticle = async e => {
    e.preventDefault()
    updateArticleMutation({
      variables: {
        id: article.id, name: articleName, category: category.value, price, online, description,
        minimumQuantity: articleMinimumQuantity ? parseInt(articleMinimumQuantity) : null
      },
    })
      .then(async mutation => {
        handleCloseArticle()
        await articleQuery.refetch()
      })
      .catch(e => {
        console.log(e)
      })
  }


  const submitOption = async e => {
    e.preventDefault()
    const form = e.currentTarget
    if (form.checkValidity() === false || !optionType) {
      e.stopPropagation()
      setValidated(true)
      return
    }
    setValidated(false)

    createArticleOptionMutation({
      variables: {
        title: optionTitle,
        description: optionDescription,
        maximum: optionMaximum ? Number(optionMaximum) : null,
        type: optionType.value,
        items: optionItems.filter(item => item),
        article: article.id,
        required: optionRequired
      },
    })
      .then(async () => {
        handleCloseArticleOption()
        await articleQuery.refetch()
      })
      .catch(e => {
        console.log(e)
      })
  }

  const handleInputOption = (e, index, input) => {
    let newOptionItems = JSON.parse(JSON.stringify(optionItems));
    newOptionItems[index][input] = e.target.value // Update it with the modified email.
    setOptionItems(newOptionItems) // Update the state.
  }

  const resetOptionForm = () => {
    setOptionId('')
    setOptionTitle('')
    setOptionDescription('')
    setOptionType({ value: 'multiple', label: 'Choix multiples' })
    setOptionItems([{ name: '', price: 0 }])
    setOptionMaximum('')
    setOptionRequired(false)
    setIsCategoryOption(false)
  }

  const optionsCombined = [...article.options, ...article?.category?.options.map(option => {
    return Object.assign({ option }, { ...option, isCategoryOption: true })
  })].sort((a, b) => a.number - b.number)

  const handleDragEndOptions = async result => {
    const { destination, source } = result

    if (!destination) {
      return
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    const options = Array.from(optionsCombined.filter(option => !option.articlesDisabled?.find(a => a.id === article.id)))
    const [removed] = options.splice(source.index, 1)
    options.splice(destination.index, 0, removed)

    await Promise.all(options.map((option, index) => (
      updateArticleOptionMutation({
        variables: {
          id: option.id, number: index,
          article: articleQuery?.article?.id
        },
      })
    )))
    await articleQuery.refetch()
  }

  return (
    <Fragment>
      <div className="header">
        <div className="container-fluid">
          <div className="header-body">
            <div className="row align-items-center">
              <div className="col-2 col-md-1">
                <button type="button" className="btn btn-white"
                        onClick={() => history.push('/menu?tab=articles&category=' + article.category?.id)}>
                  <i className="fe fe-arrow-left"/>
                </button>
              </div>
              <div className="col">
                <h6 className="header-pretitle">
                  {article.category?.name}
                </h6>
                <h1 className="header-title">
                  {article.name}
                </h1>
              </div>
              <div className="col-12 col-md-auto mt-4 mt-md-0 btn-group spacing">
                <button className="btn btn-primary lift mr-md-4 mr-3" onClick={handleShowArticleOption}>
                  Ajouter des options
                </button>
                <button className="btn btn-info lift mr-md-4 mr-3" data-toggle="modal" onClick={handleShowArticle}>
                  Modifier
                </button>
                <button className="btn btn-secondary lift mr-md-4 mr-3" onClick={() => {
                  if (window.confirm('Êtes-vous sûr de vouloir supprimer cet article ?')) _deleteArticle(article.id)
                }}>
                  Supprimer
                </button>
                <button className="btn btn-dark h-100" onClick={() =>
                  window.location.reload(true)
                }>
                  <i className="fe fe-refresh-cw"/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="row">
              <div className="col-12 col-lg-4">
                <div className="card">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col">
                        <h6 className="text-uppercase text-muted mb-2">
                          Prix
                        </h6>
                        <span className="h2 mb-0">{article.price}€</span>
                      </div>
                      <div className="col-auto">
                        {/* Icon */}
                        <span className="h2 fe fe-dollar-sign text-muted mb-0"/>
                      </div>
                    </div>
                    {/* / .row */}
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="card">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col">
                        <h6 className="text-uppercase text-muted mb-2">
                          Statut
                        </h6>
                        <span
                          className={`h2 mb-0 ${article.online ? 'text-success' : 'text-danger'}`}>{article.online ? 'En ligne' : 'Hors ligne'}</span>
                      </div>
                      <div className="col-auto">
                        <span className="h2 fe fe-activity text-muted mb-0"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="card">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col">
                        <h6 className="text-uppercase text-muted mb-2">
                          Rupture de stock ?
                        </h6>
                        <span
                          className={`h2 mb-0 ${article.soldOut ? 'text-danger' : 'text-success'}`}>{article.soldOut ? 'Oui' : 'Non'}</span>
                      </div>
                      <div className="col-auto">
                        <div className="custom-control custom-switch">
                          <input type="checkbox" className="custom-control-input" id="customSwitch1"
                                 checked={article.soldOut} onChange={async (e) => {
                            await updateArticleMutation({
                              variables: {
                                id: article.id,
                                soldOut: !article.soldOut,
                              },
                            })
                            await articleQuery.refetch()
                          }}/>
                          <label className="custom-control-label" htmlFor="customSwitch1"></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col">
                        <h6 className="text-uppercase text-muted mb-2">
                          Description
                        </h6>
                        <div className="row align-items-center no-gutters">
                          <div className="col-auto">
                        <span className="mr-2 mb-0">
                      {article.description}
                    </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-auto">
                        <span className="h2 fe fe-align-center text-muted mb-0"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="card">
              <div className="card-body text-center card-photo-article">
                <h6 className="text-uppercase text-muted mb-2">
                  Photo de l'article (Optionnel)
                </h6>
                <FileUpload mutation={uploadArticlePhoto} query={articleQuery} id={article?.id}
                            updateMutation={updateArticleMutation} image={article?.image} field={'image'}/>
              </div>
            </div>
            <div className="card">
              <div className="card-body text-center card-photo-article">
                <h6 className="text-uppercase text-muted mb-2">
                  Photo 2 de l'article (Optionnel)
                </h6>
                <FileUpload mutation={uploadArticlePhoto2} query={articleQuery} id={article?.id}
                            updateMutation={updateArticleMutation} image={article?.image2} field={'image2'}/>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12"><h2>Options</h2></div>
          <div className="col-12">
            <div className="card">
              <div className="table-responsive">
                <table className="table table-sm table-nowrap">
                  <thead>
                  <tr>
                    <th>
                <span className="text-muted sort" data-sort="orders-product">
                  Nom
                </span>
                    </th>
                    <th>
                <span className="text-muted sort" data-sort="orders-date">
                  Type
                </span>
                    </th>
                    <th>
                <span className="text-muted sort" data-sort="orders-total">
                  Maximum
                </span>
                    </th>
                    <th colSpan={2}>
                <span className="text-muted sort" data-sort="orders-method">
                  Description
                </span>
                    </th>
                  </tr>
                  </thead>
                  <DragDropContext onDragEnd={handleDragEndOptions}>
                    <Droppable droppableId="articles">
                      {(provided) => (
                        <tbody className="list" ref={provided.innerRef} {...provided.droppableProps}>
                        {optionsCombined.filter(option => !option.articlesDisabled?.find(a => a.id === article.id)).map((option, index) => (
                          <Draggable
                            draggableId={option.id.toString()}
                            key={option.id}
                            index={index}
                          >
                            {(provided) => (
                              <OptionItem
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                innerRef={provided.innerRef}

                                check={false}
                                option={option}
                                key={option.id}
                                editOption={() => {
                                  getCategories()

                                  setIsCategoryOption(option.isCategoryOption)

                                  setShowEditOption(true)
                                  setOptionId(option.id)
                                  setOptionTitle(option.title)
                                  setOptionType({ value: option.type, label: getOptionLabel(option.type) })
                                  setOptionDescription(option.description)
                                  setOptionMaximum(option.maximum)
                                  setOptionRequired(option.required)
                                  setOptionItems(option.items?.length > 0 ? option.items : [{ name: '', price: 0 }])
                                }}
                                hideOption={option.isCategoryOption ? async () => {
                                  await updateArticleOptionMutation({
                                    variables: {
                                      id: option.id,
                                      disableArticle: article.id
                                    },
                                  })
                                  await articleQuery.refetch()
                                } : null}
                                deleteOption={async () => {
                                  if (window.confirm('Êtes-vous sûr de vouloir supprimer cette option ?')) _deleteArticleOption(option.id)
                                }}/>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  </DragDropContext>
                  {optionsCombined.filter(option => option.articlesDisabled?.find(a => a.id === article.id)).map((option) => (
                    <OptionItem
                      style={{ opacity: .4 }}
                      check={false}
                      option={option}
                      key={option.id}
                      editOption={() => {
                        getCategories()

                        setIsCategoryOption(option.isCategoryOption)

                        setShowEditOption(true)
                        setOptionId(option.id)
                        setOptionTitle(option.title)
                        setOptionType({ value: option.type, label: getOptionLabel(option.type) })
                        setOptionDescription(option.description)
                        setOptionMaximum(option.maximum)
                        setOptionRequired(option.required)
                        setOptionItems(option.items?.length > 0 ? option.items : [{ name: '', price: 0 }])
                      }}
                      hideOption={async () => {
                        await updateArticleOptionMutation({
                          variables: {
                            id: option.id,
                            disableArticle: article.id
                          },
                        })
                        await articleQuery.refetch()
                      }}
                      deleteOption={async () => {
                        if (window.confirm('Êtes-vous sûr de vouloir supprimer cette option ?')) _deleteArticleOption(option.id)
                      }}/>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showArticleOption} onHide={handleCloseArticleOption} size="lg" backdrop="static"
             className="modal-full-height">
        <Form noValidate validated={validated} onSubmit={submitOption} className="d-flex flex-column h-100"
              onKeyPress={(e) => e.which === 13 && e.preventDefault()}>
          <Modal.Header closeButton>
            <Modal.Title>
              Ajouter une option
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="inputName">Titre</label>
              <small className="form-text text-muted">Entrez le titre de l'option</small>
              <input
                required
                type="text"
                className="form-control"
                placeholder="Sauces"
                value={optionTitle}
                onChange={(e) => setOptionTitle(e.target.value)}
                autoComplete="off"/>
              <Form.Control.Feedback type="invalid">Veuillez entrer un titre</Form.Control.Feedback>
            </div>
            <div className="form-group">
              <label htmlFor="inputName">Description</label>
              <small className="form-text text-muted">Une indication pour l'utilisateur</small>
              <textarea
                className="form-control"
                placeholder="Choisissez vos sauces"
                value={optionDescription}
                onChange={(e) => setOptionDescription(e.target.value)}/>
            </div>
            <div className="form-group">
              <label htmlFor="inputName">Type</label>
              <small className="form-text text-muted">
                Vous décidez si votre champ est à choix unique ou multiple
              </small>
              <ModalSelect
                required
                isValid={validated ? optionType : null}
                isSearchable={false}
                isClearable={false}
                value={optionType}
                onChange={(option) => setOptionType(option)}
                options={[
                  { value: 'unique', label: 'Choix unique' },
                  { value: 'multiple', label: 'Choix multiples' },
                ]}
              />
            </div>
            <div className="form-group">
              <label>Requis</label>
              <div className="custom-control custom-switch">
                <input type="checkbox" className="custom-control-input" id="requiredOption" checked={optionRequired}
                       onChange={(e) => setOptionRequired(e.target.checked)}/>
                <label className="custom-control-label"
                       htmlFor="requiredOption">{optionRequired ? 'Oui' : 'Non'}</label>
              </div>
            </div>
            {optionType?.value === 'multiple' && <div className="form-group">
              <label htmlFor="inputName">Maximum</label>
              <small className="form-text text-muted">Nombre d'éléments maximum sélectionnables</small>
              <input
                type="number"
                className="form-control"
                value={optionMaximum}
                onChange={(e) => setOptionMaximum(e.target.value)}
                autoComplete="off"/>
            </div>}
            <div className="form-group">
              <label htmlFor="inputName">Options</label>
              <small className="form-text text-muted">Nom, suivi du prix. Si le prix est compris dans le menu, laissez
                le champ à 0</small>
              {optionItems.map((item, index) =>
                <div className="row" key={index}>
                  <div className="col-6">
                    <div className="input-group input-group-merge">
                      <input
                        type="text"
                        className="form-control form-control-prepended"
                        placeholder="Nom"
                        value={item.name}
                        onChange={(e) => handleInputOption(e, index, 'name')}
                        autoComplete="off"
                      />
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <span className="fe fe-clipboard"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <CurrencyInput
                      className="form-control form-control-prepended"
                      placeholder="Prix"
                      defaultValue={item.price}
                      onChange={(e) => handleInputOption({ target: { value: e } }, index, 'price')}
                    />
                  </div>
                  <div className="col-2 d-flex align-items-center justify-content-around">
                    <button type="button" className="btn btn-sm btn-outline-danger mr-2"
                            onClick={() => {
                              let newOptionItems = optionItems.filter((item, idx) => index !== idx)
                              if (newOptionItems.length === 0) {
                                newOptionItems = [{ name: '', price: 0 }]
                              }
                              setOptionItems(newOptionItems)
                            }}>
                      <span className="fe fe-trash"/>
                    </button>
                    <button type="button" className="btn btn-sm btn-outline-primary"
                            onClick={() => setOptionItems([...optionItems, { name: '', price: 0 }])}>
                      <span className="fe fe-plus"/>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-between">
            <button type="button" className="btn btn-secondary" onClick={handleCloseArticleOption}>Annuler</button>
            <button type="submit" className="btn btn-primary">Ajouter</button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal show={showEditOption} onHide={handleCloseEditOption} backdrop="static" centered>
        <Form onKeyPress={(e) => e.which === 13 && e.preventDefault()} noValidate validated={validated}
              onSubmit={updateArticleOption}>
          <Modal.Header closeButton>
            <Modal.Title>
              Editer une option
            </Modal.Title>
            {isCategoryOption &&
            <Modal.Title className="ml-5">
              <i className="fe fe-alert-triangle"/>&nbsp;
              Vous modifiez une option générale
            </Modal.Title>
            }
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Nom</Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                placeholder="Sauces"
                value={optionTitle}
                onChange={(e) => setOptionTitle(e.target.value)}
                autoComplete="off"
              />
              <Form.Control.Feedback type="invalid">Veuillez entrer un nom</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <small className="form-text text-muted">Une indication pour l'utilisateur</small>
              <textarea
                className="form-control"
                placeholder="Choisissez vos sauces"
                value={optionDescription}
                onChange={(e) => setOptionDescription(e.target.value)}/>
            </Form.Group>
            <Form.Group>
              <Form.Label>Type</Form.Label>
              <small className="form-text text-muted">
                Vous décidez si votre champ est à choix unique ou multiple
              </small>
              <ModalSelect
                isValid={validated ? optionType : null}
                required
                isSearchable={false}
                isClearable={false}
                value={optionType}
                onChange={(option) => setOptionType(option)}
                options={[
                  { value: 'unique', label: 'Choix unique' },
                  { value: 'multiple', label: 'Choix multiples' },
                ]}
              />
              <Form.Control.Feedback type="invalid">Veuillez choisir une option</Form.Control.Feedback>
            </Form.Group>
            <div className="form-group">
              <label>Requis</label>
              <div className="custom-control custom-switch">
                <input type="checkbox" className="custom-control-input" id="requiredOption" checked={optionRequired}
                       onChange={(e) => setOptionRequired(e.target.checked)}/>
                <label className="custom-control-label"
                       htmlFor="requiredOption">{optionRequired ? 'Oui' : 'Non'}</label>
              </div>
            </div>
            {optionType?.value === 'multiple' &&
            <div className="form-group">
              <label htmlFor="inputName">Maximum</label>
              <small className="form-text text-muted">Nombre d'éléments maximum sélectionnables</small>
              <input
                type="number"
                className="form-control"
                value={optionMaximum}
                onChange={(e) => setOptionMaximum(e.target.value)}
                autoComplete="off"/>
            </div>
            }
            <div className="form-group">
              <label htmlFor="inputName">Options</label>
              <small className="form-text text-muted">Nom, suivi du prix. Si le prix est compris dans le menu, laissez
                le champ à 0</small>
              {optionItems.map((item, index) =>
                <div className="row" key={index}>
                  <div className="col-6">
                    <div className="input-group input-group-merge">
                      <input
                        type="text"
                        className="form-control form-control-prepended"
                        placeholder="Nom"
                        value={item.name}
                        onChange={(e) => handleInputOption(e, index, 'name')}
                        autoComplete="off"
                      />
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <span className="fe fe-clipboard"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <CurrencyInput
                      className="form-control form-control-prepended"
                      placeholder="Prix"
                      defaultValue={item.price}
                      onChange={(e) => handleInputOption({ target: { value: e } }, index, 'price')}
                    />
                  </div>
                  <div className="col-2 d-flex align-items-center justify-content-around">
                    <button type="button" className="btn btn-sm btn-outline-danger mr-2"
                            onClick={() => {
                              let newOptionItems = optionItems.filter((item, idx) => index !== idx)
                              if (newOptionItems.length === 0) {
                                newOptionItems = [{ name: '', price: 0 }]
                              }
                              setOptionItems(newOptionItems)
                            }}>
                      <span className="fe fe-trash"></span>
                    </button>
                    <button type="button" className="btn btn-sm btn-outline-primary"
                            onClick={() => setOptionItems([...optionItems, { name: '', price: 0 }])}>
                      <span className="fe fe-plus"></span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-between">
            <button type="button" className="btn btn-secondary" onClick={handleCloseEditOption}>Annuler</button>
            <button type="submit" className="btn btn-primary">Sauvegarder</button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={showArticle} onHide={handleCloseArticle} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Modifier l'article
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="inputName">Nom</label>
            <input
              type="text"
              className="form-control"
              id="inputName"
              placeholder="Entrez le nom de l'article"
              value={articleName}
              onChange={(e) => setArticleName(e.target.value)}
              autoComplete="off"/>
          </div>
          <div className="form-group">
            <label htmlFor="inputName">Catégorie</label>
            <Select
              value={category}
              onChange={(option) => {
                setCategory(option)
              }}
              options={categories?.categories?.map(category => (
                { value: category.id, label: category.name }
              ))
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="inputName">Prix</label>
            <CurrencyInput
              className="form-control"
              placeholder="Entrez le prix"
              onChange={(e) => setPrice(e)}
              defaultValue={price}
            />
          </div>
          <div className="form-group">
            <label htmlFor="inputName">Description</label>
            <textarea
              className="form-control"
              id="inputName"
              placeholder="Entrez la description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}/>
          </div>
          <div className="form-group">
            <label htmlFor="inputName">Statut</label>
            <div className="custom-control custom-switch">
              <input type="checkbox" className="custom-control-input" id="statutSwitch" checked={online}
                     onChange={(e) => setOnline(e.target.checked)}/>
              <label className="custom-control-label"
                     htmlFor="statutSwitch">{online ? 'En ligne' : 'Hors ligne'}</label>
            </div>
          </div>
          {restaurant?.id === 34 &&
          <>
            <div className="form-group">
              <label htmlFor="editMinimum">Minimum d'articles pour commander</label>
              <input
                type="number"
                className="form-control"
                id="editMinimum"
                placeholder="10"
                value={articleMinimumQuantity}
                onChange={(e) => setArticleMinimumQuantity(e.target.value)}
                autoComplete="off"/>
            </div>
          </>}
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <button type="button" className="btn btn-secondary" onClick={handleCloseArticle}>Annuler</button>
          <button type="submit" onClick={updateArticle} className="btn btn-primary">Sauvegarder</button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
}

const ARTICLE_QUERY = gql`
  query articleQuery($id: ID!) {
    article(id: $id) {
      id
      name
      price
      online
      description
      image
      image2
      soldOut
      category {
        id
        name
        options {
          id
          title
          description
          maximum
          type
          required
          items {
            id
            name
            price
          }
          number
          articlesDisabled {
            id
          }
        }
      }
      options {
        id
        title
        description
        maximum
        required
        type
        items {
          id
          name
          price
        }
        number
      }
    }
  }
`

const UPDATE_ARTICLE_MUTATION = gql`
  mutation updateArticleMutation($id: ID!, $name: String, $category: ID, $price: Float, $online: Boolean, $description: String, $image: String, $image2: String, $soldOut: Boolean) {
    updateArticle(id: $id, name: $name, category: $category, price: $price, online: $online, description: $description, image: $image, image2: $image2, soldOut: $soldOut) {
      id
      name
      price
      online
      description
      image
      image2
      soldOut
      category {
        id
        name
      }
      options {
        id
        title
        description
        maximum
        required
        type
        items {
          id
          name
          price
        }
      }
    }
  }
`

const DELETE_MUTATION = gql`
  mutation deleteArticle($id: ID!) {
    deleteArticle(id: $id) {
      id
    }
  }
`

const DELETE_OPTION_MUTATION = gql`
  mutation deleteArticleOption($id: ID!) {
    deleteArticleOption(id: $id) {
      id
    }
  }
`

const DELETE_OPTION_ITEM_MUTATION = gql`
  mutation deleteArticleOptionItem($id: ID!) {
    deleteArticleOptionItem(id: $id) {
      id
    }
  }
`

const CREATE_ARTICLE_OPTION_MUTATION = gql`
  mutation createArticleOption($title: String!, $description: String, $maximum: Int, $type: String!, $required: Boolean, $items: [ArticleOptionItemInput!], $article: ID!) {
    createArticleOption(title: $title, description: $description, maximum: $maximum, type: $type, required: $required, items: $items, article: $article) {
      id
      title
      description
      type
      maximum
      required
    }
  }
`

const UPDATE_ARTICLE_OPTION_MUTATION = gql`
  mutation updateArticleOption($id: ID!, $title: String, $description: String, $maximum: Int, $type: String, $required: Boolean, $items: [ArticleOptionItemInput!], $number: Int, $article: ID, $disableArticle: ID) {
    updateArticleOption(id: $id, title: $title, description: $description, maximum: $maximum, type: $type, required: $required, items: $items, number: $number, article: $article, disableArticle: $disableArticle) {
      id
      title
      description
      type
      maximum
      required
    }
  }
`

const CATEGORIES_QUERY = gql`
  query categoriesQuery {
    categories {
      id
      name
    }
  }
`

const UPLOAD_FILE_ARTICLE = gql`
  mutation uploadArticlePhoto($file: Upload!, $article: ID!) {
    uploadArticlePhoto(file: $file, article: $article) {
      id
    }
  }
`

const UPLOAD_FILE2_ARTICLE = gql`
  mutation uploadArticlePhoto2($file: Upload!, $article: ID!) {
    uploadArticlePhoto2(file: $file, article: $article) {
      id
    }
  }
`

export default compose(
  graphql
  (ARTICLE_QUERY, {
      name: 'articleQuery',
      options: props => ({
        variables: {
          id: props.match.params.id,
        },
      }),
    }
  ),
  graphql(UPDATE_ARTICLE_MUTATION, {
    name: 'updateArticleMutation',
  }),
  graphql(UPDATE_ARTICLE_OPTION_MUTATION, {
    name: 'updateArticleOptionMutation',
  }),
  graphql(DELETE_MUTATION, {
    name: 'deleteArticle',
  }),
  graphql(DELETE_OPTION_MUTATION, {
    name: 'deleteArticleOption',
  }),
  graphql(DELETE_OPTION_ITEM_MUTATION, {
    name: 'deleteArticleOptionItem',
  }),
  graphql(CREATE_ARTICLE_OPTION_MUTATION, {
    name: 'createArticleOptionMutation',
  }),
  graphql(UPLOAD_FILE_ARTICLE, {
    name: 'uploadArticlePhoto',
  }),
  graphql(UPLOAD_FILE2_ARTICLE, {
    name: 'uploadArticlePhoto2',
  }),
  withRouter,
)
(Article)
