import React, { Fragment, useState } from 'react';
import compose from 'lodash.flowright';
import { graphql } from 'react-apollo';
import { gql } from 'apollo-boost';

function Public ({ restaurant, categoriesQuery }) {
  const [categoryTab, setCategoryTab] = useState(0)
  return (
    <Fragment>
      <div className="header" style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        minHeight: '380px'
      }}>
        <figure style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -1
        }}>
          <img src="/assets/img/demo/3.png" className="header-img-top" alt="presentation" style={{
            objectFit: 'cover',
            height: '100%'
          }}/>
        </figure>
        <div style={{ flexBasis: '80px', flexGrow: 1, flexShrink: 1 }}/>
        <div className="col-12 col-md-6 col-lg-5 bg-white py-4 d-flex align-items-center" style={{
          height: '100%',
          flex: 1,
        }}>
          <div className="container-fluid">
            <div className="row align-items-end">
              <div className="col">
                <h1 className="header-title mb-4">{restaurant?.name}</h1>
                <div className="mb-2">
                  <span>$&nbsp;&nbsp;</span>
                  <span>Comfort Food - </span>
                  <span>American - </span>
                  <span>Chicken</span>
                </div>
                <div className="mb-2">(500)+</div>
                <div>{restaurant?.address}</div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ flexBasis: '80px', flexGrow: 1, flexShrink: 1 }}/>
      </div>
      <div className="header">
        <div className="container-fluid">
          <div className="header-body mt-n5 mt-md-n6">
            <div className="row align-items-center">
              <div className="col">
                <ul className="nav nav-tabs nav-overflow header-tabs">
                  {categoriesQuery.onlineCategories?.filter(category => category.articles?.length > 0 ).map((category, index) => (
                    <li className="nav-item" key={index}>
                      <a href={`#category${index}`} className={`nav-link ${index === categoryTab ? 'active' : ''}`} onClick={() => setCategoryTab(index)}>
                        {category.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        {
          categoriesQuery.onlineCategories?.filter(category => category.articles?.length > 0 ).map((category, index) =>
            <Fragment key={category.id}>
              <div className="row" id={`category${index}`}>
                <div className="col-12">
                  <h2>{category.name}</h2>
                </div>
              </div>
              <div className="row">
                {category.articles?.map(article =>
                  <div className="col-6 col-md-4 d-flex" key={article.id}>
                    <div className="card w-100">
                      <div className="card-body">
                        <h4 className="mb-1">{article.name}</h4>
                        <br/>
                        <p>{article.description.substring(0, 80)}{article.description?.length > 80 ? '...' : ''}</p>
                        <h3>{article.price}€</h3>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Fragment>
          )
        }
      </div>
    </Fragment>
  )
}


const CATEGORIES_QUERY = gql`
  query categoriesQuery {
    onlineCategories {
      id
      name
      articles {
        id
        name
        price
        description
        online
        soldOut
      }
    }
  }
`
export default compose(
  graphql(CATEGORIES_QUERY, {
    name: 'categoriesQuery', // name of the injected prop: this.props.categoriesQuery...
    options: {
      fetchPolicy: 'network-only',
    },
  }),
)(Public);
