import React, { useEffect, useState } from 'react'
import { gql } from 'apollo-boost'
import { useLocation, Link } from 'react-router-dom'
import Loader from '../components/Loader'
import OrderItem from '../components/orders/OrderItem'
import { useQuery } from '@apollo/react-hooks'

export function useQueryParams () {
  return new URLSearchParams(useLocation().search)
}

function History (props) {
  const { subscribeToMore, ...result } = useQuery(ORDERS_QUERY);

  return (
    <HistorySub
      {...result}
      {...props}
      subscribeToNewOrders={() =>
        subscribeToMore({
          document: ORDERS_SUBSCRIPTION,
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) return prev;
            const newOrder = subscriptionData.data.ordersSubscription
            if (prev.orders.orders.find(order => order.id === newOrder.id)) {
              return prev
            }
            return Object.assign({}, prev, {
              orders: [...prev.orders.orders, newOrder],
            })
          }
        })
      }
    />
  );
}

function HistorySub ({ subscribeToNewOrders, restaurant, me }) {
  let query = useQueryParams()
  const page = query.get('page') ? parseInt(query.get('page')) : 1
  const isDeliverer = localStorage.getItem('isDeliverer') === 'true'

  const ordersQuery = useQuery(ORDERS_QUERY, {
    name: 'ordersQuery', // name of the injected prop: this.props.ordersQuery...
    variables: {
      page
    },
    options: {
      fetchPolicy: 'network-only',
    },
    props: props =>
      Object.assign({}, props, {
        subscribeToNewOrders: () => {
          return props.ordersQuery.subscribeToMore({
            document: ORDERS_SUBSCRIPTION,
            variables: {
              page
            },
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) {
                return prev
              }
              const newOrder = subscriptionData.data.ordersSubscription
              if (prev.orders.orders.find(order => order.id === newOrder.id)) {
                return prev
              }
              return Object.assign({}, prev, {
                orders: [...prev.orders.orders, newOrder],
              })
            },
          })
        },
      }),
  })

  useEffect(() => {
    subscribeToNewOrders()
  }, [subscribeToNewOrders])


  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([])
  const handleChange = event => {
    setSearchTerm(event.target.value)
  }

  useEffect(() => {
    let orders = ordersQuery?.data?.orders?.orders
    setSearchResults(!searchTerm
      ? orders
      : orders?.filter(order =>
        order.number.toString().toLowerCase().includes(searchTerm)
        ||
        order.name.toString().toLowerCase().includes(searchTerm)
      ))
  }, [searchTerm, ordersQuery.data])

  if (ordersQuery.loading) {
    return (
      <Loader/>
    )
  }

  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="header mt-md-5">
            <div className="header-body">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="header-title">
                    Historique des commandes ({ordersQuery?.data?.orders?.total})
                  </h1>
                </div>
                <div className="col-auto">
                  <button className="btn btn-dark h-100" onClick={() =>
                    window.location.reload(true)
                  }>
                    <i className="fe fe-refresh-cw"/>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="card" data-toggle="lists" data-options='{"valueNames": ["orders-order", "orders-product", "orders-date", "orders-total", "orders-status", "orders-method"]}'>
            <div className="card-header card-header-search">
              <form>
                <div className="input-group input-group-flush">
                  <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fe fe-search"/>
                      </span>
                  </div>
                  <input className="form-control search" type="search" placeholder="Rechercher par numéro de commande ou nom de client"
                         value={searchTerm}
                         onChange={handleChange}/>
                </div>
              </form>
            </div>
            <div className="table-responsive">
              <table className="table table-nowrap card-table table-hover">
                <thead>
                <tr>
                  {(isDeliverer || me?.admin === true) &&
                  <th>
                    <span className="text-muted sort" data-sort="orders-order">
                      Restaurant
                    </span>
                  </th>
                  }
                  <th>
                    <span className="text-muted sort" data-sort="orders-order">
                      Commande
                    </span>
                  </th>
                  <th>
                    <span className="text-muted sort" data-sort="orders-product">
                      Client
                    </span>
                  </th>
                  <th>
                    <span className="text-muted sort" data-sort="orders-date">
                      Date / Heure
                    </span>
                  </th>
                  {!isDeliverer &&
                  <th>
                    <span className="text-muted sort" data-sort="orders-total">
                      Total
                    </span>
                  </th>
                  }
                  <th>
                    <span className="text-muted sort" data-sort="orders-status">
                      Statut
                    </span>
                  </th>
                  <th>
                    <span className="text-muted sort" data-sort="orders-status">
                      Livreur
                    </span>
                  </th>
                </tr>
                </thead>
                <tbody className="list">
                {searchResults?.map(order => (
                  <OrderItem order={order} key={order.id} type="history" isDeliverer={isDeliverer} admin={me?.admin === true}/>
                ))}
                </tbody>
              </table>
            </div>
            <div className="card-footer d-flex justify-content-between">
              {page > 1 &&
              <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
                <li className="page-item">
                  <Link to={`/history?page=${page - 1}`} className="page-link pl-0 pr-4 border-right">
                    <i className="fe fe-arrow-left mr-1"/>
                  </Link>
                </li>
              </ul>
              }
              <ul className="list-pagination pagination pagination-tabs card-pagination ml-auto mr-auto">
                {page > 1 &&
                <li>
                  <Link to={`/history?page=${page - 1}`} className="page">{page - 1}</Link>
                </li>
                }
                <li className="active">
                  <Link to={`/history?page=${page}`} className="page">{page}</Link>
                </li>
                {page < (ordersQuery?.data?.orders?.total / 10) &&
                <li>
                  <Link to={`/history?page=${page + 1}`} className="page">{page + 1}</Link>
                </li>
                }
              </ul>
              {page < (ordersQuery?.data?.orders?.total / 10) &&
              <ul className="list-pagination-next pagination pagination-tabs card-pagination">
                <li className="page-item">
                  <Link to={`/history?page=${page + 1}`} className="page-link pl-4 pr-0 border-left">
                    <i className="fe fe-arrow-right ml-1"/>
                  </Link>
                </li>
              </ul>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const ORDERS_QUERY = gql`
  query OrdersQuery($page: Int) {
    orders(page: $page) {
      orders {
        id
        note
        status
        addedAt
        amount
        number
        name
        delivererName
        deliverer {
          id
        }
        restaurant {
          id
          name
        }
      }
      total
    }
  }
`
const ORDERS_SUBSCRIPTION = gql`
  subscription OrdersSubscription {
    ordersSubscription {
      orders {
        id
        note
        status
        addedAt
        amount
        number
        name
        delivererName
        deliverer {
          id
        }
        restaurant {
          id
          name
        }
      }
      total
    }
  }
`


export default (History)
