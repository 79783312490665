import React from 'react'
import { withRouter } from 'react-router-dom'

const LogoutPage = () => (
  <div className="pa4 flex justify-center bg-white">
    <form>
      <h1>Vous êtes actuellement déconnecté.</h1>
    </form>
  </div>
)

export default withRouter(LogoutPage)
