import React, { PureComponent } from 'react'
import Chart from "./index";

export default class DoughnutGraph extends PureComponent {
  chartRef = React.createRef();

  componentDidMount () {
    const chartRef = this.chartRef.current.getContext("2d");
    const { labels, data } = this.props

    new Chart(chartRef, {
      type: 'doughnut',
      options: {
        events: ['click', 'mouseout'],
        tooltips: {
          callbacks: {
            afterLabel: function () {
              return '%'
            }
          }
        },
        legend: {
          display: true,
          labels: {
            fontColor: '#5d687c',
          }
        }
      },
      data: {
        labels,
        datasets: [{
          data,
          backgroundColor: ['#e74c3c', '#3498db', '#2ecc71', '#f1c40f']
        }]
      }
    });
  }


  render () {
    return (
      <>
        <div className="chart">
          <canvas
            ref={this.chartRef}
            className="chart-canvas"
          />
        </div>
      </>
    )
  }
}
