import React, { useState } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { graphql } from 'react-apollo'
import { gql } from 'apollo-boost'

function ForgotPassword ({ loginMutation }) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const _login = async e => {
    e.preventDefault();
    setError('');
    setLoading(true)
    loginMutation({
      variables: {
        email,
      },
    })
      .then(() => {
        setSuccess(true)
        setEmail('')
        setLoading(false)
      })
      .catch(() => {
        setError('Une erreur est survenue. Veuillez réessayer')
        setLoading(false)
      })
  }

  return (
    <div className="container">
      <div className="row position-absolute" style={{ top: 20 }}>
        <div className="col">
          <img
            src="/assets/img/logo.png"
            className="navbar-brand-img
    mx-auto"
            alt="logo"
            style={{ maxHeight: '3rem' }}
          />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-8 col-xl-6 my-5">
          <h1 className="display-4 text-center mb-3">
            Mot de passe oublié ?
          </h1>
          <p className="text-muted text-center mb-5">
            Réinitialisez votre mot de passe
          </p>
          {success ?
            <div className="text-center">
              <p className="text-success mb-5 h2">Si votre email correspond à un compte, un lien vous a été envoyé
                pour réinitialiser votre mot de
                passe
              </p>
              <button className="btn btn-link" onClick={() => {
                setSuccess(false)
                setEmail('')
              }}>Réessayez</button>
            </div>
            :
            <form onSubmit={_login}>
              <div className="form-group">
                <label>Email Address</label>
                <input
                  autoFocus
                  type="email"
                  className="form-control"
                  placeholder="name@address.com"
                  onChange={e => setEmail(e.target.value)}
                  value={email}
                  required
                />
              </div>
              {error && <div className="text-danger">{error}</div>}
              <button
                disabled={loading}
                className="btn btn-lg btn-block btn-primary mb-3"
                type="submit">
                {loading ? <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div> : <span>Envoyer</span>}
              </button>
              <div className="text-center">
                <small className="text-muted text-center">
                  <Link to="/login">Revenir sur la page de connexion</Link>
                </small>
              </div>
            </form>
          }
        </div>
      </div>
    </div>
  )
}

const LOGIN_USER_MUTATION = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
       id
    }
  }
`

export default graphql(LOGIN_USER_MUTATION, { name: 'loginMutation' })(
  withRouter(ForgotPassword),
)
