import React, { useState } from 'react'

const OpenHour = props => {
  const [allDay, setAllDay] = useState(props.value === 'allDay')
  const [closed, setClosed] = useState(props.value === 'closed')
  const [valid, setValid] = useState(true)

  return (
    <div className="form-group">
      <label>
        {props.label}
      </label>
      <div className="row">
        <div className="col-12 col-md-8">
          <input type="text" className="form-control" placeholder="10:00-14:00,18:00-22:00" disabled={closed || allDay}
                 value={allDay || closed ? '' : props.value} onChange={(e) => {
            let allValid = true
            e.target.value.split(',').map((value) => {
              if (!/^([0-1][0-9]|2[0-3]):([0-5][0-9])-([0-1][0-9]|2[0-3]):([0-5][0-9])$/.test(value.trim()))
                allValid = false
              return allValid
            })
            setValid(allValid)
            props.onChange(e, allValid)
          }}
          />
        </div>
        <div className="col-12 col-md-4">
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id={`check1${props.label.replace(/\s+/g, '')}`}
                   checked={allDay} onChange={(e) => {
              setAllDay(e.target.checked)
              props.onChange(e.target.checked === true ? { target: { value: 'allDay' } } : null, true)
            }}
                   disabled={closed}/>
            <label className="form-check-label" htmlFor={`check1${props.label.replace(/\s+/g, '')}`}>
              Journée entière
            </label>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id={`check2${props.label.replace(/\s+/g, '')}`}
                   checked={closed} onChange={(e) => {
              setClosed(e.target.checked)
              props.onChange(e.target.checked === true ? { target: { value: 'closed' } } : null, true)
            }}
                   disabled={allDay}/>
            <label className="form-check-label" htmlFor={`check2${props.label.replace(/\s+/g, '')}`}>
              Fermé
            </label>
          </div>
        </div>
        {!valid && <div className="col-12 text-danger">Le format est invalide</div>}
      </div>
    </div>
  )
}

export default OpenHour
