export const emojis = [
  { label: '🍇', value: 'raisin' },
  { label: '🍈', value: 'melon' },
  { label: '🍉', value: 'pastèque' },
  { label: '🍊', value: 'mandarine' },
  { label: '🍋', value: 'citron' },
  { label: '🍌', value: 'banane' },
  { label: '🍍', value: 'ananas' },
  { label: '🥭', value: 'mangue' },
  { label: '🍎', value: 'pomme' },
  { label: '🍏', value: 'pomme' },
  { label: '🍐', value: 'poire' },
  { label: '🍑', value: 'pêche' },
  { label: '🍒', value: 'cerise' },
  { label: '🍓', value: 'fraise' },
  { label: '🥝', value: 'kiwi' },
  { label: '🍅', value: 'tomate' },
  { label: '🥥', value: 'noix de coco' },
  { label: '🥑', value: 'avocat' },
  { label: '🍆', value: 'aubergine' },
  { label: '🥔', value: 'pomme de terre' },
  { label: '🥕', value: 'carotte' },
  { label: '🌽', value: 'maïs' },
  { label: '🌶', value: 'piment' },
  { label: '🥒', value: 'concombre' },
  { label: '🥬', value: 'salade' },
  { label: '🥦', value: 'broccoli' },
  { label: '🧄', value: 'ail' },
  { label: '🧅', value: 'oignon' },
  { label: '🍄', value: 'champignon' },
  { label: '🥜', value: 'cacahuète' },
  { label: '🌰', value: 'châtaigne' },
  { label: '🍞', value: 'pain' },
  { label: '🥐', value: 'croissant' },
  { label: '🥖', value: 'baguette' },
  { label: '🥨', value: 'pretzel' },
  { label: '🥯', value: 'bagel' },
  { label: '🥞', value: 'pancake' },
  { label: '🧇', value: 'gaufre' },
  { label: '🧀', value: 'fromage' },
  { label: '🍖', value: 'viande' },
  { label: '🍗', value: 'poulet' },
  { label: '🥩', value: 'viande' },
  { label: '🥓', value: 'bacon' },
  { label: '🍔', value: 'burger' },
  { label: '🍟', value: 'frites' },
  { label: '🍕', value: 'pizza' },
  { label: '🌭', value: 'hot-dog' },
  { label: '🥪', value: 'sandwich' },
  { label: '🌮', value: 'taco' },
  { label: '🌯', value: 'burrito' },
  { label: '🥙', value: 'kebab' },
  { label: '🧆', value: 'falafel' },
  { label: '🍳', value: 'poele' },
  { label: '🥘', value: 'paella' },
  { label: '🍲', value: 'nourriture' },
  { label: '🥣', value: 'bol' },
  { label: '🥗', value: 'salade' },
  { label: '🍿', value: 'pop-corn' },
  { label: '🧈', value: 'beurre' },
  { label: '🧂', value: 'sel' },
  { label: '🥫', value: 'conserve' },
  { label: '🍱', value: 'bento' },
  { label: '🍘', value: 'riz' },
  { label: '🍙', value: 'riz' },
  { label: '🍚', value: 'riz' },
  { label: '🍛', value: 'riz' },
  { label: '🍜', value: 'bol' },
  { label: '🍝', value: 'spaghetti' },
  { label: '🍠', value: 'patate douce' },
  { label: '🍢', value: 'oden' },
  { label: '🍣', value: 'sushi' },
  { label: '🍤', value: 'crevette' },
  { label: '🍥', value: 'poisson' },
  { label: '🥮', value: 'gâteau de lune' },
  { label: '🍡', value: 'dango' },
  { label: '🥟', value: 'pâte' },
  { label: '🥠', value: 'biscuit' },
  { label: '🥡', value: 'boîte' },
  { label: '🦪', value: 'huître' },
  { label: '🍦', value: 'glace' },
  { label: '🍧', value: 'glace' },
  { label: '🍨', value: 'glace' },
  { label: '🍩', value: 'donut' },
  { label: '🍪', value: 'cookie' },
  { label: '🎂', value: 'gâteau' },
  { label: '🍰', value: 'gâteau' },
  { label: '🧁', value: 'cupcake' },
  { label: '🥧', value: 'tarte' },
  { label: '🍫', value: 'chocolat' },
  { label: '🍬', value: 'bonbon' },
  { label: '🍭', value: 'bonbon' },
  { label: '🍮', value: 'crème' },
  { label: '🍯', value: 'miel' },
  { label: '🍼', value: 'biberon' },
  { label: '🥛', value: 'lait' },
  { label: '☕', value: 'café' },
  { label: '🍵', value: 'tasse' },
  { label: '🍶', value: 'saké' },
  { label: '🍾', value: 'champagne' },
  { label: '🍷', value: 'vin' },
  { label: '🍸', value: 'cocktail' },
  { label: '🍹', value: 'cocktail' },
  { label: '🍺', value: 'bière' },
  { label: '🍻', value: 'bières' },
  { label: '🥂', value: 'verres' },
  { label: '🥃', value: 'verre' },
  { label: '🥤', value: 'gobelet' },
  { label: '🧃', value: 'brique de jus' },
  { label: '🧉', value: 'maté' },
  { label: '🧊', value: 'glaçon' },
  { label: '🥢', value: 'baguettes' },
  { label: '🍽', value: 'assiette' },
  { label: '🍴', value: 'couverts' },
  { label: '🥄', value: 'cuillère' }
]
