import React from 'react'
import { useHistory } from 'react-router-dom'
import { format } from 'date-fns'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

export default function OrderItem({ order, type, isDeliverer, audio, admin }) {
  const history = useHistory()
  const intl = useIntl()

  let formatStr = 'dd/MM HH:mm'
  if (type === 'history')
    formatStr = 'dd/MM/yyyy HH:mm'

  let amount = <td className="orders-total">
    {order.amount}€
  </td>

  if (isDeliverer)
    amount = null

  return (
    <tr onClick={() => {
      if (audio) {
        audio.pause()
        audio.currentTime = 0
      }
      toast.dismiss()
      history.push(`/order/${order.id}`)
    }} className="cursor-pointer py-3">
      {order.restaurant.id !== 23 && order.restaurant.id !== 31 &&
      (isDeliverer || admin) &&
      <td className="orders-order text-info font-weight-bold">
        {order.restaurant?.name}
      </td>
      }
      <td className="orders-order text-primary">
        #{order.number}
      </td>
      <td className="orders-product py-4">
        {order.name}
      </td>
      <td className="orders-date">
        {order.addedAt && format(new Date(order.addedAt), formatStr)}
      </td>
      {amount}
      <td className="orders-status">
        <div className={`badge font-size-base text-uppercase ${order.status}`}>
          {intl.formatMessage({ id: order.status })}
        </div>
      </td>
      <td>
        {order.delivererName}
      </td>
    </tr>
  );
}
