import React, { useEffect, useState } from 'react'
import { graphql } from 'react-apollo'
import { gql } from 'apollo-boost'
import { Modal, Dropdown } from 'react-bootstrap'
import compose from 'lodash.flowright'
import {
  Link,
  useLocation
} from 'react-router-dom'
import DelivererItem from '../components/menu/DelivererItem'
import Loader from '../components/Loader'
import { useQuery } from "@apollo/react-hooks";
import { LIST_RESTAURANTS_QUERY, RESTAURANT_QUERY } from "./Restaurant";
import { useQueryParams } from "./History";


function Deliverers ({
                       deleteDeliverersMutation,
                       addDeliverer,
                       updateDeliverer,
                       deleteDeliverer,
                       me
                     }) {
  const query = useQueryParams()
  const restaurantId = query.get('restaurant') ? parseInt(query.get('restaurant')) : undefined
  const restaurantQuery = useQuery(RESTAURANT_QUERY, {
    name: 'restaurant',
    skip: me?.admin !== true || !restaurantId,
    variables: {
      id: restaurantId
    }
  })

  const deliverers = useQuery(DELIVERERS_QUERY, {
    name: 'deliverers',
    options: {
      fetchPolicy: 'network-only',
    },
    variables: {
      id: restaurantId
    }
  })

  const [delivererName, setDelivererName] = useState('')
  const [delivererUsername, setDelivererUsername] = useState('')
  const [delivererPassword, setDelivererPassword] = useState('')

  const [editedDelivererId, setEditedDelivererId] = useState(null)

  const [checkedDeliverers, setCheckedDeliverers] = useState([])

  const [showDeliverer, setShowDeliverer] = useState(false)
  const [showEditDeliverer, setShowEditDeliverer] = useState(false)

  const [searchTerm, setSearchTerm] = useState('')

  const [error, setError] = useState(null)

  const handleCloseDeliverer = () => {
    setShowDeliverer(false)
    setDelivererName('')
    setDelivererUsername('')
    setDelivererPassword('')
  }

  const handleShowDeliverer = () => {
    setShowDeliverer(true)
  }

  const handleCloseEditDeliverer = () => {
    setShowEditDeliverer(false)
    setDelivererName('')
    setDelivererUsername('')
    setDelivererPassword('')
  }

  const handleShowEditDeliverer = () => setShowEditDeliverer(true)

  const checkDeliverer = (deliverer) => {
    if (!checkedDeliverers.includes(deliverer.id)) {
      setCheckedDeliverers((prev) => [...prev, deliverer.id])
    } else {
      setCheckedDeliverers(checkedDeliverers.filter((e) => e !== deliverer.id))
    }
  }

  const submitDeliverer = async e => {
    e.preventDefault()
    addDeliverer({
      variables: { restaurant: restaurantId, name: delivererName, email: delivererUsername, password: delivererPassword },
    })
      .then(async () => {
        handleCloseDeliverer()
        await deliverers.refetch()
      })
      .catch(e => {
        if (e.message?.includes('Unique constraint failed on the fields: (`email`,`password`)')) {
          setError('Veuillez changer l\'identifiant ou le mot de passe')
        }
      })
  }

  const editDeliverer = async e => {
    e.preventDefault()
    updateDeliverer({
      variables: {
        id: editedDelivererId,
        name: delivererName,
        email: delivererUsername,
        password: delivererPassword
      },
    })
      .then(async () => {
        handleCloseEditDeliverer()
        await deliverers.refetch()
      })
      .catch(e => {
        if (e.message?.includes('Unique constraint failed on the fields: (`email`,`password`)')) {
          setError('Veuillez changer l\'identifiant ou le mot de passe')
        }
      })
  }

  const handleChange = event => {
    setSearchTerm(event.target.value)
  };


  const listRestaurants = useQuery(LIST_RESTAURANTS_QUERY, {
    name: 'listRestaurants'
  })

  const restaurantListDiv = <div>
    <nav className="nav nav-tabs">
      {listRestaurants?.data?.listRestaurants?.map((restaurant) =>
        <li className="nav-item m-0">
          <Link className={`py-2 nav-item nav-link ${restaurant.id === Number(restaurantId) ? 'active' : ''}`} to={'?restaurant=' + restaurant.id}>{restaurant.name}</Link>
        </li>
      )}
    </nav>
  </div>

  if (me?.admin === true && !restaurantId) {
    return restaurantListDiv
  }

  if (deliverers.loading) {
    return (
      <Loader/>
    )
  }

  let renderTab, renderButton
  const searchResults = !searchTerm
    ? deliverers?.data?.deliverers
    : deliverers?.data?.deliverers?.filter(deliverer =>
      deliverer.name.toString().toLowerCase().includes(searchTerm)
    )
  renderButton = <button onClick={handleShowDeliverer} className="btn btn-primary lift">
    + Ajouter un livreur
  </button>
  renderTab =
    <div className="card">
      <div className="card-header">
        <div className="dropdown flex-grow-0 mr-4">
          {checkedDeliverers.length > 0
          && <span>{checkedDeliverers.length} <i className="fe fe-check-square"/></span>
          }
          <Dropdown>
            <Dropdown.Toggle variant="white" size="sm">
              Actions
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={async () => {
                if (window.confirm('Êtes-vous sûr de vouloir supprimer les livreurs sélectionnés ?')) {
                  await deleteDeliverersMutation({ variables: { id: checkedDeliverers } })
                  await deliverers.refetch()
                  setCheckedDeliverers([])
                }
              }}>Supprimer</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <form className="flex-grow-1">
          <div className="input-group input-group-flush">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fe fe-search"/>
              </span>
            </div>
            <input className="form-control search" type="search" placeholder="Recherche..."
                   value={searchTerm}
                   onChange={handleChange}/>
          </div>
        </form>
      </div>
      <div className="table-responsive">
        <table className="table table-sm table-nowrap card-table table-hover">
          <thead>
          <tr>
            <th>
              Nom
            </th>
            <th>
              Identifiant
            </th>
            <th colSpan={2}>
              Mot de passe
            </th>
          </tr>
          </thead>
          <tbody className="list">
          {searchResults?.map((deliverer) => (
            <DelivererItem
              length={searchResults.length}
              deliverer={deliverer}
              key={deliverer.id}
              checked={checkedDeliverers.find(e => e === deliverer.id) || false}
              onChange={checkDeliverer}
              editDeliverer={() => {
                setEditedDelivererId(deliverer.id)
                setDelivererName(deliverer.name)
                setDelivererUsername(deliverer.email)
                setDelivererPassword(deliverer.password)
                handleShowEditDeliverer()
              }}
              deleteDeliverer={async () => {
                if (window.confirm('Êtes-vous sûr de vouloir supprimer le livreur ?')) {
                  await deleteDeliverer({ variables: { id: deliverer.id } })
                  await deliverers.refetch()
                }
              }}
            />
          ))}
          </tbody>
        </table>
      </div>
    </div>

  return (
    <div className="container-fluid">
      {me?.admin === true && (
        restaurantListDiv
      )}
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="header mt-md-5">
            <div className="header-body">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="header-title">
                    Livreurs
                  </h1>
                </div>
                <div className="col-auto">
                  {renderButton}
                  <button className="btn btn-dark ml-3 h-100" onClick={() =>
                    window.location.reload(true)
                  }>
                    <i className="fe fe-refresh-cw"/>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {renderTab}
        </div>
      </div>

      <Modal show={showDeliverer} onHide={handleCloseDeliverer}>
        <div className="modal-card card">
          <div className="card-header">
            <h4 className="card-header-title" id="exampleModalCenterTitle">
              Ajouter un livreur
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseDeliverer}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="card-body">
            <form onSubmit={submitDeliverer}>
              <div className="form-group">
                <label htmlFor="addDelivererName">Nom</label>
                <input
                  autoFocus={true}
                  type="text"
                  className="form-control"
                  id="addDelivererName"
                  placeholder="Nom"
                  value={delivererName}
                  onChange={(e) => setDelivererName(e.target.value)}
                  autoComplete="off"/>
              </div>
              <div className="form-group">
                <label htmlFor="addDelivererUsername">Identifiant</label>
                <input
                  autoFocus={true}
                  type="text"
                  className="form-control"
                  id="addDelivererUsername"
                  placeholder="Identifiant"
                  value={delivererUsername}
                  onChange={(e) => setDelivererUsername(e.target.value)}
                  autoComplete="off"/>
              </div>
              <div className="form-group">
                <label htmlFor="addDelivererPassword">Mot de passe</label>
                <input
                  autoFocus={true}
                  type="text"
                  className="form-control"
                  id="addDelivererPassword"
                  placeholder="Mot de passe"
                  value={delivererPassword}
                  onChange={(e) => setDelivererPassword(e.target.value)}
                  autoComplete="off"/>
              </div>
              {error && <div className="text-danger">{error}</div>}
              <div className="d-flex justify-content-between mt-5">
                <button type="button" className="btn btn-secondary" onClick={handleCloseDeliverer}>Annuler</button>
                <button type="submit" className="btn btn-primary">Ajouter</button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal show={showEditDeliverer} onHide={handleCloseEditDeliverer}>
        <div className="modal-card card">
          <div className="card-header">
            <h4 className="card-header-title" id="exampleModalCenterTitle">
              Modifier le livreur
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseEditDeliverer}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="card-body">
            <form onSubmit={editDeliverer}>
              <div className="form-group">
                <label htmlFor="editDelivererName">Nom</label>
                <input
                  autoFocus={true}
                  type="text"
                  className="form-control"
                  id="editDelivererName"
                  placeholder="Nom"
                  value={delivererName}
                  onChange={(e) => setDelivererName(e.target.value)}
                  autoComplete="off"/>
              </div>
              <div className="form-group">
                <label htmlFor="editDelivererUsername">Identifiant</label>
                <input
                  autoFocus={true}
                  type="text"
                  className="form-control"
                  id="editDelivererUsername"
                  placeholder="Identifiant"
                  value={delivererUsername}
                  onChange={(e) => setDelivererUsername(e.target.value)}
                  autoComplete="off"/>
              </div>
              <div className="form-group">
                <label htmlFor="editDelivererPassword">Mot de passe</label>
                <input
                  autoFocus={true}
                  type="text"
                  className="form-control"
                  id="editDelivererPassword"
                  placeholder="Mot de passe"
                  value={delivererPassword}
                  onChange={(e) => setDelivererPassword(e.target.value)}
                  autoComplete="off"/>
              </div>
              {error && <div className="text-danger">{error}</div>}
              <div className="d-flex justify-content-between mt-5">
                <button type="button" className="btn btn-secondary" onClick={handleCloseEditDeliverer}>Annuler</button>
                <button type="submit" className="btn btn-primary">Sauvegarder</button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const DELIVERERS_QUERY = gql`
  query deliverers($id: ID) {
    deliverers(id: $id) {
      id
      name
      email
      password
      restaurants {
        id
      }
    }
  }
`

const ADD_DELIVERER = gql`
  mutation addDeliverer($restaurant: ID, $name: String!, $email: String!, $password: String!) {
    addDeliverer(restaurant: $restaurant, name: $name, email: $email, password: $password) {
      id
      name
      email
      password
    }
  }
`

const UPDATE_DELIVERER_MUTATION = gql`
  mutation updateDeliverer($id: ID!, $name: String!, $email: String!, $password: String!) {
    updateDeliverer(id: $id, name: $name, email: $email, password: $password) {
      id
      name
      email
      password
    }
  }
`

const DELETE_DELIVERERS_MUTATION = gql`
  mutation deleteDeliverers($id: [ID]!) {
    deleteDeliverers(id: $id){
      count
    }
  }
`

const DELETE_DELIVERER_MUTATION = gql`
  mutation deleteDeliverer($id: ID!) {
    deleteDeliverer(id: $id){
      id
    }
  }
`

export default compose(
  graphql(ADD_DELIVERER, {
    name: 'addDeliverer'
  }),
  graphql(UPDATE_DELIVERER_MUTATION, {
    name: 'updateDeliverer'
  }),
  graphql(DELETE_DELIVERER_MUTATION, {
    name: 'deleteDeliverer'
  }),
  graphql(DELETE_DELIVERERS_MUTATION, {
    name: 'deleteDeliverers'
  }),
)(Deliverers)
