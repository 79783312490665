import React, { Component, Fragment, useEffect, useState } from 'react'
import {
  NavLink,
  Link,
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import { graphql } from 'react-apollo'
import { gql } from 'apollo-boost'
import { AUTH_TOKEN } from './constant'
import { isTokenExpired } from './helper/jwtHelper'
import Dashboard from './pages/Dashboard'
import Orders from './pages/Orders'
import Menu from './pages/Menu'
import Profile from './pages/Profile'
import Public from './pages/Public'
import LoginPage from './pages/auth/LoginPage'
import SignupPage from './pages/auth/SignupPage'
import LogoutPage from './pages/auth/LogoutPage'
import PageNotFound from './pages/PageNotFound'
import Article from './pages/Article'
import Order from './pages/Order'
import Restaurant from './pages/Restaurant'
import History from './pages/History'
import { toast, ToastContainer } from 'react-toastify';
import compose from 'lodash.flowright'
import { Dropdown } from 'react-bootstrap';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import Deliverers from './pages/Deliverers';
import silence from './assets/silence.mp3';
import sound from './assets/notification.mp3'
import DashboardDeliverer from './pages/DashboardDeliverer';

let NOTIFICATION = []

const audioNotification = new Audio(sound)

const ProtectedRouteFn = ({
                            component: Component,
                            token,
                            restaurant,
                            deliverer,
                            location,
                            stripeOnboardingLink,
                            feedQuery,
                            subscribeToNewFeed,
                            name,
                            me,
                            ...rest
                          }) => {
  const [showAlert, setShowAlert] = useState(true)

  useEffect(() => {
    if (Array.isArray(restaurant)) {
      restaurant.map(restaurant => {
        if (restaurant?.id)
          subscribeToNewFeed(restaurant.id, deliverer)
      })
    } else {
      if (restaurant?.id)
        subscribeToNewFeed(restaurant.id, deliverer)
    }
  }, [subscribeToNewFeed, restaurant, deliverer])

  return token ? (
    <Route {...rest} render={matchProps =>
      <>
        {restaurant && !deliverer && !restaurant.isStripeValid && showAlert &&
        <div style={{ marginBottom: 40 }}>
          <div className="fixed-top">
            <div className="alert alert-danger alert-dismissible fade show text-center" role="alert">
              <a href={stripeOnboardingLink} rel="nofollow noopener noreferrer" className="text-white text-decoration-underline">
                <i className="fe fe-alert-triangle"/>&nbsp;
                Pour que votre compte fonctionne, vous devez remplir les informations
                de votre entreprise et de paiement ici
              </a>
              <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setShowAlert(false)}>
                <span aria-hidden="true">×</span>
              </button>
            </div>
          </div>
        </div>
        }
        <Component name={name} {...matchProps} me={me} restaurant={restaurant} deliverer={deliverer} feedQuery={feedQuery} audioNotification={audioNotification}/>
      </>}/>
  ) : (
    <Redirect to={{
      pathname: '/login',
      state: { from: location }
    }}/>
  )
}

const FEED_QUERY = gql`
  query FeedQuery {
    feed {
      id
      note
      status
      addedAt
      amount
      number
      name
      delivererName
      delivererNumber
      deliverer {
        id
      }
      restaurant {
        id
        name
      }
    }
  }
`
const FEED_SUBSCRIPTION = gql`
  subscription FeedSubscription {
    feedSubscription {
        id
        note
        status
        addedAt
        amount
        number
        name
        delivererName
        delivererNumber
        deliverer {
          id
        }
        restaurant {
          id
          name
        }
    }
  }
`

const ProtectedRoute = graphql(FEED_QUERY, {
  name: 'feedQuery', // name of the injected prop: this.props.feedQuery...
  options: {
    fetchPolicy: 'network-only',
  },
  props: props =>
    Object.assign({}, props, {
      subscribeToNewFeed: (restaurantId, deliverer) => {
        return props.feedQuery.subscribeToMore({
          document: FEED_SUBSCRIPTION,
          variables: {
            restaurantId
          },
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) {
              return prev
            }
            if (!prev.feed)
              return prev
            const newOrder = subscriptionData.data.feedSubscription

            if (newOrder.restaurant.id !== restaurantId)
              return prev

            if (localStorage.getItem('isDeliverer') === 'true') {
              if (newOrder.status === 'readytodeliver'
                && newOrder.id !== NOTIFICATION
                && newOrder.deliverer?.id === deliverer
              ) {
                audioNotification.pause()
                audioNotification.currentTime = 0
                toast.dismiss()
                toast(<Link to={`/order/${newOrder.id}`} className="text-reset toast-notification">Nouvelle
                  commande</Link>, {
                  position: 'top-right',
                  autoClose: false,
                  hideProgressBar: true,
                  closeOnClick: true,
                  draggable: true,
                  progress: undefined,
                  type: 'success',
                  containerId: 'newOrder'
                })
                audioNotification.play()
                NOTIFICATION = newOrder.id
                return Object.assign({}, prev, {
                  feed: [...prev.feed, newOrder],
                })
              }
              return prev
            }
            if (prev.feed.find(order => order.id === newOrder.id)) {
              return prev
            }

            if (newOrder.status === 'new') {
              audioNotification.pause()
              audioNotification.currentTime = 0
              toast.dismiss()
              toast(<Link to={`/order/${newOrder.id}`} className="text-reset toast-notification">Nouvelle
                commande</Link>, {
                position: 'top-right',
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
                type: 'success',
                containerId: 'newOrder',
                onClose: () => {
                  audioNotification.pause()
                  audioNotification.currentTime = 0
                }
              })
              audioNotification.loop = true
              audioNotification.play()
            }

            return Object.assign({}, prev, {
              feed: [...prev.feed, newOrder],
            })
          },
        })
      },
    }),
})(ProtectedRouteFn)

class App extends Component {
  constructor (props) {
    super(props)
    this.refreshTokenFn = this.refreshTokenFn.bind(this)

    this.state = {
      token: props.token,
      show: false,
      subscribed: false
    }
  }

  refreshTokenFn (data = {}) {
    const token = data[AUTH_TOKEN]

    if (token) {
      localStorage.setItem(AUTH_TOKEN, token)
    } else {
      localStorage.removeItem(AUTH_TOKEN)
      localStorage.removeItem('isDeliverer')
    }

    this.setState({
      token: data[AUTH_TOKEN],
    })
  }

  bootStrapData () {
    try {
      const token = localStorage.getItem(AUTH_TOKEN)
      if (token !== null && token !== undefined) {
        const expired = isTokenExpired(token)
        if (!expired) {
          this.setState({ token: token })
        } else {
          localStorage.removeItem(AUTH_TOKEN)
          localStorage.removeItem('isDeliverer')
          this.setState({ token: null })
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  componentDidMount () {
    this.bootStrapData()
    // this.interval = setInterval(() => window.location.reload(false), 1000 * 60 * 60)
  }

  componentWillUnmount () {
    // clearInterval(this.interval)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.data.meDeliverer) {
      if (!this.props.data.meDeliverer.hasOwnProperty('restaurants') || !Array.isArray(this.props.data.meDeliverer.restaurants)) {
        this.refreshTokenFn &&
        this.refreshTokenFn({
          [AUTH_TOKEN]: null,
        })
        window.location.href = '/'
      }
    }
    if (this.props.data.me?.restaurant?.slug && window.OneSignal && !this.state.subscribed && !(localStorage.getItem('isDeliverer') === 'true')) {
      const OneSignal = window.OneSignal || []
      OneSignal.push(() => {
        OneSignal.init({
          appId: '75e33d0f-1b83-4a35-a78b-075ed15bc6b8',
          safari_web_id: 'web.onesignal.auto.43666e9c-a8ad-4b1e-8de4-10291bcbdb86',
          notifyButton: {
            enable: false,
          },
          autoResubscribe: true,
        })
        OneSignal.showNativePrompt()
        OneSignal.setExternalUserId(this.props.data.me.restaurant.slug)
        OneSignal.sendTag('userAgent', `${window.navigator?.userAgent}`)
        this.setState({ subscribed: true })
      })
    }

    if (this.props.data.meDeliverer && window.OneSignal && !this.state.subscribed && (localStorage.getItem('isDeliverer') === 'true')) {
      const OneSignal = window.OneSignal || []
      OneSignal.push(() => {
        OneSignal.init({
          appId: '75e33d0f-1b83-4a35-a78b-075ed15bc6b8',
          safari_web_id: 'web.onesignal.auto.43666e9c-a8ad-4b1e-8de4-10291bcbdb86',
          notifyButton: {
            enable: false,
          },
          autoResubscribe: true,
        })
        OneSignal.showNativePrompt()
        OneSignal.setExternalUserId(`deliverer-${this.props.data.meDeliverer.id}-${this.props.data.meDeliverer.email}`)
        OneSignal.sendTag('userAgent', `${window.navigator?.userAgent}`)
        this.setState({ subscribed: true })
      })
    }
  }

  render () {
    return (
      <Router>
        <Fragment>
          {this.state.token && this.renderNavBar()}
          {this.renderRoute()}
          <ToastContainer/>
          <iframe src={silence}
                  title="silence"
                  allow="autoplay"
                  id="audio"
                  style={{ display: 'none' }}/>
        </Fragment>
      </Router>
    )
  }

  renderNavBar () {
    const { showMobile } = this.state
    if (localStorage.getItem('isDeliverer') === 'true')
      return (
        <nav
          className="navbar navbar-vertical fixed-left navbar-expand-md navbar-dark d-print-none"
          id="sidebarSmall"
        >
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#sidebarSmallCollapse"
              aria-controls="sidebarSmallCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => this.setState({ showMobile: !showMobile })}
            >
              <span className="navbar-toggler-icon"/>
            </button>
            <Link className="navbar-brand" to="/">
              <img
                src="/assets/img/logo_dashboard.png"
                className="navbar-brand-img
    mx-auto"
                alt="logo"
              />
            </Link>
            <div className="navbar-user d-md-none">
              <Dropdown>
                <Dropdown.Toggle variant="link" size="sm" style={{ outline: 0 }}>
                  <div className="avatar avatar-sm avatar-online">
                    <img
                      src="/assets/img/avatars/profiles/avatar-2.jpg"
                      className="avatar-img rounded-circle"
                      alt="avatar"
                    />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item as={NavLink} to={'/profile'}>Profil</Dropdown.Item>
                  <Dropdown.Item onClick={() => {
                    this.refreshTokenFn &&
                    this.refreshTokenFn({
                      [AUTH_TOKEN]: null,
                    })
                    window.location.href = '/'
                  }}>
                    Se déconnecter
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className={`collapse navbar-collapse ${showMobile ? 'show' : ''}`} id="sidebarSmallCollapse">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to={'/'}
                    exact
                    onClick={() => this.setState({ showMobile: false })}
                  >
                    <i className="fe fe-activity"/>
                    <span>Tableau de bord</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to={'/orders'}
                    onClick={() => this.setState({ showMobile: false })}
                  >
                    <i className="fe fe-list"/>
                    <span>Commandes</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link "
                    to={'/history'}
                    onClick={() => this.setState({ showMobile: false })}
                  >
                    <i className="fe fe-file-text"/>
                    <span>Historique</span>
                  </NavLink>
                </li>
              </ul>
              <hr className="navbar-divider my-3"/>
              <h6 className="navbar-heading">
                Paramètres
              </h6>
              <ul className="navbar-nav">
                {this.props.data.stripeOnboardingLink &&
                <li className="nav-item">
                  <a rel="noopener noreferrer"
                     className="nav-link"
                     href={this.props.data.stripeOnboardingLink}
                     target="_blank"
                  >
                    <i className="fe fe-credit-card"/>
                    <span>Paiement</span>
                  </a>
                </li>
                }
                <li className="nav-item">
                  <NavLink
                    to="/profile"
                    className="nav-link"
                    onClick={() => this.setState({ showMobile: false })}
                  >
                    <i className="fe fe-user"/>
                    <span>Profil</span>
                  </NavLink>
                </li>
              </ul>
              <div className="mt-auto"/>
              <div
                className="navbar-user d-none d-md-flex"
                id="sidebarSmallUser"
              >
                {this.props.data.stripeOnboardingLink &&
                <a rel="noopener noreferrer"
                   className="navbar-user-link"
                   href={this.props.data.stripeOnboardingLink}
                   target="_blank"
                >
                  <span className="icon"><i className="fe fe-credit-card"/></span>
                </a>
                }
                <div className="dropup">
                  <NavLink
                    to={'/profile'}
                    id="sidebarSmallIconCopy"
                    className="dropdown-toggle"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={() => this.setState({ showMobile: false })}
                  >
                    <div className="avatar avatar-sm avatar-online">
                      <img
                        src="/assets/img/avatars/profiles/avatar-2.jpg"
                        className="avatar-img rounded-circle"
                        alt="avatar"
                      />
                    </div>
                  </NavLink>
                  <div className="dropdown-menu" aria-labelledby="sidebarSmallIconCopy">
                    <NavLink
                      className="dropdown-item"
                      to={'/profile'}
                      onClick={() => this.setState({ showMobile: false })}
                    >Profil</NavLink>
                    <NavLink to="/restaurant" className="dropdown-item">
                      Restaurant
                    </NavLink>
                    <hr className="dropdown-divider"/>
                    <button className="dropdown-item" onClick={() => {
                      this.refreshTokenFn &&
                      this.refreshTokenFn({
                        [AUTH_TOKEN]: null,
                      })
                      window.location.href = '/'
                    }}>
                      Se déconnecter
                    </button>
                  </div>
                </div>
                <NavLink to="/restaurant"
                         className="navbar-user-link"
                         onClick={() => this.setState({ showMobile: false })}
                >
                  <span className="icon"><i className="fe fe-settings"/></span>
                </NavLink>
              </div>
            </div>
          </div>
        </nav>
      )

    return (
      <nav
        className="navbar navbar-vertical fixed-left navbar-expand-md navbar-dark d-print-none"
        id="sidebarSmall"
      >
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#sidebarSmallCollapse"
            aria-controls="sidebarSmallCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => this.setState({ showMobile: !showMobile })}
          >
            <span className="navbar-toggler-icon"/>
          </button>
          <Link className="navbar-brand" to="/">
            <img
              src="/assets/img/logo_dashboard.png"
              className="navbar-brand-img
    mx-auto"
              alt="logo"
            />
          </Link>
          <div className="navbar-user d-md-none">
            <Dropdown>
              <Dropdown.Toggle variant="link" size="sm" style={{ outline: 0 }}>
                <div className="avatar avatar-sm avatar-online">
                  <img
                    src="/assets/img/avatars/profiles/avatar-1.jpg"
                    className="avatar-img rounded-circle"
                    alt="avatar"
                  />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as={NavLink} to={'/restaurant'}>Restaurant</Dropdown.Item>
                <Dropdown.Item as={NavLink} to={'/profile'}>Profil</Dropdown.Item>
                <Dropdown.Item onClick={() => {
                  this.refreshTokenFn &&
                  this.refreshTokenFn({
                    [AUTH_TOKEN]: null,
                  })
                  window.location.href = '/'
                }}>
                  Se déconnecter
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className={`collapse navbar-collapse ${showMobile ? 'show' : ''}`} id="sidebarSmallCollapse">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink
                  exact
                  className="nav-link "
                  to={'/'}
                  onClick={() => this.setState({ showMobile: false })}
                >
                  <i className="fe fe-activity"/>
                  <span>Tableau de bord</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to={'/orders'}
                  onClick={() => this.setState({ showMobile: false })}
                >
                  <i className="fe fe-list"/>
                  <span>Commandes</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link "
                  to={'/history'}
                  onClick={() => this.setState({ showMobile: false })}
                >
                  <i className="fe fe-file-text"/>
                  <span>Historique</span>
                </NavLink>
              </li>
            </ul>
            <hr className="navbar-divider my-3"/>
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink
                  className="nav-link "
                  to={'/menu'}
                  onClick={() => this.setState({ showMobile: false })}
                >
                  <i className="fe custom-svg">
                    <svg viewBox="0 0 164 124.17">
                      <path
                        fill="#FFF"
                        className="prefix__cls-1"
                        d="M9.43 95c0-41.08 32.49-74.38 72.57-74.38s72.57 33.3 72.57 74.38"
                      />
                      <rect
                        fill="#FFF"
                        className="prefix__cls-1"
                        x={67.65}
                        width={28.71}
                        height={28.71}
                        rx={13.69}
                        ry={13.69}
                      />
                      <rect
                        fill="#FFF"
                        className="prefix__cls-1"
                        y={107.68}
                        width={164}
                        height={16.5}
                        rx={7.87}
                        ry={7.87}
                      />
                    </svg>
                  </i>
                  <span>Carte du restaurant</span>
                </NavLink>
              </li>
            </ul>
            <hr className="navbar-divider my-3"/>
            <ul className="navbar-nav">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href={'https://yeaty.app/' + this.props.data?.me?.restaurant?.slug}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => this.setState({ showMobile: false })}
                >
                  <i className="fe fe-globe"/>
                  <span>Site web</span>
                </a>
              </li>
            </ul>
            <hr className="navbar-divider my-3"/>
            <h6 className="navbar-heading">
              Paramètres
            </h6>
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink
                  to="/restaurant"
                  className="nav-link"
                  onClick={() => this.setState({ showMobile: false })}
                >
                  <i className="fe fe-settings"/>
                  <span>Restaurant</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/deliverers"
                  className="nav-link"
                  onClick={() => this.setState({ showMobile: false })}
                >
                  <i className="fe fe-users"/>
                  <span>Livreurs</span>
                </NavLink>
              </li>
              {this.props.data.stripeOnboardingLink &&
              <li className="nav-item">
                <a rel="noopener noreferrer"
                   className="nav-link"
                   href={this.props.data.stripeOnboardingLink}
                   target="_blank"
                >
                  <i className="fe fe-credit-card"/>
                  <span>Paiement</span>
                </a>
              </li>
              }
              <li className="nav-item">
                <NavLink
                  to="/profile"
                  className="nav-link"
                  onClick={() => this.setState({ showMobile: false })}
                >
                  <i className="fe fe-user"/>
                  <span>Profil</span>
                </NavLink>
              </li>
            </ul>
            <div className="mt-auto"/>
            <div
              className="navbar-user d-none d-md-flex"
              id="sidebarSmallUser"
            >
              {this.props.data.stripeOnboardingLink &&
              <a rel="noopener noreferrer"
                 className="navbar-user-link"
                 href={this.props.data.stripeOnboardingLink}
                 target="_blank"
              >
                <span className="icon"><i className="fe fe-credit-card"/></span>
              </a>
              }
              <div className="dropup">
                <NavLink
                  to={'/profile'}
                  id="sidebarSmallIconCopy"
                  className="dropdown-toggle"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => this.setState({ showMobile: false })}
                >
                  <div className="avatar avatar-sm avatar-online">
                    <img
                      src="/assets/img/avatars/profiles/avatar-1.jpg"
                      className="avatar-img rounded-circle"
                      alt="avatar"
                    />
                  </div>
                </NavLink>
                <div className="dropdown-menu" aria-labelledby="sidebarSmallIconCopy">
                  <NavLink
                    className="dropdown-item"
                    to={'/profile'}
                    onClick={() => this.setState({ showMobile: false })}
                  >Profil</NavLink>
                  <NavLink to="/restaurant" className="dropdown-item">
                    Restaurant
                  </NavLink>
                  <hr className="dropdown-divider"/>
                  <button className="dropdown-item" onClick={() => {
                    this.refreshTokenFn &&
                    this.refreshTokenFn({
                      [AUTH_TOKEN]: null,
                    })
                    window.location.href = '/'
                  }}>
                    Se déconnecter
                  </button>
                </div>
              </div>
              <NavLink to="/restaurant"
                       className="navbar-user-link"
                       onClick={() => this.setState({ showMobile: false })}
              >
                <span className="icon"><i className="fe fe-settings"/></span>
              </NavLink>
            </div>
          </div>
        </div>
      </nav>
    )
  }

  renderRoute () {
    let protectedRoutes = <Switch>
      <ProtectedRoute
        token={this.state.token}
        stripeOnboardingLink={this.props.data.stripeOnboardingLink}
        restaurant={this.props.data.me && this.props.data.me.restaurant}
        exact
        path="/"
        me={this.props.data.me}
        component={Dashboard}/>
      <ProtectedRoute
        stripeOnboardingLink={this.props.data.stripeOnboardingLink}
        restaurant={this.props.data.me && this.props.data.me.restaurant}
        token={this.state.token}
        path="/orders"
        me={this.props.data.me}
        component={Orders}/>
      <ProtectedRoute
        exact
        stripeOnboardingLink={this.props.data.stripeOnboardingLink}
        restaurant={this.props.data.me && this.props.data.me.restaurant}
        token={this.state.token}
        me={this.props.data.me}
        path="/menu"
        component={Menu}/>
      <ProtectedRoute
        stripeOnboardingLink={this.props.data.stripeOnboardingLink}
        restaurant={this.props.data.me && this.props.data.me.restaurant}
        me={this.props.data.me}
        token={this.state.token}
        path="/history"
        component={History}
      />
      <ProtectedRoute
        stripeOnboardingLink={this.props.data.stripeOnboardingLink}
        restaurant={this.props.data.me && this.props.data.me.restaurant}
        token={this.state.token}
        path="/profile"
        me={this.props.data.me}
        component={Profile}
      />
      <ProtectedRoute
        stripeOnboardingLink={this.props.data.stripeOnboardingLink}
        restaurant={this.props.data.me && this.props.data.me.restaurant}
        token={this.state.token}
        path="/restaurant"
        component={Restaurant}
        me={this.props.data.me}
      />
      <ProtectedRoute
        stripeOnboardingLink={this.props.data.stripeOnboardingLink}
        restaurant={this.props.data.me && this.props.data.me.restaurant}
        token={this.state.token}
        me={this.props.data.me}
        path="/deliverers"
        component={Deliverers}
      />
      <ProtectedRoute
        stripeOnboardingLink={this.props.data.stripeOnboardingLink}
        restaurant={this.props.data.me && this.props.data.me.restaurant}
        token={this.state.token}
        path="/page"
        me={this.props.data.me}
        component={Public}
      />
      <ProtectedRoute
        stripeOnboardingLink={this.props.data.stripeOnboardingLink}
        restaurant={this.props.data.me && this.props.data.me.restaurant}
        token={this.state.token}
        me={this.props.data.me}
        path="/menu/article/:id"
        component={Article}/>
      <ProtectedRoute
        stripeOnboardingLink={this.props.data.stripeOnboardingLink}
        restaurant={this.props.data.me && this.props.data.me.restaurant}
        token={this.state.token}
        me={this.props.data.me}
        path="/order/:id"
        component={Order}/>
      <Route
        token={this.state.token}
        path="/login"
        render={() => this.state.token ? (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        ) : (
          <LoginPage refreshTokenFn={this.refreshTokenFn}/>
        )}
      />
      <Route
        token={this.state.token}
        path="/forgot-password"
        render={() => this.state.token ? (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        ) : (
          <ForgotPassword refreshTokenFn={this.refreshTokenFn}/>
        )}
      />
      <Route
        token={this.state.token}
        path="/reset-password"
        render={() => this.state.token ? (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        ) : (
          <ResetPassword refreshTokenFn={this.refreshTokenFn}/>
        )}
      />
      <Route
        token={this.state.token}
        path="/signup"
        render={() => (
          this.state.token ? (
            <Redirect
              to={{
                pathname: '/',
              }}
            />
          ) : (
            <SignupPage refreshTokenFn={this.refreshTokenFn}/>
          )
        )}
      />
      <Route path="/logout" component={LogoutPage}/>
      <Route component={PageNotFound}/>
    </Switch>
    if (localStorage.getItem('isDeliverer') === 'true')
      protectedRoutes = <Switch>
        <ProtectedRoute
          stripeOnboardingLink={this.props.data.stripeOnboardingLink}
          restaurant={this.props.data.meDeliverer && this.props.data.meDeliverer.restaurants}
          deliverer={this.props.data.meDeliverer && this.props.data.meDeliverer.id}
          name={this.props.data.meDeliverer && this.props.data.meDeliverer.name}
          token={this.state.token}
          exact
          path="/"
          component={DashboardDeliverer}/>
        <ProtectedRoute
          stripeOnboardingLink={this.props.data.stripeOnboardingLink}
          restaurant={this.props.data.meDeliverer && this.props.data.meDeliverer.restaurants}
          deliverer={this.props.data.meDeliverer && this.props.data.meDeliverer.id}
          token={this.state.token}
          path="/orders"
          component={Orders}/>
        <ProtectedRoute
          stripeOnboardingLink={this.props.data.stripeOnboardingLink}
          restaurant={this.props.data.meDeliverer && this.props.data.meDeliverer.restaurants}
          deliverer={this.props.data.meDeliverer && this.props.data.meDeliverer.id}
          token={this.state.token}
          path="/order/:id"
          component={Order}/>
        <ProtectedRoute
          stripeOnboardingLink={this.props.data.stripeOnboardingLink}
          restaurant={this.props.data.meDeliverer && this.props.data.meDeliverer.restaurants}
          deliverer={this.props.data.meDeliverer && this.props.data.meDeliverer.id}
          token={this.state.token}
          path="/history"
          component={History}
        />
        <Route
          token={this.state.token}
          path="/login"
          render={() => this.state.token ? (
            <Redirect
              to={{
                pathname: '/',
              }}
            />
          ) : (
            <LoginPage refreshTokenFn={this.refreshTokenFn}/>
          )}
        />
        <Route
          token={this.state.token}
          path="/forgot-password"
          render={() => this.state.token ? (
            <Redirect
              to={{
                pathname: '/',
              }}
            />
          ) : (
            <ForgotPassword refreshTokenFn={this.refreshTokenFn}/>
          )}
        />
        <Route
          token={this.state.token}
          path="/reset-password"
          render={() => this.state.token ? (
            <Redirect
              to={{
                pathname: '/',
              }}
            />
          ) : (
            <ResetPassword refreshTokenFn={this.refreshTokenFn}/>
          )}
        />
        <Route
          token={this.state.token}
          path="/signup"
          render={() => (
            this.state.token ? (
              <Redirect
                to={{
                  pathname: '/',
                }}
              />
            ) : (
              <SignupPage refreshTokenFn={this.refreshTokenFn}/>
            )
          )}
        />
        <Route path="/logout" component={LogoutPage}/>
        <Route component={PageNotFound}/>
      </Switch>
    return (
      <div className={this.state.token ? 'main-content' : 'd-flex align-items-center bg-auth vh-min-100'}>
        {protectedRoutes}
      </div>
    )
  }
}

const ME_QUERY = gql`
  query MeQuery {
    stripeOnboardingLink
    isDeliverer
    me {
      id
      email
      name
      admin
      restaurant {
        id
        name
        slug
        isStripeValid
        additionalTicketInfo
      }
    }
  }
`

const ME_DELIVERER_QUERY = gql`
  query MeQuery {
    stripeOnboardingLink
    isDeliverer
    meDeliverer {
      id
      email
      name
      restaurants {
        id
        name
        slug
        isStripeValid
        additionalTicketInfo
      }
    }
  }
`

export default compose(
  graphql(localStorage.getItem('isDeliverer') === 'true' ? ME_DELIVERER_QUERY : ME_QUERY, {
    options: {
      errorPolicy: 'all',
    },
    // skip: !localStorage.getItem(AUTH_TOKEN)
  })
)(App)
