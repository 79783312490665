import React, { useState } from 'react'
import { graphql } from 'react-apollo'
import compose from 'lodash.flowright'
import { withRouter } from 'react-router-dom'
import { gql } from 'apollo-boost'
import { format } from 'date-fns'
import Loader from '../components/Loader'
import { Modal } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import ModalSelect from "../components/menu/ModalSelect";

const Order = ({
                 orderQuery,
                 updateOrderMutation,
                 history,
                 deliverersQuery,
                 setDelivererMutation,
                 restaurant,
                 ...props
               }) => {
  const isDeliverer = localStorage.getItem('isDeliverer') === 'true'
  const [loading, setLoading] = useState(false)
  const [showUpdateStatus, setShowUpdateStatus] = useState(false)
  const [showSetDeliverer, setShowSetDeliverer] = useState(false)
  const [newStatus, setNewStatus] = useState(null)
  const [deliverer, setDeliverer] = useState(null)
  const [message, setMessage] = useState(null)
  const intl = useIntl()

  const handleShowUpdateStatus = () => {
    setNewStatus({ value: orderQuery.order.status, label: intl.formatMessage({ id: orderQuery.order.status }) })
    setShowUpdateStatus(true)
  }
  const handleCloseUpdateStatus = () => {
    setShowUpdateStatus(false)
    setNewStatus(null)
  }

  const handleShowSetDeliverer = () => {
    setShowSetDeliverer(true)
  }

  const handleCloseSetDeliverer = () => {
    setShowSetDeliverer(false)
    setDeliverer(null)
  }

  const updateOrder = async data => {
    setLoading(true)
    await updateOrderMutation({
      variables: data,
    })
    orderQuery.refetch()
    setLoading(false)
    history.replace('/orders')
  }

  const editOrder = async e => {
    e.preventDefault()
    setLoading(true)
    updateOrderMutation({
      variables: {
        id: orderQuery.order.id,
        status: newStatus?.value || undefined,
        message
      },
    })
      .then(async () => {
        handleCloseUpdateStatus()
        await orderQuery.refetch()
        setLoading(false)
      })
      .catch(e => {
        console.log(e)
        setLoading(false)
      })
  }

  const setDelivererOrder = async e => {
    e.preventDefault()
    setLoading(true)
    setDelivererMutation({
      variables: {
        id: orderQuery.order.id,
        deliverer: deliverer?.value || undefined,
      },
    })
      .then(async () => {
        handleCloseSetDeliverer()
        await orderQuery.refetch()
        setLoading(false)
      })
      .catch(e => {
        console.log(e)
        setLoading(false)
      })
  }

  const allStatus = { 'new': 0, 'preparing': 1, 'ready': 2, 'completed': 3, 'canceled': -2 }
  const allStatusDelivery = {
    'new': 0,
    'preparing': 1,
    'readytodeliver': 2,
    'delivering': 3,
    // 'arrived': 4,
    'delivered': 4,
    'canceled': -2
  }

  if (orderQuery.loading) {
    return (
      <Loader/>
    )
  }

  if (orderQuery.error) {
    return (
      <div>Une erreur est survenue</div>
    )
  }

  const { order } = orderQuery

  let nextStatus = Object.keys(allStatus).find(key => allStatus[key] === allStatus[order.status] + 1)

  if (order.deliveryType === 'delivery')
    nextStatus = Object.keys(allStatusDelivery).find(key => allStatusDelivery[key] === allStatusDelivery[order.status] + 1)

  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="header d-print-none">
            <div className="header-body">
              <div className="row align-items-center">
                <div className="col-2 col-md-1">
                  <button type="button" className="btn btn-white" onClick={() => history.push('/orders')}>
                    <i className="fe fe-arrow-left"/>
                  </button>
                </div>
                <div className="col mb-3">
                  <h6 className="header-pretitle">Commande</h6>
                  <h1 className="header-title">
                    <span className="align-middle">#{order.number}&#32;</span>
                    <div className={`badge ${order.status}`} style={{
                      position: 'relative',
                      bottom: '2px',
                      left: '5px'
                    }}>{intl.formatMessage({ id: order.status })}
                    </div>
                  </h1>
                </div>
                <div className="col-12 col-md-auto mt-3 btn-group spacing flex-wrap">
                  {!isDeliverer &&
                  <button onClick={() => setTimeout(() => {
                    window.print();
                  }, 100)} className="btn btn-white lift mr-md-4 mr-3 mb-3">
                    Imprimer
                  </button>}
                  {nextStatus && (isDeliverer ? order.status !== 'preparing' : true) &&
                  <button onClick={() => updateOrder({
                    id: order.id,
                    status: nextStatus,
                  })} className="btn btn-primary mr-3 lift mb-3" disabled={loading}>
                    {loading ? <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div> : <span>Passer à <FormattedMessage
                      id={nextStatus}
                      defaultMessage={nextStatus}
                    /></span>}
                  </button>}
                  {!isDeliverer &&
                  <>
                    {nextStatus &&
                    <button className="btn btn-secondary mr-3 mb-3" onClick={handleShowUpdateStatus}>Changer le
                      statut
                    </button>
                    }
                    {order.deliveryType === 'delivery' && nextStatus &&
                    <button className="btn btn-info mr-3 mb-3" onClick={handleShowSetDeliverer}>Associer un livreur
                    </button>
                    }
                  </>}
                  <button className="btn btn-dark mr-3 h-100" onClick={() =>
                    window.location.reload(true)
                  }>
                    <i className="fe fe-refresh-cw"/>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-body p-5 col-12 col-lg-8 mx-auto card-order">
            <div className="row mb-4 mt-4">
              <div className="col text-center mb-5">
                <h1>{order.restaurant?.name}</h1>
                <h2 className="order-number">Commande #{order.number}</h2>
                {order.scheduled && <h3 className="h1">
                  Prévu pour&nbsp;
                  {order.scheduled}
                </h3>}
              </div>
            </div>
            <div className="row mb-4 detail-order">
              <div className="col-12 col-md-6 mb-4 mb-md-0">
                <h4 className="text-uppercase text-muted d-print-none">Détails commande</h4>
                <div style={{
                  fontSize: '2em',
                  color: order.deliveryType === 'delivery' ? '#4CAF50' : 'blue',
                  fontWeight: 'bold'
                }}>{intl.formatMessage({ id: order.deliveryType })}</div>
                <div className="d-print-none">#{order.number}</div>
                <div>
                  <time>{order.addedAt && format(new Date(order.addedAt), 'dd/MM/yyyy HH:mm')}</time>
                </div>
                {!isDeliverer &&
                <div className="d-print-none">{order.amount}€</div>}
                {order.delivererName && <div className="font-weight-bold">Livreur : {order.delivererName}</div>}
              </div>
              <div
                className={`col-12 col-md-6 text-md-right client-info ${!restaurant?.additionalTicketInfo ? 'd-print-none' : ''}`}>
                <h4 className="text-uppercase text-muted">Détails client</h4>
                <div className="text-muted">
                  <i className="fe fe-user">&nbsp;</i>
                  <strong className="text-body">{order.name}</strong>
                </div>
                <div className="email d-print-none">
                  <i className="fe fe-mail icon-left"/>
                  <span>{order.email}</span>
                </div>
                <div>
                  <a href={`tel:${order.phone}`} rel="noreferrer nofollow noopener">
                    <i className="fe fe-phone icon-left"/>
                    <span>{order.phone}</span>
                  </a>
                </div>
                {order.foundAddress && <div>
                  <a
                    href={`https://www.google.fr/maps/dir/${order.restaurant.id === 23 ? '' : order.restaurant.address}/${order.foundAddress}/am=t`}
                    target="_blank"
                    rel="noreferrer nofollow noopener">
                    <i className="fe fe-map-pin icon-left"/>
                    <span>{order.foundAddress}</span>
                  </a>
                </div>
                }
                {order.other &&
                <div className="d-print-none" style={{ whiteSpace: 'pre-wrap' }}>
                  <span>{order.other}</span>
                </div>
                }
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="table-responsive">
                  <table className="table mb-0">
                    <thead className="d-print-none">
                    <tr>
                      <th className="px-0 bg-transparent border-top-0">
                        <span className="h4">Qté</span>
                      </th>
                      <th className="px-0 bg-transparent border-top-0">
                        <span className="h4">Catégorie</span>
                      </th>
                      <th className="px-0 bg-transparent border-top-0">
                        <span className="h4">Article</span>
                      </th>
                      {!isDeliverer &&
                      <th className="px-0 bg-transparent border-top-0 text-right">
                        <span className="h4">Prix</span>
                      </th>
                      }
                    </tr>
                    </thead>
                    <tbody>
                    {order.articles.map(articleOrdered =>
                      <tr key={articleOrdered.id}>
                        <td className="px-0 h1 d-print-none" style={{
                          width: 50,
                          fontSize: '3em'
                        }}>{articleOrdered.quantity}</td>
                        <td className="px-0 h3 d-print-none">{articleOrdered.category}</td>
                        <td className="px-0">
                          <span className="h3">
                            <span className="d-none d-print-block h3 pr-3">{articleOrdered.category}</span>
                            <span className="d-none d-print-inline-block h2">{articleOrdered.quantity}</span>
                            &nbsp;{articleOrdered.name}
                          </span>
                          {articleOrdered.options?.slice().sort((a, b) => a.number - b.number).map(option =>
                            <span key={option.id} className="ml-2 d-block">{option.item}
                              {!isDeliverer && <span className="d-print-none">&nbsp;+{option.price}€</span>}
                            </span>
                          )}
                        </td>
                        {!isDeliverer &&
                        <td className="px-0 text-right h3 d-print-none">{articleOrdered.totalPrice}€</td>
                        }
                      </tr>
                    )}
                    {order.deliveryType === 'delivery' && !isDeliverer &&
                    <tr className="d-print-none">
                      <td className="px-0 border-top border-top-2">
                        <strong className="h3">Frais de livraison</strong>
                      </td>
                      <td
                        colSpan={3}
                        className="px-0 text-right border-top border-top-2"
                      >
                        <span className="h3">{order.deliveryFees}€</span>
                      </td>
                    </tr>
                    }
                    {!isDeliverer && order.discount &&
                    <tr className="d-print-none">
                      <td colSpan={4} className="px-0 border-top border-top-2">
                        <strong className="h3">Code Promo {order.discount.code}</strong>
                      </td>
                    </tr>}
                    {!isDeliverer &&
                    <tr className="d-print-none">
                      <td className="px-0 border-top border-top-2">
                        <strong className="h1">Total</strong>
                      </td>
                      <td
                        colSpan={3}
                        className="px-0 text-right border-top border-top-2"
                      >
                        <span className="h1">{order.amount}€</span>
                      </td>
                    </tr>
                    }
                    </tbody>
                  </table>
                </div>
                <hr/>
                {order.note &&
                <div>
                  <h4 className="text-uppercase">Notes</h4>
                  <p className="mb-0">
                    {order.note}
                  </p>
                </div>
                }
                {order.deliveryDetails &&
                <div className="d-print-none mt-4">
                  <h4 className="text-uppercase">Détails de livraison</h4>
                  <p className="mb-0">
                    {order.deliveryDetails}
                  </p>
                </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showUpdateStatus} onHide={handleCloseUpdateStatus}>
        <div className="modal-card card">
          <div className="card-header">
            <h4 className="card-header-title" id="exampleModalCenterTitle">
              Modifier le statut
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                    onClick={handleCloseUpdateStatus}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="card-body">
            <form onSubmit={editOrder}>
              <div className="form-group">
                <label htmlFor="inputName">Statut</label>
                <ModalSelect
                  required
                  value={newStatus}
                  onChange={(option) => setNewStatus(option)}
                  options={Object.keys(order.deliveryType === 'delivery' ? allStatusDelivery : allStatus).map(status => ({
                    value: status,
                    label: intl.formatMessage({ id: status })
                  }))}
                  isValid={true}
                />
              </div>
              {newStatus?.value === 'canceled' && <div>
                <div>Le client sera remboursé</div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    placeholder="Message pour le client"
                    onChange={e => setMessage(e.target.value)}
                    value={message}
                  />
                </div>
              </div>}
              <div className="d-flex justify-content-between mt-5">
                <button type="button" className="btn btn-secondary" onClick={handleCloseUpdateStatus}>Annuler</button>
                <button type="submit" className="btn btn-primary" disabled={loading}>
                  {loading ? <span className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </span> : <span>Sauvegarder</span>}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <Modal show={showSetDeliverer} onHide={handleCloseSetDeliverer}>
        <div className="modal-card card">
          <div className="card-header">
            <h4 className="card-header-title" id="exampleModalCenterTitle">
              Associer un livreur
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                    onClick={handleCloseUpdateStatus}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="card-body">
            <form onSubmit={setDelivererOrder}>
              <div className="form-group">
                <label htmlFor="inputName">Livreur</label>
                <ModalSelect
                  required
                  value={deliverer}
                  onChange={(option) => setDeliverer(option)}
                  options={deliverersQuery?.deliverers?.map(deliverer => ({
                    value: deliverer.id,
                    label: deliverer.name
                  }))}
                  isValid={true}
                />
              </div>
              <div className="d-flex justify-content-between mt-5">
                <button type="button" className="btn btn-secondary" onClick={handleCloseSetDeliverer}>Annuler</button>
                <button type="submit" className="btn btn-primary" disabled={loading}>
                  {loading ? <span className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </span> : <span>Sauvegarder</span>}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const ORDER_QUERY = gql`
  query OrderQuery($id: ID!) {
    order(id: $id) {
      id
      number
      note
      status
      other
      articles {
        id
        quantity
        name
        price
        totalPrice
        options {
          id
          item
          price
          number
        }
        category
      }
      restaurant {
        id
        name
        address
      }
      amount
      addedAt
      scheduled
      name
      email
      phone
      foundAddress
      deliveryFees
      deliveryType
      deliveryDetails
      delivererName
      deliverer {
        id
      }
      discount {
        id
        discount
        code
      }
      discountAmount
    }
  }
`

const UPDATE_ORDER = gql`
  mutation updateOrder($id: ID!, $status: String, $message: String) {
    updateOrder(id: $id, status: $status, message: $message) {
      id
      status
    }
  }
`

const SET_DELIVERER = gql`
  mutation setDeliverer($id: ID!, $deliverer: ID) {
    setDeliverer(id: $id, deliverer: $deliverer) {
      id
      delivererName
    }
  }
`

const DELIVERERS_QUERY = gql`
  query deliverers {
    deliverers {
      id
      name
    }
  }
`

export default compose(
  graphql(ORDER_QUERY, {
      name: 'orderQuery',
      options: props => ({
        variables: {
          id: props.match.params.id,
        },
      }),
    }
  ),
  graphql(UPDATE_ORDER, {
    name: 'updateOrderMutation',
  }),
  graphql(SET_DELIVERER, {
    name: 'setDelivererMutation',
  }),
  graphql(DELIVERERS_QUERY, {
    name: 'deliverersQuery'
  }),
  withRouter,
)
(Order)
