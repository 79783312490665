import React, { PureComponent } from 'react'
import Chart from "./index";

export default class LineGraph extends PureComponent {
  chartRef = React.createRef();

  componentDidMount () {
    const chartRef = this.chartRef.current.getContext("2d");
    const { labels, data } = this.props

    new Chart(chartRef, {
      type: 'line',
      options: {
        events: ['click', 'mouseout'],
        scales: {
          yAxes: [{
            ticks: {
              callback: function (value) {
                return value + '€';
              }
            }
          }]
        },
      },
      data: {
        labels,
        datasets: [{
          borderColor: '#1a1a1a',
          label: '€',
          data
        }]
      }
    });
  }

  render () {
    return (
      <div className="chart">
        <canvas
          ref={this.chartRef}
          className="chart-canvas"
        />
      </div>
    )
  }
}
