import React, { useState } from 'react'
import { graphql } from 'react-apollo'
import { gql } from 'apollo-boost'
import { Modal, Dropdown, Form } from 'react-bootstrap'
import compose from 'lodash.flowright'
import {
  useLocation, Link
} from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import CategoryItem from '../components/menu/CategoryItem'
import ArticleItem from '../components/menu/ArticleItem'
import { emojis } from '../helper/emojiList'
import CurrencyInput from '../components/menu/CurrencyInput'
import Loader from '../components/Loader'
import OptionItem from '../components/menu/OptionItem'
import { getOptionLabel } from '../helper/helper'
import ModalSelect from '../components/menu/ModalSelect'
import Select from 'react-select'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

function Menu({
                categoriesQuery, articlesQuery,
                deleteArticlesMutation, deleteCategoriesMutation,
                createCategoryMutation, createArticleMutation, history,
                deleteCategoryMutation, deleteArticleMutation,
                updateCategoryMutation, updateArticleMutation,
                createArticleOptionMutation, optionsQuery,
                deleteOptionMutation, updateOptionMutation,
                deleteOptionsMutation, restaurant,
                optionsItemsQuery
              }) {
  let query = useQuery()

  const [validated, setValidated] = useState(false)
  const [redirect, setRedirect] = useState(true)

  const [categoryName, setCategoryName] = useState('')
  const [categoryEmoji, setCategoryEmoji] = useState({ value: '', label: '' })
  const [categoryMinimum, setCategoryMinimum] = useState(null)
  const [categoryMinimumQuantity, setCategoryMinimumQuantity] = useState(null)

  const [editedCategoryId, setEditedCategoryId] = useState(null)
  const [editedArticleId, setEditedArticleId] = useState(null)
  const [editedOptionId, setEditedOptionId] = useState(null)

  const [articleName, setArticleName] = useState('')
  const [category, setCategory] = useState('')
  const [price, setPrice] = useState(0)
  const [online, setOnline] = useState(true)
  const [description, setDescription] = useState('')
  const [articleSoldOut, setArticleSoldOut] = useState(false)
  const [articleMinimumQuantity, setArticleMinimumQuantity] = useState(null)

  const [checkedArticles, setCheckedArticles] = useState([])
  const [checkedCategories, setCheckedCategories] = useState([])
  const [checkedOptions, setCheckedOptions] = useState([])

  const tab = query.get('tab') || 'categories'
  let selectedCategory = Number(query.get('category')) || null

  const [showCategory, setShowCategory] = useState(false)
  const [showEditCategory, setShowEditCategory] = useState(false)
  const [showArticle, setShowArticle] = useState(false)
  const [showEditArticle, setShowEditArticle] = useState(false)
  const [showOption, setShowOption] = useState(false)
  const [showEditOption, setShowEditOption] = useState(false)

  const [optionTitle, setOptionTitle] = useState('')
  const [optionDescription, setOptionDescription] = useState('')
  const [optionMaximum, setOptionMaximum] = useState('')
  const [optionType, setOptionType] = useState({ value: 'multiple', label: 'Choix multiples' })
  const [optionRequired, setOptionRequired] = useState(false)
  const [optionItems, setOptionItems] = useState([{ id: null, name: '', price: 0 }])
  const [optionCategories, setOptionCategories] = useState([])
  const [optionParentItem, setOptionParentItem] = useState([])
  const [searchTerm, setSearchTerm] = useState('');

  const handleCloseArticle = () => {
    setShowArticle(false)
    resetArticleForm()
  }

  const handleCloseEditArticle = () => {
    setShowEditArticle(false)
    resetArticleForm()
  }

  const handleShowArticle = () => {
    setShowArticle(true)
    if (selectedCategory) {
      let category = categoriesQuery.categories?.find(cat => cat.id === selectedCategory)
      setCategory({ value: category.id, label: category.name })
    }
  }

  const handleCloseOption = () => {
    setShowOption(false)
    resetOptionForm()
    setValidated(false)
  }
  const handleShowOption = () => setShowOption(true)

  const handleCloseCategory = () => {
    setShowCategory(false)
    setCategoryName('')
    setCategoryEmoji('')
    setCategoryMinimum(null)
    setCategoryMinimumQuantity(null)
  }
  const handleShowCategory = () => {
    setShowCategory(true)
  }

  const handleCloseEditCategory = () => {
    setShowEditCategory(false)
    setCategoryName('')
    setCategoryEmoji('')
    setCategoryMinimum(null)
    setCategoryMinimumQuantity(null)
  }
  const handleShowEditCategory = () => setShowEditCategory(true)

  const handleCloseEditOption = () => {
    setShowEditOption(false)
    resetOptionForm()
  }
  const handleShowEditOption = () => setShowEditOption(true)

  const checkArticle = (article) => {
    if (!checkedArticles.includes(article.id)) {
      setCheckedArticles((prev) => [...prev, article.id])
    } else {
      setCheckedArticles(checkedArticles.filter((e) => e !== article.id))
    }
  }

  const checkOption = (article) => {
    if (!checkedOptions.includes(article.id)) {
      setCheckedOptions((prev) => [...prev, article.id])
    } else {
      setCheckedOptions(checkedOptions.filter((e) => e !== article.id))
    }
  }

  const checkCategory = (category) => {
    if (!checkedCategories.includes(category.id)) {
      setCheckedCategories((prev) => [...prev, category.id])
    } else {
      setCheckedCategories(checkedCategories.filter((e) => e !== category.id))
    }
  }

  const submitCategory = async e => {
    e.preventDefault()
    createCategoryMutation({
      variables: {
        name: categoryName, emoji: categoryEmoji?.label, minimum: categoryMinimum ? parseFloat(categoryMinimum) : null,
        minimumQuantity: categoryMinimumQuantity ? parseInt(categoryMinimumQuantity) : null
      },
    })
      .then(async () => {
        handleCloseCategory()
        await categoriesQuery.refetch()
      })
      .catch(e => {
        console.log(e)
      })
  }

  const editCategory = async e => {
    e.preventDefault()
    updateCategoryMutation({
      variables: {
        id: editedCategoryId,
        name: categoryName,
        emoji: categoryEmoji?.label || '',
        minimum: categoryMinimum ? parseFloat(categoryMinimum) : null,
        minimumQuantity: categoryMinimumQuantity ? parseInt(categoryMinimumQuantity) : null
      },
    })
      .then(async () => {
        handleCloseEditCategory()
        await categoriesQuery.refetch()
      })
      .catch(e => {
        console.log(e)
      })
  }

  const editOption = async e => {
    e.preventDefault()

    updateOptionMutation({
      variables: {
        id: editedOptionId,
        title: optionTitle,
        description: optionDescription,
        maximum: optionMaximum ? Number(optionMaximum) : null,
        type: optionType.value,
        required: optionRequired,
        items: optionItems?.map(item => ({ id: item.id, name: item.name, price: item.price })),
        categories: optionCategories?.map(option => Number(option.value)) || [],
        parentItem: optionParentItem?.map(option => Number(option.value)) || []
      },
    })
      .then(async () => {
        handleCloseEditOption()
        await optionsQuery.refetch()
      })
      .catch(e => {
        console.log(e)
      })
  }

  const submitArticle = async e => {
    e.preventDefault()
    const form = e.currentTarget
    if (form.checkValidity() === false || !category) {
      e.stopPropagation()
      setValidated(true)
      return
    }
    setValidated(false)

    // TODO : ajouter un loader
    createArticleMutation({
      variables: {
        name: articleName, category: category.value, price, online, description,
        minimumQuantity: articleMinimumQuantity ? parseInt(articleMinimumQuantity) : null
      },
    })
      .then(async mutation => {
        resetArticleForm()
        articlesQuery.refetch()
        if (redirect) {
          history.push(`/menu/article/${mutation.data.createArticle.id}`)
        } else {
          if (selectedCategory) {
            let category = categoriesQuery.categories?.find(cat => cat.id === selectedCategory)
            setCategory({ value: category.id, label: category.name })
          }
        }
      })
      .catch(e => {
        console.log(e)
      })
    setRedirect(true)
  }

  const resetArticleForm = () => {
    setArticleName('')
    setCategory('')
    setPrice(0)
    setOnline(true)
    setDescription('')
    setEditedArticleId('')
    setArticleSoldOut(false)
    setArticleMinimumQuantity(null)
  }

  const resetOptionForm = () => {
    setEditedOptionId(null)
    setOptionTitle('')
    setOptionDescription('')
    setOptionMaximum('')
    setOptionType({ value: 'multiple', label: 'Choix multiples' })
    setOptionRequired(false)
    setOptionCategories([])
    setOptionItems([{ id: null, name: '', price: 0 }])
    setOptionParentItem([])
  }

  const handleInputOption = (e, index, input) => {
    let newOptionItems = JSON.parse(JSON.stringify(optionItems));
    newOptionItems[index][input] = e.target.value // Update it with the modified email.
    setOptionItems(newOptionItems) // Update the state.
  }

  const updateArticle = async e => {
    e.preventDefault()
    updateArticleMutation({
      variables: {
        id: editedArticleId, name: articleName, price, description,
        soldOut: articleSoldOut, category: category.value,
        minimumQuantity: articleMinimumQuantity ? parseInt(articleMinimumQuantity) : null
      },
    })
      .then(async mutation => {
        handleCloseEditArticle()
        await articlesQuery.refetch()
      })
      .catch(e => {
        console.log(e)
      })
  }

  const submitOption = async e => {
    e.preventDefault()
    const form = e.currentTarget
    if (form.checkValidity() === false || !optionType) {
      e.stopPropagation()
      setValidated(true)
      return
    }
    setValidated(false)

    createArticleOptionMutation({
      variables: {
        title: optionTitle,
        description: optionDescription,
        maximum: optionMaximum ? Number(optionMaximum) : null,
        type: optionType.value,
        items: optionItems.filter(item => item?.name),
        categories: optionCategories.map(option => Number(option.value)),
        generalOption: true,
        required: optionRequired,
        parentItem: optionParentItem?.map(option => Number(option.value)) || [],
      },
    })
      .then(async () => {
        handleCloseOption()
        await optionsQuery.refetch()
      })
      .catch(e => {
        console.log(e)
      })
  }

  const handleDragEndCategories = async result => {
    const { destination, source } = result

    if (!destination) {
      return
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    const categories = Array.from(categoriesQuery.categories)
    const [removed] = categories.splice(source.index, 1)
    categories.splice(destination.index, 0, removed)

    await Promise.all(categories.map((category, index) => (
      updateCategoryMutation({
        variables: { id: category.id, number: index },
      })
    )))
    await categoriesQuery.refetch()
  }

  const handleDragEndArticles = async result => {
    const { destination, source } = result

    if (!destination) {
      return
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    const articles = Array.from(articlesQuery.articles.filter(e => selectedCategory ? e.category.id === selectedCategory : e))
    const [removed] = articles.splice(source.index, 1)
    articles.splice(destination.index, 0, removed)

    await Promise.all(articles.map((article, index) => (
      updateArticleMutation({
        variables: { id: article.id, number: index },
      })
    )))
    await articlesQuery.refetch()
  }

  const handleChange = event => {
    setSearchTerm(event.target.value);
  };

  if (categoriesQuery.loading || articlesQuery.loading || optionsQuery.loading) {
    return (
      <Loader/>
    )
  }

  let renderTab, renderButton
  if (tab === 'categories') {
    const searchResults = !searchTerm
      ? categoriesQuery?.categories
      : categoriesQuery?.categories?.filter(category =>
        category.name.toString().toLowerCase().includes(searchTerm)
      )
    renderButton = <button onClick={handleShowCategory} className="btn btn-primary lift">
      + Ajouter une catégorie
    </button>
    renderTab =
      <div className="card">
        <div className="card-header">
          <div className="dropdown flex-grow-0 mr-4">
            {checkedCategories.length > 0
            && <span>{checkedCategories.length} <i className="fe fe-check-square"/></span>
            }
            <Dropdown>
              <Dropdown.Toggle variant="white" size="sm">
                Actions
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={async () => {
                  if (window.confirm('Êtes-vous sûr de vouloir supprimer les catégories sélectionnés ?')) {
                    await deleteCategoriesMutation({ variables: { id: checkedCategories } })
                    await categoriesQuery.refetch()
                    await articlesQuery.refetch()
                    setCheckedCategories([])
                  }
                }}>Supprimer</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <form className="flex-grow-1">
            <div className="input-group input-group-flush">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fe fe-search"/>
                </span>
              </div>
              <input className="form-control search" type="search" placeholder="Recherche..."
                     value={searchTerm}
                     onChange={handleChange}/>
            </div>
          </form>
        </div>
        <div className="table-responsive">
          <table className="table table-sm table-nowrap card-table table-hover">
            <thead>
            <tr>
              <th style={{ width: '20px' }}>
                <div className="custom-control custom-checkbox table-checkbox">
                  <input
                    id="selectAllCategories"
                    type="checkbox"
                    className="custom-control-input"
                    name="ordersSelect"
                    checked={checkedCategories.length === searchResults?.length && searchResults?.length > 0}
                    onChange={(e) => {
                      if (e.target.checked === false)
                        setCheckedCategories([])
                      else
                        setCheckedCategories(searchResults?.map(category => category.id))
                    }}/>
                  <label className="custom-control-label" htmlFor="selectAllCategories">&nbsp;</label>
                </div>
              </th>
              <th style={{ width: '50%' }}>
                <span className="text-muted sort" data-sort="orders-product">
                  Nom
                </span>
              </th>
              <th colSpan={2}>
                <span className="text-muted sort" data-sort="orders-product">
                  Nombre d'articles
                </span>
              </th>
            </tr>
            </thead>
            <DragDropContext onDragEnd={handleDragEndCategories}>
              <Droppable droppableId="categories">
                {(provided) => (
                  <tbody className="list" ref={provided.innerRef} {...provided.droppableProps}>
                  {searchResults?.map((category, index) => (
                    <Draggable
                      draggableId={category.id.toString()}
                      key={category.id}
                      index={index}
                    >
                      {(provided) => (
                        <CategoryItem
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          innerRef={provided.innerRef}

                          length={searchResults.length}
                          category={category}
                          key={category.id}
                          checked={checkedCategories.find(e => e === category.id) || false}
                          onChange={checkCategory}
                          editCategory={() => {
                            setEditedCategoryId(category.id)
                            setCategoryName(category.name)
                            setCategoryEmoji({
                              value: emojis.filter(cat => cat.label === category.emoji)[0]?.value,
                              label: category.emoji
                            })
                            setCategoryMinimum(category.minimum)
                            setCategoryMinimumQuantity(category.minimumQuantity)
                            handleShowEditCategory()
                          }}
                          deleteCategory={async () => {
                            if (window.confirm('Êtes-vous sûr de vouloir supprimer la catégorie ?')) {
                              await deleteCategoryMutation({ variables: { id: category.id } })
                              await categoriesQuery.refetch()
                              await articlesQuery.refetch()
                            }
                          }}/>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          </table>
        </div>
      </div>
  } else if (tab === 'articles') {
    const searchResults = !searchTerm
      ? articlesQuery?.articles
      : articlesQuery?.articles?.filter(article =>
        article.name.toString().toLowerCase().includes(searchTerm)
      )
    renderButton = <button onClick={handleShowArticle} className="btn btn-primary lift">
      + Ajouter un article
    </button>
    renderTab =
      <div className="card">
        <div className="card-header">
          <div className="dropdown flex-grow-0 mr-4">
            {checkedArticles.length > 0
            && <span>{checkedArticles.length} <i className="fe fe-check-square"/></span>
            }
            <Dropdown>
              <Dropdown.Toggle variant="white" size="sm">
                Actions
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={async () => {
                  if (window.confirm('Êtes-vous sûr de vouloir supprimer les articles sélectionnés ?')) {
                    await deleteArticlesMutation({ variables: { id: checkedArticles } })
                    await articlesQuery.refetch()
                    setCheckedArticles([])
                  }
                }}>Supprimer</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <form className="flex-grow-1">
            <div className="input-group input-group-flush">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fe fe-search"/>
                </span>
              </div>
              <input className="form-control search" type="search" placeholder="Recherche..." value={searchTerm}
                     onChange={handleChange}/>
            </div>
          </form>
        </div>
        <div className="table-responsive">
          <table className="table table-sm table-nowrap card-table table-hover">
            <thead>
            <tr>
              <th>
                <div className="custom-control custom-checkbox table-checkbox">
                  <input
                    id="selectAllArticles"
                    type="checkbox"
                    className="custom-control-input"
                    name="ordersSelect"
                    checked={checkedArticles.length === searchResults?.filter(e => selectedCategory ? e.category.id === selectedCategory : e).length && searchResults?.filter(e => selectedCategory ? e.category.id === selectedCategory : e).length > 0}
                    onChange={(e) => {
                      if (e.target.checked === false)
                        setCheckedArticles([])
                      else
                        setCheckedArticles(searchResults?.filter(e => selectedCategory ? e.category.id === selectedCategory : e).map(article => article.id))
                    }}/>
                  <label className="custom-control-label" htmlFor="selectAllArticles">&nbsp;</label>
                </div>
              </th>
              <th>
                <span className="text-muted sort" data-sort="orders-product">
                  Nom
                </span>
              </th>
              <th>
                <span className="text-muted sort" data-sort="orders-date">
                  Catégorie
                </span>
              </th>
              <th>
                <span className="text-muted sort" data-sort="orders-total">
                  Total
                </span>
              </th>
              <th>
                <span className="text-muted sort" data-sort="orders-status">
                  Statut
                </span>
              </th>
              <th colSpan={2}>
                <span className="text-muted sort" data-sort="orders-method">
                  Description
                </span>
              </th>
            </tr>
            </thead>
            <DragDropContext onDragEnd={handleDragEndArticles}>
              <Droppable droppableId="articles">
                {(provided) => (
                  <tbody className="list" ref={provided.innerRef} {...provided.droppableProps}>
                  {searchResults?.filter(article => selectedCategory ? article.category.id === selectedCategory : article).map((article, index) => (
                    <Draggable
                      draggableId={article.id.toString()}
                      key={article.id}
                      index={index}
                      isDragDisabled={!selectedCategory}
                    >
                      {(provided) => (
                        <ArticleItem
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          innerRef={provided.innerRef}

                          article={article}
                          key={article.id}
                          checked={checkedArticles.find(e => e === article.id) || false}
                          onChange={checkArticle}
                          editArticle={() => {
                            setEditedArticleId(article.id)
                            setArticleName(article.name)
                            setCategory({ value: article.category.id, label: article.category.name })
                            setPrice(article.price)
                            setDescription(article.description)
                            setArticleSoldOut(article.soldOut)
                            setArticleMinimumQuantity(article.minimumQuantity)
                            setShowEditArticle(true)
                          }}
                          deleteArticle={async () => {
                            if (window.confirm('Êtes-vous sûr de vouloir supprimer l\'article ?')) {
                              await deleteArticleMutation({ variables: { id: article.id } })
                              await articlesQuery.refetch()
                            }
                          }}/>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          </table>
        </div>
      </div>
  } else {
    const searchResults = !searchTerm
      ? optionsQuery.options
      : optionsQuery.options?.filter(option =>
        option.title.toString().toLowerCase().includes(searchTerm)
      )
    renderButton = <button onClick={handleShowOption} className="btn btn-primary lift">
      + Ajouter une option
    </button>
    renderTab =
      <div className="card">
        <div className="card-header">
          <div className="dropdown flex-grow-0 mr-4">
            {checkedOptions.length > 0
            && <span>{checkedOptions.length} <i className="fe fe-check-square"/></span>
            }
            <Dropdown>
              <Dropdown.Toggle variant="white" size="sm">
                Actions
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={async () => {
                  if (window.confirm('Êtes-vous sûr de vouloir supprimer les articles sélectionnés ?')) {
                    await deleteOptionsMutation({ variables: { id: checkedOptions } })
                    await optionsQuery.refetch()
                    setCheckedOptions([])
                  }
                }}>Supprimer</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <form className="flex-grow-1">
            <div className="input-group input-group-flush">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fe fe-search"/>
                </span>
              </div>
              <input className="form-control search" type="search" placeholder="Recherche..."
                     value={searchTerm}
                     onChange={handleChange}/>
            </div>
          </form>
        </div>
        <div className="table-responsive">
          <table className="table table-sm table-nowrap card-table">
            <thead>
            <tr>
              <th>
                <div className="custom-control custom-checkbox table-checkbox">
                  <input
                    id="selectAllOptions"
                    type="checkbox"
                    className="custom-control-input"
                    name="ordersSelect"
                    checked={checkedOptions.length === optionsQuery.options?.length && optionsQuery.options?.length > 0}
                    onChange={(e) => {
                      if (e.target.checked === false)
                        setCheckedOptions([])
                      else
                        setCheckedOptions(optionsQuery.options?.map(option => option.id))
                    }}/>
                  <label className="custom-control-label" htmlFor="selectAllOptions">&nbsp;</label>
                </div>
              </th>
              <th>
                <span className="text-muted sort" data-sort="orders-product">
                  Nom
                </span>
              </th>
              <th>
                <span className="text-muted sort" data-sort="orders-date">
                  Type
                </span>
              </th>
              <th>
                <span className="text-muted sort" data-sort="orders-total">
                  Maximum
                </span>
              </th>
              <th colSpan={2}>
                <span className="text-muted sort" data-sort="orders-method">
                  Description
                </span>
              </th>
            </tr>
            </thead>
            <tbody className="list">
            {searchResults?.map((option, index) => (
              <OptionItem
                option={option}
                key={option.id}
                checked={checkedOptions.find(e => e === option.id) || false}
                onChange={checkOption}
                editOption={() => {
                  setEditedOptionId(option.id)
                  setOptionTitle(option.title)
                  setOptionDescription(option.description || '')
                  setOptionMaximum(option.maximum || '')
                  setOptionType({ value: option.type, label: getOptionLabel(option.type) })
                  setOptionRequired(option.required)
                  setOptionItems(option.items?.length > 0 ? option.items : [{ id: null, name: '', price: 0 }])
                  setOptionCategories(option.categories?.map(category => (
                    { value: category.id, label: category.name }
                  )))
                  setOptionParentItem(option.parentItem?.map(option => (
                    { value: option.id, label: option.name }
                  )))
                  handleShowEditOption()
                }}
                deleteOption={async () => {
                  if (window.confirm('Êtes-vous sûr de vouloir supprimer l\'option ?')) {
                    await deleteOptionMutation({ variables: { id: option.id } })
                    await optionsQuery.refetch()
                  }
                }}/>
            ))}
            </tbody>
          </table>
        </div>
      </div>
  }

  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="header mt-md-5">
            <div className="header-body">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="header-title">
                    Carte du restaurant
                  </h1>
                </div>
                <div className="col-auto">
                  {renderButton}
                  <button className="btn btn-dark ml-3 h-100" onClick={() =>
                    window.location.reload(true)
                  }>
                    <i className="fe fe-refresh-cw"/>
                  </button>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col">
                  <ul className="nav nav-tabs nav-overflow header-tabs">
                    <li className="nav-item">
                      <Link to="/menu?tab=categories" className={`nav-link ${tab === 'categories' && 'active'}`}
                            onClick={() => setSearchTerm('')}>
                        Catégories
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/menu?tab=articles" className={`nav-link ${tab === 'articles' && 'active'}`}
                            onClick={() => setSearchTerm('')}>
                        Articles
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/menu?tab=options" className={`nav-link ${tab === 'options' && 'active'}`}
                            onClick={() => setSearchTerm('')}>
                        Options
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {tab === 'articles' &&
              <div className="row align-items-center mt-4">
                <div className="col">
                  <ul className="nav nav-tabs nav-overflow header-tabs no-padding">
                    <li className="nav-item">
                      <Link to="/menu?tab=articles" className={`nav-link ${selectedCategory === null && 'active'}`}>
                        Tous les articles
                      </Link>
                    </li>
                    {categoriesQuery.categories?.map(category =>
                      <li className="nav-item" key={category.id}>
                        <span onClick={() => {
                          selectedCategory = null
                          history.replace(`/menu?tab=articles&category=${category.id}`)
                        }} className={`cursor-pointer nav-link ${selectedCategory === category.id && 'active'}`}>
                          {category.name}{category.emoji && <span>&nbsp;{category.emoji}</span>}
                        </span>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              }
            </div>
          </div>
          {renderTab}
        </div>
      </div>

      <Modal show={showCategory} onHide={handleCloseCategory}>
        <div className="modal-card card">
          <div className="card-header">
            <h4 className="card-header-title" id="exampleModalCenterTitle">
              Ajouter une catégorie
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                    onClick={handleCloseCategory}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="card-body">
            <form onSubmit={submitCategory}>
              <div className="form-group">
                <label htmlFor="addCategoryName">Nom</label>
                <input
                  autoFocus={true}
                  type="text"
                  className="form-control"
                  id="addCategoryName"
                  placeholder="Exemple : Burgers"
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                  autoComplete="off"/>
              </div>
              <div className="form-group">
                <label htmlFor="addEmoji">Emoji (Optionnel)</label>
                <ModalSelect
                  isValid={validated ? categoryEmoji : null}
                  value={categoryEmoji}
                  onChange={(option) => setCategoryEmoji(option)}
                  options={emojis.map(emoji => ({ value: emoji.value, label: emoji.label }))}
                  isClearable={true}
                  id="addEmoji"
                />
              </div>
              {restaurant?.id === 18 &&
              <>
                {false &&
                <div className="form-group">
                  <label htmlFor="addMinimum">Minimum de commande</label>
                  <input
                    type="number"
                    className="form-control"
                    id="addMinimum"
                    placeholder="20€"
                    value={categoryMinimum}
                    onChange={(e) => setCategoryMinimum(e.target.value)}
                    autoComplete="off"/>
                </div>
                }
                <div className="form-group">
                  <label htmlFor="addMinimum">Minimum d'articles pour commander</label>
                  <input
                    type="number"
                    className="form-control"
                    id="addMinimum"
                    value={categoryMinimumQuantity}
                    onChange={(e) => setCategoryMinimumQuantity(e.target.value)}
                    autoComplete="off"/>
                </div>
              </>}
              <div className="d-flex justify-content-between mt-5">
                <button type="button" className="btn btn-secondary" onClick={handleCloseCategory}>Annuler</button>
                <button type="submit" className="btn btn-primary">Ajouter</button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal show={showEditCategory} onHide={handleCloseEditCategory}>
        <div className="modal-card card">
          <div className="card-header">
            <h4 className="card-header-title" id="exampleModalCenterTitle">
              Modifier la catégorie
            </h4>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                    onClick={handleCloseEditCategory}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="card-body">
            <form onSubmit={editCategory}>
              <div className="form-group">
                <label htmlFor="editCategoryName">Nom</label>
                <input
                  type="text"
                  className="form-control"
                  id="editCategoryName"
                  placeholder="Entrez le nom de la catégorie"
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                  autoComplete="off"/>
              </div>
              <div className="form-group">
                <label htmlFor="editEmoji">Emoji (Optionnel)</label>
                <ModalSelect
                  isValid={validated ? categoryEmoji : null}
                  value={categoryEmoji}
                  onChange={(option) => setCategoryEmoji(option)}
                  options={emojis.map(emoji => ({ value: emoji.value, label: emoji.label }))}
                  isClearable={true}
                  id="editEmoji"
                />
              </div>
              {restaurant?.id === 18 &&
              <>
                {false &&
                <div className="form-group">
                  <label htmlFor="editMinimum">Minimum de commande</label>
                  <input
                    type="number"
                    className="form-control"
                    id="editMinimum"
                    placeholder="20€"
                    value={categoryMinimum}
                    onChange={(e) => setCategoryMinimum(e.target.value)}
                    autoComplete="off"/>
                </div>
                }
                <div className="form-group">
                  <label htmlFor="editMinimum">Minimum d'articles pour commander</label>
                  <input
                    type="number"
                    className="form-control"
                    id="editMinimum"
                    placeholder="10"
                    value={categoryMinimumQuantity}
                    onChange={(e) => setCategoryMinimumQuantity(e.target.value)}
                    autoComplete="off"/>
                </div>
              </>}
              <div className="d-flex justify-content-between mt-5">
                <button type="button" className="btn btn-secondary" onClick={handleCloseEditCategory}>Annuler</button>
                <button type="submit" className="btn btn-primary">Sauvegarder</button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal show={showOption} onHide={handleCloseOption} size="lg" backdrop="static" className="modal-full-height">
        <Form noValidate validated={validated} onSubmit={submitOption} className="d-flex flex-column h-100"
              onKeyPress={(e) => e.which === 13 && e.preventDefault()}>
          <Modal.Header closeButton>
            <Modal.Title>
              Ajouter une option
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="inputName">Titre</label>
              <small className="form-text text-muted">Entrez le titre de l'option</small>
              <input
                required
                type="text"
                className="form-control"
                placeholder="Sauces"
                value={optionTitle}
                onChange={(e) => setOptionTitle(e.target.value)}
                autoComplete="off"/>
              <Form.Control.Feedback type="invalid">Veuillez entrer un titre</Form.Control.Feedback>
            </div>
            <div className="form-group">
              <label htmlFor="inputName">Description</label>
              <small className="form-text text-muted">Une indication pour l'utilisateur</small>
              <textarea
                className="form-control"
                placeholder="Choisissez vos sauces"
                value={optionDescription}
                onChange={(e) => setOptionDescription(e.target.value)}/>
            </div>
            <div className="form-group">
              <label htmlFor="inputName">Type</label>
              <small className="form-text text-muted">
                Vous décidez si votre champ est à choix unique ou multiple
              </small>
              <ModalSelect
                required
                isValid={validated ? optionType : null}
                isSearchable={false}
                isClearable={false}
                value={optionType}
                onChange={(option) => setOptionType(option)}
                options={[
                  { value: 'unique', label: 'Choix unique' },
                  { value: 'multiple', label: 'Choix multiples' },
                ]}
              />
            </div>
            <div className="form-group">
              <label>Requis</label>
              <div className="custom-control custom-switch">
                <input type="checkbox" className="custom-control-input" id="requiredOption" checked={optionRequired}
                       onChange={(e) => setOptionRequired(e.target.checked)}/>
                <label className="custom-control-label"
                       htmlFor="requiredOption">{optionRequired ? 'Oui' : 'Non'}</label>
              </div>
            </div>
            {optionType?.value === 'multiple' &&
            <div className="form-group">
              <label htmlFor="inputName">Maximum</label>
              <small className="form-text text-muted">Nombre d'éléments maximum sélectionnables</small>
              <input
                type="number"
                className="form-control"
                value={optionMaximum}
                onChange={(e) => setOptionMaximum(e.target.value)}
                autoComplete="off"/>
            </div>
            }
            <Form.Group>
              <Form.Label>Catégorie(s)</Form.Label>
              <ModalSelect
                isMulti
                isValid={validated ? optionCategories : null}
                value={optionCategories}
                onChange={(option) => setOptionCategories(option)}
                options={categoriesQuery.categories?.map(category => (
                  { value: category.id, label: category.name }
                ))
                }
              />
              <Form.Control.Feedback type="invalid">Veuillez choisir une ou plusieurs
                catégorie(s)</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Choix d'option dépendant</Form.Label>
              <ModalSelect
                isMulti
                isClearable={true}
                isValid={validated ? optionParentItem : null}
                value={optionParentItem}
                onChange={(option) => setOptionParentItem(option)}
                options={optionsItemsQuery.optionsItems?.map(item => (
                  { value: item.id, label: item.name }
                ))}
              />
            </Form.Group>
            <div className="form-group">
              <label htmlFor="inputName">Options</label>
              <small className="form-text text-muted">Nom, suivi du prix. Si le prix est compris dans le menu, laissez
                le champ à 0</small>
              {optionItems.map((item, index) =>
                <div className="row" key={index}>
                  <div className="col-6">
                    <div className="input-group input-group-merge">
                      <input
                        type="text"
                        className="form-control form-control-prepended"
                        placeholder="Nom"
                        value={item.name}
                        onChange={(e) => handleInputOption(e, index, 'name')}
                        autoComplete="off"
                      />
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <span className="fe fe-clipboard"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <CurrencyInput
                      className="form-control form-control-prepended"
                      placeholder="Prix"
                      defaultValue={item.price}
                      onChange={(e) => handleInputOption({ target: { value: e } }, index, 'price')}
                    />
                  </div>
                  <div className="col-2 d-flex align-items-center justify-content-around">
                    <button type="button" className="btn btn-sm btn-outline-danger mr-2"
                            onClick={() => {
                              let newOptionItems = optionItems.filter((item, idx) => index !== idx)
                              if (newOptionItems.length === 0) {
                                newOptionItems = [{ id: null, name: '', price: 0 }]
                              }
                              setOptionItems(newOptionItems)
                            }}>
                      <span className="fe fe-trash"></span>
                    </button>
                    <button type="button" className="btn btn-sm btn-outline-primary"
                            onClick={() => setOptionItems([...optionItems, { id: null, name: '', price: 0 }])}>
                      <span className="fe fe-plus"></span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-between">
            <button type="button" className="btn btn-secondary" onClick={handleCloseOption}>Annuler</button>
            <button type="submit" className="btn btn-primary">Ajouter</button>
          </Modal.Footer>
        </Form>
      </Modal>


      <Modal show={showArticle} onHide={handleCloseArticle} backdrop="static" centered>
        <Form onKeyPress={(e) => e.which === 13 && e.preventDefault()} noValidate validated={validated}
              onSubmit={submitArticle}>
          <Modal.Header closeButton>
            <Modal.Title>
              Ajouter un article
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Nom</Form.Label>
              <Form.Control
                required
                type="text"
                className="form-control"
                placeholder="Entrez le nom de l'article"
                value={articleName}
                onChange={(e) => setArticleName(e.target.value)}
                autoComplete="off"
                autoFocus={true}
              />
              <Form.Control.Feedback type="invalid">Veuillez entrer un nom</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Catégorie</Form.Label>
              <ModalSelect
                isValid={validated ? category : null}
                required
                value={category}
                onChange={(option) => setCategory(option)}
                options={categoriesQuery.categories?.map(category => (
                  { value: category.id, label: category.name }
                ))
                }
              />
              <Form.Control.Feedback type="invalid">Veuillez choisir une catégorie</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Prix</Form.Label>
              <CurrencyInput
                required
                className="form-control"
                placeholder="Entrez le prix"
                onChange={(e) => setPrice(e)}
                defaultValue={price}
                autoComplete="off"
              />
              <Form.Control.Feedback type="invalid">Veuillez entrez un prix</Form.Control.Feedback>
            </Form.Group>
            <div className="form-group">
              <label htmlFor="inputName">Description</label>
              <textarea
                className="form-control"
                placeholder="Entrez la description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}/>
            </div>
            {restaurant?.id === 34 &&
            <>
              <div className="form-group">
                <label htmlFor="editMinimum">Minimum d'articles pour commander</label>
                <input
                  type="number"
                  className="form-control"
                  id="editMinimum"
                  placeholder="10"
                  value={articleMinimumQuantity}
                  onChange={(e) => setArticleMinimumQuantity(e.target.value)}
                  autoComplete="off"/>
              </div>
            </>}
          </Modal.Body>
          <Modal.Footer className="justify-content-between">
            <button type="button" className="btn btn-secondary" onClick={handleCloseArticle}>Annuler</button>
            <button type="submit" className="btn btn-primary" onClickCapture={() => setRedirect(false)}>
              Ajouter un autre
            </button>
            <button type="submit" className="btn btn-primary">Ajouter</button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={showEditOption} onHide={handleCloseEditOption} size="lg" backdrop="static"
             className="modal-full-height">
        <Form noValidate validated={validated} onSubmit={editOption} className="d-flex flex-column h-100"
              onKeyPress={(e) => e.which === 13 && e.preventDefault()}>
          <Modal.Header closeButton>
            <Modal.Title>
              Editer une option
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="inputName">Titre</label>
              <small className="form-text text-muted">Entrez le titre de l'option</small>
              <input
                required
                type="text"
                className="form-control"
                placeholder="Sauces"
                value={optionTitle}
                onChange={(e) => setOptionTitle(e.target.value)}
                autoComplete="off"/>
              <Form.Control.Feedback type="invalid">Veuillez entrer un titre</Form.Control.Feedback>
            </div>
            <div className="form-group">
              <label htmlFor="inputName">Description</label>
              <small className="form-text text-muted">Une indication pour l'utilisateur</small>
              <textarea
                className="form-control"
                placeholder="Choisissez vos sauces"
                value={optionDescription}
                onChange={(e) => setOptionDescription(e.target.value)}/>
            </div>
            <Form.Group>
              <Form.Label>Catégorie(s)</Form.Label>
              <ModalSelect
                isMulti
                isValid={validated ? optionCategories : null}
                value={optionCategories}
                onChange={(option) => setOptionCategories(option)}
                options={categoriesQuery.categories?.map(category => (
                  { value: category.id, label: category.name }
                ))}
              />
              <Form.Control.Feedback type="invalid">Veuillez choisir une ou plusieurs
                catégorie(s)</Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Choix d'option dépendant</Form.Label>
              <ModalSelect
                isMulti
                isClearable={true}
                isValid={validated ? optionParentItem : null}
                value={optionParentItem}
                onChange={(option) => setOptionParentItem(option)}
                options={optionsItemsQuery.optionsItems?.map(item => (
                  { value: item.id, label: item.name }
                ))}
              />
            </Form.Group>
            <div className="form-group">
              <label htmlFor="inputName">Type</label>
              <small className="form-text text-muted">
                Vous décidez si votre champ est à choix unique ou multiple
              </small>
              <ModalSelect
                required
                isValid={validated ? optionType : null}
                isSearchable={false}
                isClearable={false}
                value={optionType}
                onChange={(option) => setOptionType(option)}
                options={[
                  { value: 'unique', label: 'Choix unique' },
                  { value: 'multiple', label: 'Choix multiples' },
                ]}
              />
            </div>
            <div className="form-group">
              <label>Requis</label>
              <div className="custom-control custom-switch">
                <input type="checkbox" className="custom-control-input" id="requiredOption" checked={optionRequired}
                       onChange={(e) => setOptionRequired(e.target.checked)}/>
                <label className="custom-control-label"
                       htmlFor="requiredOption">{optionRequired ? 'Oui' : 'Non'}</label>
              </div>
            </div>
            {optionType?.value === 'multiple' &&
            <div className="form-group">
              <label htmlFor="inputName">Maximum</label>
              <small className="form-text text-muted">Nombre d'éléments maximum sélectionnables</small>
              <input
                type="number"
                className="form-control"
                value={optionMaximum}
                onChange={(e) => setOptionMaximum(e.target.value)}
                autoComplete="off"/>
            </div>
            }
            <div className="form-group">
              <label htmlFor="inputName">Options</label>
              <small className="form-text text-muted">Nom, suivi du prix. Si le prix est compris dans le menu, laissez
                le champ à 0</small>
              {optionItems.map((item, index) =>
                <div className="row" key={index}>
                  <div className="col-6">
                    <div className="input-group input-group-merge">
                      <input
                        type="text"
                        className="form-control form-control-prepended"
                        placeholder="Nom"
                        value={item.name}
                        onChange={(e) => handleInputOption(e, index, 'name')}
                        autoComplete="off"
                      />
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <span className="fe fe-clipboard"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <CurrencyInput
                      className="form-control form-control-prepended"
                      placeholder="Prix"
                      defaultValue={item.price}
                      onChange={(e) => handleInputOption({ target: { value: e } }, index, 'price')}
                    />
                  </div>
                  <div className="col-2 d-flex align-items-center justify-content-around">
                    <button type="button" className="btn btn-sm btn-outline-danger mr-2"
                            onClick={() => {
                              let newOptionItems = optionItems.filter((item, idx) => index !== idx)
                              if (newOptionItems.length === 0) {
                                newOptionItems = [{ id: null, name: '', price: 0 }]
                              }
                              setOptionItems(newOptionItems)
                            }}>
                      <span className="fe fe-trash"></span>
                    </button>
                    <button type="button" className="btn btn-sm btn-outline-primary"
                            onClick={() => setOptionItems([...optionItems, { id: null, name: '', price: 0 }])}>
                      <span className="fe fe-plus"></span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-between">
            <button type="button" className="btn btn-secondary" onClick={handleCloseEditOption}>Annuler</button>
            <button type="submit" className="btn btn-primary">Sauvegarder</button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={showEditArticle} onHide={handleCloseEditArticle} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Modifier l'article
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="inputName">Nom</label>
            <input
              type="text"
              className="form-control"
              id="inputName"
              placeholder="Entrez le nom de l'article"
              value={articleName}
              onChange={(e) => setArticleName(e.target.value)}
              autoComplete="off"/>
          </div>
          <div className="form-group">
            <label htmlFor="inputName">Catégorie</label>
            <Select
              value={category}
              onChange={(option) => {
                setCategory(option)
              }}
              options={categoriesQuery?.categories?.map(category => (
                { value: category.id, label: category.name }
              ))
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="inputName">Prix</label>
            <CurrencyInput
              className="form-control"
              placeholder="Entrez le prix"
              onChange={(e) => setPrice(e)}
              defaultValue={price}
            />
          </div>
          <div className="form-group">
            <label htmlFor="inputName">Description</label>
            <textarea
              className="form-control"
              id="inputName"
              placeholder="Entrez la description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}/>
          </div>
          <div className="form-group">
            <label htmlFor="inputName2">Rupture de stock</label>
            <div className="custom-control custom-switch">
              <input type="checkbox" className="custom-control-input" id="inputName2" checked={articleSoldOut}
                     onChange={async (e) => {
                       setArticleSoldOut(e.target.checked)
                     }}/>
              <label className="custom-control-label" htmlFor="inputName2">{articleSoldOut ? 'Oui' : 'Non'}</label>
            </div>
          </div>
          {restaurant?.id === 34 &&
          <>
            <div className="form-group">
              <label htmlFor="editMinimum">Minimum d'articles pour commander</label>
              <input
                type="number"
                className="form-control"
                id="editMinimum"
                placeholder="10"
                value={articleMinimumQuantity}
                onChange={(e) => setArticleMinimumQuantity(e.target.value)}
                autoComplete="off"/>
            </div>
          </>}
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <button type="button" className="btn btn-secondary" onClick={handleCloseEditArticle}>Annuler</button>
          <button type="submit" onClick={updateArticle} className="btn btn-primary">Sauvegarder</button>
        </Modal.Footer>
      </Modal>

    </div>
  )
}

const CATEGORIES_QUERY = gql`
  query categoriesQuery {
    categories {
      id
      name
      emoji
      number
      minimum
      minimumQuantity
      articles {
        id
      }
    }
  }
`
const ARTICLES_QUERY = gql`
  query articlesQuery {
    articles {
      id
      name
      category {
        id
        name
      }
      price
      online
      description
      minimumQuantity
    }
  }
`
const OPTIONS_QUERY = gql`
  query optionsQuery {
    options {
      id
      title
      description
      maximum
      required
      type
      items {
        id
        name
        price
      }
      categories {
        id
        name
      }
      parentItem {
        id
        name
      }
    }
  }
`

const OPTIONS_ITEMS_QUERY = gql`
  query optionsItemsQuery {
    optionsItems {
      id
      name
    }
  }
`

const CREATE_CATEGORY_MUTATION = gql`
  mutation CreateCategoryMutation($name: String!, $emoji: String, $minimum: Float, $minimumQuantity: Int) {
    createCategory(name: $name, emoji: $emoji, minimum: $minimum, minimumQuantity: $minimumQuantity) {
      id
      name
      emoji
      minimum
      minimumQuantity
    }
  }
`

const UPDATE_CATEGORY_MUTATION = gql`
  mutation UpdateCategoryMutation($id: ID!, $name: String, $emoji: String, $number: Int, $minimum: Float, $minimumQuantity: Int) {
    updateCategory(id: $id, name: $name, emoji: $emoji, number: $number, minimum: $minimum, minimumQuantity: $minimumQuantity) {
      id
      name
      emoji
      number
      minimum
      minimumQuantity
    }
  }
`

const UPDATE_ARTICLE_MUTATION = gql`
  mutation UpdateArticleMutation($id: ID!, $number: Int, $name: String, $category: ID, $price: Float, $online: Boolean, $description: String, $soldOut: Boolean, $minimumQuantity: Int) {
    updateArticle(id: $id, number: $number, name: $name, category: $category, price: $price, online: $online, description: $description, soldOut: $soldOut, minimumQuantity: $minimumQuantity) {
      id
      name
      price
      online
      number
    }
  }
`

const CREATE_ARTICLE_MUTATION = gql`
  mutation CreateArticleMutation($name: String!, $category: ID!, $price: Float!, $online: Boolean!, $description: String, $minimumQuantity: Int) {
    createArticle(name: $name, category: $category, price: $price, online: $online, description: $description, minimumQuantity: $minimumQuantity) {
      id
      name
      category {
        id
        name
      }
      price
      online
      description
    }
  }
`

const DELETE_ARTICLES_MUTATION = gql`
  mutation deleteArticlesMutation($id: [ID]!) {
    deleteArticles(id: $id){
      count
    }
  }
`

const DELETE_OPTIONS_MUTATION = gql`
  mutation deleteOptionsMutation($id: [ID]!) {
    deleteOptions(id: $id){
      count
    }
  }
`

const DELETE_CATEGORIES_MUTATION = gql`
  mutation deleteCategoriesMutation($id: [ID]!) {
    deleteCategories(id: $id){
      count
    }
  }
`

const DELETE_CATEGORY_MUTATION = gql`
  mutation deleteCategoryMutation($id: ID!) {
    deleteCategory(id: $id){
      id
    }
  }
`

const DELETE_ARTICLE_MUTATION = gql`
  mutation deleteArticleMutation($id: ID!) {
    deleteArticle(id: $id){
      id
    }
  }
`

const DELETE_ARTICLE_OPTION_MUTATION = gql`
  mutation deleteOptionMutation($id: ID!) {
    deleteArticleOption(id: $id){
      id
    }
  }
`

const CREATE_ARTICLE_OPTION_MUTATION = gql`
  mutation createArticleOption($title: String!, $description: String, $maximum: Int, $type: String!, $required: Boolean, $items: [ArticleOptionItemInput!], $categories: [ID!], $generalOption: Boolean, $parentItem: [ID!]) {
    createArticleOption(title: $title, description: $description, maximum: $maximum, type: $type, required: $required, items: $items, categories: $categories, generalOption: $generalOption, parentItem: $parentItem) {
      id
      title
      description
      type
      maximum
      required
    }
  }
`

const UPDATE_ARTICLE_OPTION_MUTATION = gql`
  mutation updateArticleOption($id: ID!, $title: String!, $description: String, $maximum: Int, $type: String!, $required: Boolean, $items: [ArticleOptionItemInput!], $categories: [ID!], $parentItem: [ID!]) {
    updateArticleOption(id: $id, title: $title, description: $description, maximum: $maximum, type: $type, required: $required items: $items, categories: $categories, parentItem: $parentItem) {
      id
      title
      description
      type
      maximum
      required
    }
  }
`

export default compose(
  graphql(CATEGORIES_QUERY, {
    name: 'categoriesQuery', // name of the injected prop: this.props.categoriesQuery...
    options: {
      fetchPolicy: 'network-only',
    },
  }),
  graphql(ARTICLES_QUERY, {
    name: 'articlesQuery', // name of the injected prop: this.props.categoriesQuery...
    options: {
      fetchPolicy: 'network-only',
    },
  }),
  graphql(OPTIONS_QUERY, {
    name: 'optionsQuery', // name of the injected prop: this.props.categoriesQuery...
    options: {
      fetchPolicy: 'network-only',
    },
  }),
  graphql(OPTIONS_ITEMS_QUERY, {
    name: 'optionsItemsQuery', // name of the injected prop: this.props.categoriesQuery...
    options: {
      fetchPolicy: 'network-only',
    },
  }),
  graphql(CREATE_CATEGORY_MUTATION, {
    name: 'createCategoryMutation', // name of the injected prop: this.props.createDraftMutation...
  }),
  graphql(UPDATE_CATEGORY_MUTATION, {
    name: 'updateCategoryMutation', // name of the injected prop: this.props.createDraftMutation...
  }),
  graphql(UPDATE_ARTICLE_MUTATION, {
    name: 'updateArticleMutation', // name of the injected prop: this.props.createDraftMutation...
  }),
  graphql(DELETE_ARTICLES_MUTATION, {
    name: 'deleteArticlesMutation', // name of the injected prop: this.props.createDraftMutation...
  }),
  graphql(DELETE_OPTIONS_MUTATION, {
    name: 'deleteOptionsMutation', // name of the injected prop: this.props.createDraftMutation...
  }),
  graphql(DELETE_CATEGORIES_MUTATION, {
    name: 'deleteCategoriesMutation', // name of the injected prop: this.props.createDraftMutation...
  }),
  graphql(DELETE_CATEGORY_MUTATION, {
    name: 'deleteCategoryMutation', // name of the injected prop: this.props.createDraftMutation...
  }),
  graphql(CREATE_ARTICLE_MUTATION, {
    name: 'createArticleMutation', // name of the injected prop: this.props.createDraftMutation...
  }),
  graphql(DELETE_ARTICLE_MUTATION, {
    name: 'deleteArticleMutation', // name of the injected prop: this.props.createDraftMutation...
  }),
  graphql(DELETE_ARTICLE_OPTION_MUTATION, {
    name: 'deleteOptionMutation', // name of the injected prop: this.props.createDraftMutation...
  }),
  graphql(CREATE_ARTICLE_OPTION_MUTATION, {
    name: 'createArticleOptionMutation', // name of the injected prop: this.props.createDraftMutation...
  }),
  graphql(UPDATE_ARTICLE_OPTION_MUTATION, {
    name: 'updateOptionMutation', // name of the injected prop: this.props.createDraftMutation...
  }),
)(Menu)
