import React from 'react'
import { useHistory } from 'react-router-dom'

export default function ArticleItem ({ article, checked, onChange, editArticle, deleteArticle, innerRef, ...props }) {
  const history = useHistory();

  const goToArticle = (e) => {
    if (!['INPUT', 'LABEL', 'I', 'SELECT', 'BUTTON'].includes(e.target.tagName)) {
      return history.push(`/menu/article/${article.id}`)
    }
  }

  return (
    <tr ref={innerRef} {...props} onClick={goToArticle}>
      <td>
        <div className="custom-control custom-checkbox table-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            name="ordersSelect"
            id={`orders${article.id}`}
            onChange={(e) => {
              e.stopPropagation();
              onChange(article);
            }}
            checked={checked}/>
          <label className="custom-control-label" htmlFor={`orders${article.id}`} data-redirect={true}>&nbsp;</label>
        </div>
      </td>
      <td className="orders-product">
        {article.name}
      </td>
      <td className="orders-date">
        {article.category?.name}
      </td>
      <td className="orders-total">
        {article.price}€
      </td>
      <td className="orders-status">
        <div className={`badge ${article.online ? 'badge-soft-success' : 'badge-soft-danger'}`}>
          {article.online ? 'En ligne' : 'Hors ligne'}
        </div>
      </td>
      <td className="orders-method">
        {article.description.substring(0, 40)}{article.description?.length > 40 ? '...' : ''}
      </td>
      <td className="text-right">
        <button className="btn btn-lg btn-info mr-5" onClick={editArticle}>
          <i className="fe fe-edit"/>
        </button>
        <button className="btn btn-lg btn-outline-danger" onClick={deleteArticle}>
          <i className="fe fe-trash"/>
        </button>
      </td>
    </tr>
  )
}
