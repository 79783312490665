import React, { useState, useEffect, useRef } from 'react';

const removeCommas = (value) => value.replace(/,/g, '');

const checkIsValidNumber = (input) => {
  return !(Number(input) < 0 || isNaN(Number(input)))
};

/**
 * Remove prefix, commas and extra decimals from value
 */
const cleanValue = (
  value,
  allowDecimals,
  decimalsLimit,
  prefix
) => {
  const withoutPrefix = prefix ? value.replace(prefix, '') : value;
  const withoutCommas = removeCommas(withoutPrefix);

  if (withoutCommas.includes('.')) {
    const [int, decimals] = withoutCommas.split('.');
    const includeDecimals = allowDecimals
      ? `.${decimalsLimit ? decimals.slice(0, decimalsLimit) : decimals}`
      : '';

    return `${int}${includeDecimals}`;
  }

  return withoutCommas;
};

/**
 * Format value with commas and prefix
 */
const formatValue = (value, prefix) => {
  const [int, decimals] = value.split('.');
  const includeDecimals = value.includes('.') ? `.${decimals}` : '';

  return `${int}${includeDecimals}`;
};
export const CurrencyInput = ({
                                allowDecimals = true,
                                id,
                                name,
                                className,
                                decimalsLimit = 2,
                                defaultValue,
                                disabled = false,
                                required = false,
                                onChange,
                                placeholder,
                                prefix = '€',
                                maxLength,
                              }) => {
  const _defaultValue = defaultValue ? formatValue(String(defaultValue), prefix) : '';
  const [stateValue, setStateValue] = useState(_defaultValue);
  const inputRef = useRef(null);

  const onFocus = () => (stateValue ? stateValue.length : 0);
  const processChange = (event) => {
    const {
      target: { value },
    } = event;

    const valueOnly = cleanValue(value, allowDecimals, decimalsLimit, prefix);
    if (!valueOnly) {
      onChange && onChange(null, name);
      return setStateValue('');
    }

    if (checkIsValidNumber(valueOnly)) {
      const formattedValue = formatValue(valueOnly, prefix);
      setStateValue(formattedValue);
      onChange && onChange(Number(formattedValue), name);
    }
  };

  useEffect(() => {
    if (stateValue !== defaultValue && defaultValue === 0)
      setStateValue(_defaultValue)
  }, [defaultValue])

  return (
    <div className="input-group">
      <input
        type="text"
        inputMode="decimal"
        id={id}
        name={name}
        className={className}
        onChange={processChange}
        onFocus={onFocus}
        placeholder={placeholder}
        disabled={disabled}
        value={stateValue}
        ref={inputRef}
        maxLength={maxLength}
        autoComplete="off"
        required={required}
        autoFocus={false}
      />
      <div className="input-group-append">
        <span className="input-group-text">€</span>
      </div>
    </div>
  );
};

export default CurrencyInput;
