import React, { useState } from 'react'
import { useHistory, withRouter, Link } from 'react-router-dom'
import { graphql } from 'react-apollo'
import { gql } from 'apollo-boost'
import { AUTH_TOKEN, STRIPE_KEY } from '../../constant'
// eslint-disable-next-line no-undef
const stripe = Stripe(STRIPE_KEY);

function SignupPage ({ signupMutation, refreshTokenFn }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVerification, setPasswordVerification] = useState('');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [restaurantName, setRestaurantName] = useState('');
  const [restaurantAddress, setRestaurantAddress] = useState('');
  const [error, setError] = useState('');
  const history = useHistory();

  const _signup = async e => {
    e.preventDefault()
    setLoading(true);
    setError('');
    const accountResult = await stripe.createToken('account', {
      business_type: 'company',
      company: {
        name: restaurantName,
        address: {
          line1: restaurantAddress,
        },
      },
      tos_shown_and_accepted: true,
    });

    signupMutation({
      variables: {
        name,
        email,
        password,
        passwordVerification,
        restaurantName,
        restaurantAddress,
        tokenAccount: accountResult.token.id
      },
    }).then((result) => {
      const token = result.data.signup.token
      localStorage.setItem(AUTH_TOKEN, token)

      refreshTokenFn &&
      refreshTokenFn({
        [AUTH_TOKEN]: token,
      })

      setLoading(false);

      history.replace('/')
      window.location.reload()
    }).catch(e => {
      if (e.message?.includes('Unique constraint failed'))
        setError('L\'email est déjà pris')
      else
        setError(String(e))
      setLoading(false);
    })
  }

  return (
    <div className="container">
      <div className="row position-absolute" style={{ top: 20 }}>
        <div className="col">
          <img
            src="/assets/img/logo.png"
            className="navbar-brand-img
    mx-auto"
            alt="logo"
            style={{ maxHeight: '3rem' }}
          />
        </div>
      </div>
      <div className="row align-items-center" style={{marginTop: 80}}>
        <div className="col-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0">
          <div className="text-center">
            <img src="/assets/img/illustrations/cooking.svg" alt="illustration" className="img-fluid"/>
          </div>
        </div>
        <div className="col-12 col-md-5 col-xl-4 my-5 order-md-1">
          <h1 className="display-4 text-center mb-3">
            S'inscrire
          </h1>
          <p className="text-muted text-center mb-5">
            Accès au panel complet Yeaty
          </p>
          <form onSubmit={_signup}>
            <div className="form-group">
              <label>
                Identifiant
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="identifiant"
                value={email}
                onChange={e => setEmail(e.target.value)}
                required
                autoFocus/>
            </div>
            <div className="form-group">
              <label>
                Mot de passe
              </label>
              <div className="input-group input-group-merge">
                <input type="password"
                       className="form-control"
                       placeholder="Enter your password"
                       value={password}
                       onChange={e => setPassword(e.target.value)}
                       required
                       autoComplete="current-password"/>
              </div>
            </div>
            <div className="form-group">
              <label>
                Vérification du mot de passe
              </label>
              <div className="input-group input-group-merge">
                <input type="password"
                       className="form-control"
                       placeholder="Enter your password"
                       value={passwordVerification}
                       onChange={e => setPasswordVerification(e.target.value)}
                       required
                       autoComplete="current-password"/>
              </div>
            </div>
            <div className="form-group">
              <label>
                Votre nom
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="John Doe"
                value={name}
                onChange={e => setName(e.target.value)}
                required/>
            </div>
            <div className="form-group">
              <label>
                Nom du restaurant
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Al Pizza"
                value={restaurantName}
                onChange={e => setRestaurantName(e.target.value)}
                required/>
            </div>
            <div className="form-group">
              <label>
                Adresse du restaurant
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="10 rue du pacte 75015 Paris"
                value={restaurantAddress}
                onChange={e => setRestaurantAddress(e.target.value)}
                required/>
            </div>
            {error && <div className="text-danger">{error}</div>}
            <button className="btn btn-lg btn-block btn-primary mb-3" disabled={loading}>
              {loading ? <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div> : <span>S'inscrire</span>}
            </button>
            <div className="text-center">
              <small className="text-muted text-center">
                Déjà un compte ? <Link to="/login">Se connecter</Link>.
              </small>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

const SIGNUP_USER_MUTATION = gql`
  mutation SignupMutation($email: String!, $password: String!, $name: String!, $restaurantName: String!, $restaurantAddress: String!, $tokenAccount: String!) {
    signup(email: $email, password: $password, name: $name, restaurantName: $restaurantName, restaurantAddress: $restaurantAddress, tokenAccount: $tokenAccount) {
      token
      user {
        id
        name
        email
      }
    }
  }
`

export default graphql(SIGNUP_USER_MUTATION, { name: 'signupMutation' })(
  withRouter(SignupPage),
)
