import React, { useState } from 'react'
import { useHistory, withRouter, useLocation, Link } from 'react-router-dom'
import { graphql } from 'react-apollo'
import { gql } from 'apollo-boost'
import { AUTH_TOKEN } from '../../constant'

function LoginPage ({ loginMutation, refreshTokenFn }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const history = useHistory();
  const location = useLocation();

  let { from } = location.state || { from: { pathname: "/" } };

  const _login = async e => {
    e.preventDefault();
    setError('');
    loginMutation({
      variables: {
        email,
        password,
      },
    })
      .then(result => {
        const token = result.data.login.token
        refreshTokenFn &&
        refreshTokenFn({
          [AUTH_TOKEN]: token,
        })
        if (result.data.login.isDeliverer)
          localStorage.setItem('isDeliverer', 'true')
        history.replace(from);
        window.location.reload()
      })
      .catch(err => {
        if (err && (err.toString().toLowerCase().includes('no user found') || err.toString().toLowerCase().includes('invalid password'))) {
          setError('Identifiant ou mot de passe invalide')
        } else {
          setError('Une erreur est survenue. Veuillez réessayer')
        }
      })
  }

  return (
    <div className="container">
      <div
        className="d-none d-md-block"
        style={{
          position: 'absolute',
          width: '33%',
          height: '100vh',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          backgroundColor: '#EEB540'
        }}/>
      <div className="row">
        <div className="d-none col-6 d-md-flex justify-content-center align-items-center">
          <img
            src="/assets/img/photo_login.png"
            className="img-fluid"
            alt="logo"
          />
        </div>
        <div className="col-12 col-md-5 offset-md-1">
          <img
            src="/assets/img/logo.png"
            className="navbar-brand-img mb-6 d-block margin-auto"
            alt="logo"
            style={{ maxHeight: '7rem' }}
          />
          <h1 className="display-4 text-center mb-3">
            Se Connecter
          </h1>
          <p className="text-muted text-center mb-5">
            Accès à votre tableau de gestion.
          </p>
          <form onSubmit={_login}>
            <div className="form-group">
              <label>Identifiant de connection</label>
              <input
                autoComplete="username"
                autoFocus
                autoCapitalize="none"
                type="text"
                className="form-control"
                placeholder="name@address.com"
                onChange={e => setEmail(e.target.value)}
                value={email}
                required
                tabIndex={1}
              />
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col">
                  <label>Mot de passe</label>
                </div>
                <div className="col-auto">
                  <Link to="/forgot-password" className="form-text small text-muted" tabIndex={3}>
                    Mot de passe oublié ?
                  </Link>
                </div>
              </div>
              <div className="input-group input-group-merge">
                <input
                  autoComplete="current-password"
                  type="password"
                  className="form-control"
                  placeholder="Enter your password"
                  onChange={e => setPassword(e.target.value)}
                  value={password}
                  required
                  tabIndex={2}/>
              </div>
            </div>
            {error && <div className="text-danger">{error}</div>}
            <button
              className="btn btn-lg btn-block btn-primary mb-3"
              type="submit">
              Se Connecter
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

const LOGIN_USER_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      isDeliverer
      token
      user {
        id
        name
        email
        restaurant {
          id
        }
      }
    }
  }
`

export default graphql(LOGIN_USER_MUTATION, { name: 'loginMutation' })(
  withRouter(LoginPage),
)
