import React from 'react'
import { useHistory } from 'react-router-dom'

export default function CategoryItem ({ category, onChange, checked, editCategory, deleteCategory, length, onChangeOrder, innerRef, ...props }) {
  const history = useHistory()

  const clickCategory = (e) => {
    if (!['INPUT', 'LABEL', 'I', 'SELECT', 'BUTTON'].includes(e.target.tagName) && e.target.closest('td').className !== 'no-redirect') {
      return history.push(`/menu?tab=articles&category=${category.id}`)
    }
  }

  return (
    <tr ref={innerRef} {...props}>
      <td>
        <div className="custom-control custom-checkbox table-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            name="categoriesSelect"
            id={`categories${category.id}`}
            onChange={(e) => {
              e.stopPropagation()
              onChange(category)
            }}
            checked={checked}/>
          <label className="custom-control-label" htmlFor={`categories${category.id}`} data-redirect={true}>&nbsp;</label>
        </div>
      </td>
      <td className="orders-product th" onClick={clickCategory}>
        {category.name}&nbsp;{category.emoji}
      </td>
      <td onClick={clickCategory}>
        {category.articles?.length}
      </td>
      <td className="text-right" onClick={clickCategory}>
        <button className="btn btn-lg btn-info mr-5" onClick={editCategory}>
          <i className="fe fe-edit"/>
        </button>
        <button className="btn btn-lg btn-outline-danger" onClick={deleteCategory}>
          <i className="fe fe-trash"/>
        </button>
      </td>
    </tr>
  )
}
