import React from "react";

export default function Loader () {
  return (
    <div className="d-flex justify-content-center w-100 align-items-center vh-100">
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
}
