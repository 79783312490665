import React, { useState } from 'react'
import { withRouter, useLocation, Link } from 'react-router-dom'
import { graphql } from 'react-apollo'
import { gql } from 'apollo-boost'

function useQueryParams () {
  return new URLSearchParams(useLocation().search)
}

function ResetPassword ({ resetPassword }) {
  const [password, setPassword] = useState('');
  const [passwordVerification, setPasswordVerification] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  let query = useQueryParams()
  const token = query.get('token')

  if (!token)
    return <div className="container">
      <div className="row align-items-center">
        <div className="col-12 col-md-6 offset-xl-2 offset-md-1 mb-5 mb-md-0">
          <p>Un problème est survenu. Veuillez réessayer</p>
        </div>
      </div>
    </div>

  const _login = async e => {
    e.preventDefault();
    setError('');
    if (password !== passwordVerification)
      return setError('Les mots de passes ne sont pas identiques')
    resetPassword({
      variables: {
        token,
        password
      },
    })
      .then(() => {
        setSuccess(true)
        setPassword('')
      })
      .catch(() => {
        setError('Une erreur est survenue. Veuillez réessayer')
      })
  }

  return (
    <div className="container">
      <div className="row position-absolute" style={{ top: 20 }}>
        <div className="col">
          <img
            src="/assets/img/logo.png"
            className="navbar-brand-img
    mx-auto"
            alt="logo"
            style={{ maxHeight: '3rem' }}
          />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-8 col-xl-6 my-5">
          {success ?
            <div className="text-center">
              <h1 className="text-success mb-3">
                Votre mot de passe a été mis à jour
              </h1>
              <Link to="/login">Connectez-vous</Link>
            </div>
            :
            <div>
              <h1 className="display-4 text-center mb-3">
                Nouveau mot de passe
              </h1>
              <p className="text-muted text-center mb-5">
                Définissez un nouveau mot de passe
              </p>
              <form onSubmit={_login}>
                <div className="form-group">
                  <div className="row">
                    <div className="col">
                      <label>Mot de passe</label>
                    </div>
                  </div>
                  <div className="input-group input-group-merge">
                    <input
                      autoComplete="new-password"
                      type="password"
                      className="form-control"
                      placeholder="Enter your password"
                      onChange={e => setPassword(e.target.value)}
                      value={password}
                      required/>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col">
                      <label>Vérification du mot de passe</label>
                    </div>
                  </div>
                  <div className="input-group input-group-merge">
                    <input
                      autoComplete="new-password"
                      type="password"
                      className="form-control"
                      placeholder="Enter your password"
                      onChange={e => setPasswordVerification(e.target.value)}
                      value={passwordVerification}
                      required/>
                  </div>
                </div>
                {error && <div className="text-danger">{error}</div>}
                <button
                  className="btn btn-lg btn-block btn-primary mb-3"
                  type="submit">
                  Enregistrer
                </button>
                <div className="text-center">
                  <small className="text-muted text-center">
                    <Link to="/login">Revenir sur la page de connexion</Link>
                  </small>
                </div>
              </form>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

const LOGIN_USER_MUTATION = gql`
  mutation resetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password) {
       id
    }
  }
`

export default graphql(LOGIN_USER_MUTATION, { name: 'resetPassword' })(
  withRouter(ResetPassword),
)
