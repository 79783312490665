import React from 'react'

export default function DelivererItem ({ deliverer, onChange, checked, editDeliverer, deleteDeliverer, ...props }) {
  return (
    <tr {...props}>
      <td className="orders-product th">
        {deliverer.name}
      </td>
      <td>
        {deliverer.email}
      </td>
      <td>
        {deliverer.password}
      </td>
      {deliverer.restaurants?.length === 1 &&
      <td className="text-right">
        <button className="btn btn-lg btn-info mr-5" onClick={editDeliverer}>
          <i className="fe fe-edit"/>
        </button>
        <button className="btn btn-lg btn-outline-danger" onClick={deleteDeliverer}>
          <i className="fe fe-trash"/>
        </button>
      </td>
      }
    </tr>
  )
}
