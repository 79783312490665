import React, { Component } from 'react';
import Select from 'react-select';

export default class ModalSelect extends Component {

  render () {
    const { isValid } = this.props;
    let styles = {
      menuPortal: base => ({ ...base, zIndex: 9999 }),
    }
    if (isValid !== null)
      styles.control = (base, state) => ({
        ...base,
        // state.isFocused can display different borderColor if you need it
        borderColor: state.isFocused ?
          '#ddd' : isValid ?
            '#ddd' : 'red',
        // overwrittes hover style
        '&:hover': {
          borderColor: state.isFocused ?
            '#ddd' : isValid ?
              '#ddd' : 'red'
        }
      })
    return (
      <Select
        styles={styles}
        menuPortalTarget={document.body}
        name="color"
        {...this.props}
      />
    )
  }
}
