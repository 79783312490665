export const getOptionLabel = (label) => {
  switch (label) {
    case 'required':
      return 'Choix unique'
    case 'optional':
      return 'Choix multiples'
    case 'unique':
      return 'Choix unique'
    case 'multiple' :
      return 'Choix multiple'
    default:
      return ''
  }
}
