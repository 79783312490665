import React from 'react'
import { getOptionLabel } from "../../helper/helper";

export default function OptionItem ({ option, checked, onChange, editOption, deleteOption, innerRef, hideOption, ...props }) {
  return (
    <tr ref={innerRef} {...props}>
      {props.check !== false &&
      <td>
        <div className="custom-control custom-checkbox table-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            name="ordersSelect"
            id={`orders${option.id}`}
            onChange={(e) => {
              e.stopPropagation();
              onChange(option);
            }}
            checked={checked}/>
          <label className="custom-control-label" htmlFor={`orders${option.id}`} data-redirect={true}>&nbsp;</label>
        </div>
      </td>
      }
      <td className="orders-product">
        {option.title}
      </td>
      <td className="orders-total">
        {getOptionLabel(option.type)}
      </td>
      <td className="orders-date">
        {option.maximum}
      </td>
      <td className="orders-method">
        {option.description.substring(0, 40)}{option.description?.length > 40 ? '...' : ''}
      </td>
      <td className="text-right">
        {hideOption &&
        <button className="btn btn-lg btn-secondary mr-5" onClick={hideOption}>
          <i className="fe fe-x"/>
        </button>
        }
        {props.newOption &&
        <button className="btn btn-lg btn-outline-primary mr-5" onClick={props.newOption}>
          <i className="fe fe-plus"/>
        </button>
        }
        <button className="btn btn-lg btn-info mr-5" onClick={editOption}>
          <i className="fe fe-edit"/>
        </button>
        <button className="btn btn-lg btn-outline-danger" onClick={deleteOption}>
          <i className="fe fe-trash"/>
        </button>
      </td>
    </tr>
  )
}
