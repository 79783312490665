import React, { useState, useEffect } from 'react'
import compose from 'lodash.flowright'
import { graphql } from 'react-apollo'
import { gql } from 'apollo-boost'

function Profile ({ meQuery, updateProfile }) {
  const [profile, setProfile] = useState(meQuery.me)
  const [profileLoader, setProfileLoader] = useState(false)

  useEffect(() => {
    setProfile(meQuery.me)
  }, [meQuery.me])

  const handleInputChange = event => {
    const { name, value } = event.target
    if (name.includes('.')) {
      const array = name.split('.')
      const first = array[0]
      const second = array[1]
      setProfile({ ...profile, ...{ [first]: { ...profile[first], [second]: value } } })
    } else {
      setProfile({ ...profile, [name]: value })
    }
  }

  const submitProfile = async e => {
    e.preventDefault()
    setProfileLoader(true)
    updateProfile({
      variables: {
        restaurantName: profile.restaurant.name, restaurantAddress: profile.restaurant.address,
        restaurantSlug: profile.restaurant.slug,
        email: profile.email
      }
    })
      .then(async () => {
        setProfileLoader(false)
        await meQuery.refetch()
      })
      .catch(() => {
        setProfileLoader(false)
      })
  }

  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10 col-xl-8">
          <div className="header mt-md-5">
            <div className="header-body">
              <div className="row align-items-center">
                <div className="col">
                  <h6 className="header-pretitle">
                    Paramètres
                  </h6>
                  <h1 className="header-title">
                    Utilisateur
                  </h1>
                </div>
                <div className="col-auto">
                  <button className="btn btn-dark h-100" onClick={() =>
                    window.location.reload(true)
                  }>
                    <i className="fe fe-refresh-cw"/>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <form className="mb-4" onSubmit={submitProfile}>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label className="mb-1">
                    Email
                  </label>
                  <input type="email" className="form-control" name="email" value={profile?.email || ''} onChange={handleInputChange}/>
                </div>
              </div>
              <div className="col-12">
                <button type="submit" className="btn btn-primary lift" disabled={profileLoader}>
                  {profileLoader ? <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div> : <span>Mettre à jour les données</span>}
                </button>
              </div>
            </div>
          </form>
          <form>
            <hr className="mt-4 mb-5"/>
            <div className="row">
              <div className="col-12 col-md-6 order-md-2">
                <div className="card bg-light border ml-md-4">
                  <div className="card-body">
                    <p className="mb-2">
                      Password requirements
                    </p>
                    <p className="small text-muted mb-2">
                      To create a new password, you have to meet all of the following requirements:
                    </p>
                    <ul className="small text-muted pl-4 mb-0">
                      <li>
                        Minimum 8 character
                      </li>
                      <li>
                        At least one special character
                      </li>
                      <li>
                        At least one number
                      </li>
                      <li>
                        Can’t be the same as a previous password
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label>
                    Password
                  </label>
                  <input type="password" className="form-control" autoComplete="current-password"/>
                </div>
                <div className="form-group">
                  <label>
                    New password
                  </label>
                  <input type="password" className="form-control" autoComplete="new-password"/>
                </div>
                <div className="form-group">
                  <label>
                    Confirm new password
                  </label>
                  <input type="password" className="form-control" autoComplete="new-password"/>
                </div>
                <button type="submit" className="btn btn-primary lift">
                  Mettre à jour le mot de passe
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

const ME_QUERY = gql`
  query MeQuery {
    me {
      id
      email
      admin
      name
      restaurant {
        name
        address
        image
        slug
        orderDisabled
        disabled
      }
    }
  }
`

const UPDATE_PROFILE_MUTATION = gql`
  mutation UpdateProfile($restaurantName: String!, $restaurantAddress: String!, $restaurantSlug: String! $email: String!) {
    updateProfile(restaurantName: $restaurantName, restaurantAddress : $restaurantAddress, restaurantSlug: $restaurantSlug, email: $email) {
      email
    }
  }
`
const UPLOAD_FILE_RESTAURANT = gql`
  mutation uploadPhotoRestaurant($file: Upload!) {
    uploadPhotoRestaurant(file: $file) {
      id
    }
  }
`

export default compose(
  graphql(ME_QUERY, {
    name: 'meQuery', // name of the injected prop: this.props.categoriesQuery...
    options: {
      fetchPolicy: 'network-only',
    },
  }),
  graphql(UPDATE_PROFILE_MUTATION, {
    name: 'updateProfile', // name of the injected prop: this.props.createDraftMutation...
  }),
  graphql(UPLOAD_FILE_RESTAURANT, {
    name: 'uploadPhotoRestaurant', // name of the injected prop: this.props.categoriesQuery...
  }),
)(Profile);
